// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch psotherbodypart list
 * @param {*} payload
 */
export async function fetchPsotherbodypartList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`psotherbodypart?${params}`);
}

/**
 * get psotherbodypart
 * @param {*} payload
 */

export async function fetchPsotherbodypart(payload) {
  return GET(`psotherbodypart/${payload}`);
}

/**
 * update psotherbodypart by id
 * @param {*} payload
 */
export async function updatePsotherbodypartById(payload) {
  const { id, ...rest } = payload;
  return PUT(`psotherbodypart/${id}`, rest);
}

/**
 * remove psotherbodypart by id
 * @param {*} payload
 */
export async function removePsotherbodypartById(payload) {
  return DELETE(`psotherbodypart/${payload}`);
}

/**
 * create psotherbodypart
 * @param {*} payload
 */
export async function createPsotherbodypart(payload) {
  return POST(`psotherbodypart`, payload);
}
