import {
  fetchAcu1List,
  fetchAcu1,
  updateAcu1ById,
  removeAcu1ById,
  createAcu1
} from '@service/api/acu1';

// types
import * as types from '../types';
/*
export const setAcu1 = (payload) => ({
  type: types.WIZWL_SET_ACCOUNT_DETAILS,
  payload,
});
*/

export const updateMinatoAcu1Ps = (params) => ({
  type: types.SET_ACU1_PS_ALPHA,
  payload: params,
});

export const getAcu1List = (payload) => async (dispatch) => {
  const res = await fetchAcu1List(payload);
  const { success, data } = res;

  if (success) {
    await dispatch({
      type: types.SET_ACU1,
      payload: data.docs,
    });

    if (data.docs.length > 0) {
      const acu1 = data.docs[0];
      await dispatch({
        type: types.SET_ACU1_PS,
        payload: acu1.ps ? JSON.parse(acu1.ps) : undefined,
      });
    }
  }

  return res;
};

export const removeAcu1 = (payload) => () => {
  return removeAcu1ById(payload).then((res) => {
    return res;
  });
};

export const addAcu1 = (payload) => () => {
  return createAcu1(payload).then((res) => {
    return res;
  });
};

export const getAcu1 = (payload) => () => {
  return fetchAcu1(payload).then((res) => {
    return res;
  });
};

export const updateAcu1 = (payload) => () => {
  return updateAcu1ById(payload).then((res) => {
    return res;
  });
};
