import * as types from '../types';

export const setLoading = () => ({
  type: types.SET_LOADING,
});

export const clearLoading = () => ({
  type: types.CLEAR_LOADING,
});

export const fromDoeButton = (payload) => ({
  type: types.SET_FROM_DOE_BUTTON,
  payload,
});
