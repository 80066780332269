import {
  fetchFingersList,
  fetchFingers,
  updateFingersById,
  removeFingersById,
  createFingers
} from '@service/api/fingers';

// types
import * as types from '../types';

export const getFingersList = (payload) => async (dispatch) => {
  return fetchFingersList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_HANDS_AND_FINGERS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeFingers = (payload) => () => {
  return removeFingersById(payload).then((res) => {
    return res;
  });
};

export const addFingers = (payload) => () => {
  return createFingers(payload).then((res) => {
    return res;
  });
};

export const getFingers = (payload) => () => {
  return fetchFingers(payload).then((res) => {
    return res;
  });
};

export const updateFingers = (payload) => () => {
  return updateFingersById(payload).then((res) => {
    return res;
  });
};
