// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch addressbook list
 * @param {*} payload
 */
export async function fetchAddressBookList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`addressbook?${params}`);
}

/**
 * get addressbook
 * @param {*} payload
 */

export async function fetchAddressBook(payload) {
  return GET(`addressbook/${payload}`);
}

/**
 * update addressbook by id
 * @param {*} payload
 */
export async function updateAddressBookById(payload) {
  const { id, ...rest } = payload;
  return PUT(`addressbook/${id}`, rest);
}

/**
 * remove addressbook by id
 * @param {*} payload
 */
export async function removeAddressBookById(payload) {
  return DELETE(`addressbook/${payload}`);
}

/**
 * create addressbook
 * @param {*} payload
 */
export async function createAddressBook(payload) {
  return POST(`addressbook`, payload);
}

//////////////////
// P A T I E N T D O E   L I S T
/////////////////
export async function fetchPatientDoeList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`patientdoe?${params}`);
};
