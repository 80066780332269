import {
  fetchInternalmedList,
  fetchInternalmed,
  updateInternalmedById,
  removeInternalmedById,
  createInternalmed
} from '@service/api/internalmed';

// types
import * as types from '../types';

export const getInternalmedList = (payload) => async (dispatch) => {
  return fetchInternalmedList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PSINTERNAL,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeInternalmed = (payload) => () => {
  return removeInternalmedById(payload).then((res) => {
    return res;
  });
};

export const addInternalmed = (payload) => () => {
  return createInternalmed(payload).then((res) => {
    return res;
  });
};

export const getInternalmed = (payload) => () => {
  return fetchInternalmed(payload).then((res) => {
    return res;
  });
};

export const updateInternalmed = (payload) => () => {
  return updateInternalmedById(payload).then((res) => {
    return res;
  });
};
