import { combineReducers } from 'redux';

import patients from './patients';
import providers from './providers';
import directwebremote from './directwebremote';
import templates from './templates';
import bodyparts from './bodyparts';
import treatmentplan from './treatmentplan';
import medications from './medications';
import xsers from './xsers';
import acu1 from './acu1';
import poffice from './poffice';
import procedurecode from './procedurecode';

const commonReducer = combineReducers({
  patients,
  providers,
  directwebremote,
  templates,
  bodyparts,
  treatmentplan,
  medications,
  xsers,
  acu1,
  poffice,
  procedurecode,
});

export default commonReducer;
