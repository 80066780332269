// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch billreduc list
 * @param {*} payload
 */
export async function fetchBillReducList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`billreduc?${params}`);
}

/**
 * get billreduc
 * @param {*} payload
 */

export async function fetchBillReduc(payload) {
  return GET(`billreduc/${payload}`);
}

/**
 * update billreduc by id
 * @param {*} payload
 */
export async function updateBillReducById(payload) {
  const { id, ...rest } = payload;
  return PUT(`billreduc/${id}`, rest);
}

/**
 * remove billreduc by id
 * @param {*} payload
 */
export async function removeBillReducById(payload) {
  return DELETE(`billreduc/${payload}`);
}

/**
 * create billreduc
 * @param {*} payload
 */
export async function createBillReduc(payload) {
  return POST(`billreduc`, payload);
}
