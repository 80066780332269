// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch treatmentplan list
 * @param {*} payload
 */
export async function fetchTreatmentplanList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`treatmentplan?${params}`);
}

/**
 * get treatmentplan
 * @param {*} payload
 */

export async function fetchTreatmentplan(payload) {
  return GET(`treatmentplan/${payload}`);
}

/**
 * update treatmentplan by id
 * @param {*} payload
 */
export async function updateTreatmentplanById(payload) {
  const { id, ...rest } = payload;
  return PUT(`treatmentplan/${id}`, rest);
}

export async function updateTreatmentplanByIdx(payload) {
  const { id, ...rest } = payload;
  return PUT(`treatmentplan/fastcar/${id}`, rest);
}

/**
 * remove treatmentplan by id
 * @param {*} payload
 */
export async function removeTreatmentplanById(payload) {
  return DELETE(`treatmentplan/${payload}`);
}

/**
 * remove treatmentplan by id
 * @param {*} payload
 */
export async function removeTreatmentplanByPatientId(payload) {
  const params = methods.convertQueryString(payload);
  return DELETE(`treatmentplan?${params}`);
}

/**
 * create treatmentplan
 * @param {*} payload
 */
export async function createTreatmentplan(payload) {
  return POST(`treatmentplan`, payload);
}
