import * as types from '../types';

const initialState = {
  activeMtusMed: '',
  selectedMed: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SELECTED_MED:
      return {
        ...state,
        selectedMed: action.payload,
      };
    case types.SET_ACTIVE_MTUS_MED:
      return {
        ...state,
        activeMtusMed: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
