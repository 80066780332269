// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch treatcode list
 * @param {*} payload
 */
export async function fetchTreatcodeList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`treatcode?${params}`);
}

/**
 * get treatcode
 * @param {*} payload
 */

export async function fetchTreatcode(payload) {
  return GET(`treatcode/${payload}`);
}

/**
 * update treatcode by id
 * @param {*} payload
 */
export async function updateTreatcodeById(payload) {
  const { id, ...rest } = payload;
  return PUT(`treatcode/${id}`, rest);
}

/**
 * remove treatcode by id
 * @param {*} payload
 */
export async function removeTreatcodeById(payload) {
  return DELETE(`treatcode/${payload}`);
}

/**
 * create treatcode
 * @param {*} payload
 */
export async function createTreatcode(payload) {
  return POST(`treatcode`, payload);
}
