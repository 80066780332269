import {
  fetchNevroList,
  fetchNevro,
  updateNevroById,
  removeNevroById,
  createNevro
} from '@service/api/nevro';

// types
import * as types from '../types';

export const getNevroList = (payload) => async(dispatch) => {
  return fetchNevroList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PSNEVRO,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeNevro = (payload) => () => {
  return removeNevroById(payload).then((res) => {
    return res;
  });
};

export const addNevro = (payload) => () => {
  return createNevro(payload).then((res) => {
    return res;
  });
};

export const getNevro = (payload) => () => {
  return fetchNevro(payload).then((res) => {
    return res;
  });
};

export const updateNevro = (payload) => () => {
  return updateNevroById(payload).then((res) => {
    return res;
  });
};
