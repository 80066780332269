import { fetchDiagsetList } from '@service/api/diagset';

// types
import * as types from '../types';

export const getDiagset = payload => async dispatch => {
  const res = await fetchDiagsetList(payload);
  const { success, data } = res;
  if (success) {
    const arr = ['diagcspine', 'diaglspine', 'diagtspine', 'diagmisc', 'diagrshoulder', 'diaglshoulder', 'diagrelbow', 'diaglelbow', 'diagrwrist', 'diaglwrist', 'diagrhand', 'diaglhand', 'diagrfinger', 'diaglfinger', 'diagrthumb', 'diaglthumb', 'diagrhip', 'diaglhip', 'diagrknee', 'diaglknee', 'diagrankfoot', 'diaglankfoot', 'diagrtoe', 'diagltoe'];

    arr.map(diag2 => {
      const diagItems = data.docs.filter(items => items.diag2 === diag2);
      const arrDiag1 = [];
      const arrDiag5 = [];
      diagItems.map(diag => {
        arrDiag1.push(diag.diag1);
        arrDiag5.push(diag.diag5);
        return diag;
      });
      dispatch({
        type: types.SET_PANTERA_RPT_CONTENT,
        payload: { [diag2]: arrDiag1.toString() },
      });
      dispatch({
        type: types.SET_PANTERA_RPT_CONTENT,
        payload: { [`${diag2}ICD`]: arrDiag5.toString() },
      });
      return diag2;
    });
  }
  return res;
};
