// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch treatmentx list
 * @param {*} payload
 */
export async function fetchTreatmentPlansetList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`treatmentx?${params}`);
}

/**
 * get treatmentx
 * @param {*} payload
 */

export async function fetchTreatmentPlanset(payload) {
  return GET(`treatmentx/${payload}`);
}

/**
 * update treatmentx by id
 * @param {*} payload
 */
export async function updateTreatmentPlansetById(payload) {
  const { id, ...rest } = payload;
  return PUT(`treatmentx/${id}`, rest);
}

/**
 * remove treatmentx by id
 * @param {*} payload
 */
export async function removeTreatmentPlansetById(payload) {
  return DELETE(`treatmentx/${payload}`);
}

/**
 * create treatmentx
 * @param {*} payload
 */
export async function createTreatmentPlanset(payload) {
  return POST(`treatmentx`, payload);
}
