// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch jobdescription list
 * @param {*} payload
 */
export async function fetchJobdescList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`jobdescription?${params}`);
}

/**
 * get jobdescription
 * @param {*} payload
 */

export async function fetchJobdesc(payload) {
  return GET(`jobdescription/${payload}`);
}

/**
 * update jobdescription by id
 * @param {*} payload
 */
export async function updateJobdescById(payload) {
  const { id, ...rest } = payload;
  return PUT(`jobdescription/${id}`, rest);
}

/**
 * remove jobdescription by id
 * @param {*} payload
 */
export async function removeJobdescById(payload) {
  return DELETE(`jobdescription/${payload}`);
}

/**
 * create jobdescription
 * @param {*} payload
 */
export async function createJobdesc(payload) {
  return POST(`jobdescription`, payload);
}
