// constants
import { tlight, tplanFields, medFields, bpFields} from '@utils/common';

import {
  fetchTreatmentPlansetList,
  fetchTreatmentPlanset,
  updateTreatmentPlansetById,
  removeTreatmentPlansetById,
  createTreatmentPlanset,
} from '@service/api/treatmentplanset';

// types
import * as types from '../types';

export const prepareTanks = () => async (dispatch) => {
  const temptera = {};
  const temptura = {};

  // sepultura
  tplanFields.map(c => {
    if (c === 'treatmisc') {
      temptura[c] = { bp: '', items: [] };
    } else {
      temptura[c] = '';
    }
    return c;
  });
  temptura.treatother = '';
  temptura.othermeds = '';

  medFields.map(c => {
    temptura[c] = '';
    return c;
  });

  dispatch({
    type: types.SET_SEPULTURA_RPT_CONTENT,
    payload: temptura
  });

  // pantera
  tplanFields.map(c => {
    temptera[c] = {};
    if (tlight.includes(c)) {
      if (c !== 'treatpsyche') {
        temptera[c].ALL = [];
          bpFields.map(bp => {
            temptera[c][bp] = [];
            return bp;
          });
      } else {
        bpFields.map(bp => {
          temptera[c][bp] = [];
          return bp;
        });
      }
    } else {
      bpFields.map(bp => {
        temptera[c][bp] = [];
        return bp;
      });
    }
    return c;
  });

  medFields.map(med => {
    temptera[med] = {};
    // temptera.meds[med] = [];

    bpFields.map(bp => {
      temptera[med][bp] = [];
      return bp;
    });
    return med;
  });

  dispatch({
    type: types.SET_PANTERA_RPT_CONTENT,
    payload: { treatmentPlan: temptera }
  });

  return temptera;
};

export const getTreatmentPlanXList = (payload) => async (dispatch) => {
  const res = await fetchTreatmentPlansetList(payload);
  if (res.success) {
    dispatch({
      type: types.SET_TREAT_PLANX,
      payload: res.data.docs,
    });
  }
  return res;
};

export const removeTreatmentPlanX = (payload) => async (dispatch) => {
  const res = await removeTreatmentPlansetById(payload);
  return res;
};

export const addTreatmentPlanX = (payload) => async (dispatch) => {
  const res = await createTreatmentPlanset(payload);
  return res;
};

export const getTreatmentPlanXById = (payload) => async (dispatch) => {
  const res = await fetchTreatmentPlanset(payload);
  return res;
};

export const updateTreatmentPlanX = (payload) => async (dispatch) => {
  const res = await updateTreatmentPlansetById(payload);
  return res;
};
