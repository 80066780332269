// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch vitalsign list
 * @param {*} payload
 */
export async function fetchVitalsignList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`vitalsign?${params}`);
}

/**
 * get vitalsign
 * @param {*} payload
 */

export async function fetchVitalsign(payload) {
  return GET(`vitalsign/${payload}`);
}

/**
 * update vitalsign by id
 * @param {*} payload
 */
export async function updateVitalsignById(payload) {
  const { id, ...rest } = payload;
  return PUT(`vitalsign/${id}`, rest);
}

/**
 * remove vitalsign by id
 * @param {*} payload
 */
export async function removeVitalsignById(payload) {
  return DELETE(`vitalsign/${payload}`);
}

/**
 * create vitalsign
 * @param {*} payload
 */
export async function createVitalsign(payload) {
  return POST(`vitalsign`, payload);
}
