import {
  fetchProcedureCodeList,
  fetchProcedureCode,
  updateProcedureCodeById,
  removeProcedureCodeById,
  createProcedureCode,
} from '@service/api/procedurecode';

// types
import * as types from '../types';

export const getProcedureCodeList = (payload) => async (dispatch) => {
  return fetchProcedureCodeList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PROCEDURE_CODES,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeProcedureCode = (payload) => () => {
  return removeProcedureCodeById(payload).then((res) => {
    return res;
  });
};

export const addProcedureCode = (payload) => () => {
  return createProcedureCode(payload).then((res) => {
    return res;
  });
};

export const getProcedureCode = (payload) => () => {
  return fetchProcedureCode(payload).then((res) => {
    return res;
  });
};

export const updateProcedureCode = (payload) => () => {
  return updateProcedureCodeById(payload).then((res) => {
    return res;
  });
};
