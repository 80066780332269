// types
import * as types from '../types';

export const showBodyPartsModal = (params) => {
  return {
    type: types.SHOW_BODY_PART_MODAL,
    payload: params,
  };
};

export const setBodyParts = (params) => {
  return {
    type: types.SET_BODY_PARTS,
    payload: params,
  };
};
