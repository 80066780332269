import {
  fetchBillReducList,
  fetchBillReduc,
  updateBillReducById,
  removeBillReducById,
  createBillReduc
} from '@service/api/billreduc';

import {
  fetchBillReducItemList,
} from '@service/api/billreducitem';

// types
import * as types from '../types';

export const getBillReducList = (payload) => async (dispatch) => {
  return fetchBillReducList(payload).then(async (res) => {
    // console.log('[GBR] ', payload, res);

    if (res.success) {
      let brItems = [];
      
      await Promise.all(
        res.data.docs.map(async br => {
          const pload = {
            ...payload,
            billReducId: br._id,
          };
          const res1 = await fetchBillReducItemList(pload);
          // console.log('[RES1]  ', res1);
          res1.data.docs.map(bri => brItems.push({ 
            ...bri,
            billIdNum: br.billIdNum,
            dateExpOfReview: br.dateExpOfReview,
            listOfDisputedServices: br.listOfDisputedServices,                        
          }));

          return br;
        })
      );

      // console.log('[BR ITEMSZSZ]  ', brItems);

      dispatch({
        type: types.SET_BILL_REDUCTION,
        payload: brItems,
      });
    }
    return res;
  });
};

export const removeBillReduc = (payload) => () => {
  return removeBillReducById(payload).then((res) => {
    return res;
  });
};

export const addBillReduc = (payload) => () => {
  return createBillReduc(payload).then((res) => {
    return res;
  });
};

export const getBillReduc = (payload) => () => {
  return fetchBillReduc(payload).then((res) => {
    return res;
  });
};

export const updateBillReduc = (payload) => () => {
  return updateBillReducById(payload).then((res) => {
    return res;
  });
};
