// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch shoulders list
 * @param {*} payload
 */
export async function fetchShouldersList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`shoulders?${params}`);
}

/**
 * get shoulders
 * @param {*} payload
 */

export async function fetchShoulders(payload) {
  return GET(`shoulders/${payload}`);
}

/**
 * update shoulders by id
 * @param {*} payload
 */
export async function updateShouldersById(payload) {
  const { id, ...rest } = payload;
  return PUT(`shoulders/${id}`, rest);
}

/**
 * remove shoulders by id
 * @param {*} payload
 */
export async function removeShouldersById(payload) {
  return DELETE(`shoulders/${payload}`);
}

/**
 * create shoulders
 * @param {*} payload
 */
export async function createShoulders(payload) {
  return POST(`shoulders`, payload);
}
