import {
  fetchLumbarspineList,
  fetchLumbarspine,
  updateLumbarspineById,
  removeLumbarspineById,
  createLumbarspine
} from '@service/api/lumbarspine';

// types
import * as types from '../types';

export const getLumbarspineList = (payload) => async (dispatch) => {
  return fetchLumbarspineList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_LUMBAR_SPINE,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeLumbarspine = (payload) => () => {
  return removeLumbarspineById(payload).then((res) => {
    return res;
  });
};

export const addLumbarspine = (payload) => () => {
  return createLumbarspine(payload).then((res) => {
    return res;
  });
};

export const getLumbarspine = (payload) => () => {
  return fetchLumbarspine(payload).then((res) => {
    return res;
  });
};

export const updateLumbarspine = (payload) => () => {
  return updateLumbarspineById(payload).then((res) => {
    return res;
  });
};
