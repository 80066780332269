import {
  fetchHistoryaccidentList,
  fetchHistoryaccident,
  updateHistoryaccidentById,
  removeHistoryaccidentById,
  createHistoryaccident
} from '@service/api/historyaccident';

export const getHistoryaccidentList = (payload) => () => {
  return fetchHistoryaccidentList(payload).then((res) => {
    return res;
  });
};

export const removeHistoryaccident = (payload) => () => {
  return removeHistoryaccidentById(payload).then((res) => {
    return res;
  });
};

export const addHistoryaccident = (payload) => () => {
  return createHistoryaccident(payload).then((res) => {
    return res;
  });
};

export const getHistoryaccident = (payload) => () => {
  return fetchHistoryaccident(payload).then((res) => {
    return res;
  });
};

export const updateHistoryaccident = (payload) => () => {
  return updateHistoryaccidentById(payload).then((res) => {
    return res;
  });
};
