import {
  fetchAbdomenList,
  fetchAbdomen,
  updateAbdomenById,
  removeAbdomenById,
  createAbdomen
} from '@service/api/abdomen';

// types
import * as types from '../types';

export const getAbdomenList = (payload) => async (dispatch) => {
  return fetchAbdomenList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_ABDOMEN,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeAbdomen = (payload) => () => {
  return removeAbdomenById(payload).then((res) => {
    return res;
  });
};

export const addAbdomen = (payload) => () => {
  return createAbdomen(payload).then((res) => {
    return res;
  });
};

export const getAbdomen = (payload) => () => {
  return fetchAbdomen(payload).then((res) => {
    return res;
  });
};

export const updateAbdomen = (payload) => () => {
  return updateAbdomenById(payload).then((res) => {
    return res;
  });
};
