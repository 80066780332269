// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch abdomen list
 * @param {*} payload
 */
export async function fetchAbdomenList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`abdomen?${params}`);
}

/**
 * get abdomen
 * @param {*} payload
 */

export async function fetchAbdomen(payload) {
  return GET(`abdomen/${payload}`);
}

/**
 * update abdomen by id
 * @param {*} payload
 */
export async function updateAbdomenById(payload) {
  const { id, ...rest } = payload;
  return PUT(`abdomen/${id}`, rest);
}

/**
 * remove abdomen by id
 * @param {*} payload
 */
export async function removeAbdomenById(payload) {
  return DELETE(`abdomen/${payload}`);
}

/**
 * create abdomen
 * @param {*} payload
 */
export async function createAbdomen(payload) {
  return POST(`abdomen`, payload);
}
