import {
  fetchPsshoulderList,
  fetchPsshoulder,
  updatePsshoulderById,
  removePsshoulderById,
  createPsshoulder
} from '@service/api/psshoulder';

// types
import * as types from '../types';

export const getPsshoulderList = (payload) => (dispatch) => {
  return fetchPsshoulderList(payload).then((res) => {
    if (res.success) {
      if (res.data.docs.length > 0) {
        res.data.docs.map(doc => {
          if (doc.lr === 'right') {
            dispatch({
              type: types.SET_PSRSHOULDER,
              payload: [doc],
            });
          }
          if (doc.lr === 'left') {
            dispatch({
              type: types.SET_PSLSHOULDER,
              payload: [doc],
            });
          }
          return doc;
        });
      }
    }
    return res;
  });
};

export const removePsshoulder = (payload) => () => {
  return removePsshoulderById(payload).then((res) => {
    return res;
  });
};

export const addPsshoulder = (payload) => () => {
  return createPsshoulder(payload).then((res) => {
    return res;
  });
};

export const getPsshoulder = (payload) => () => {
  return fetchPsshoulder(payload).then((res) => {
    return res;
  });
};

export const updatePsshoulder = (payload) => () => {
  return updatePsshoulderById(payload).then((res) => {
    return res;
  });
};
