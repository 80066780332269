// RPTGEN

// XSER
export const SET_XSER_LIST = 'SET XSER LIST';

// ADMIN
export const SET_VSER_LIST = 'SET VSER LIST';
export const SET_VSER = 'SET VSER';
export const SET_LEGACY_LIST = 'SET LEGACY LIST';
export const SET_LEGACY = 'SET LEGACY';

export const SET_PROVIDER = 'SET PROVIDER';
export const SET_ASST_PROVIDERS = 'SET ASST PROVIDERS';
export const SET_PATIENT = 'SET PATIENT';
export const SET_NEW_PATIENT = 'SET NEW PATIENT';
export const SET_NEW_PATIENT_BY_FIELD = 'SET NEW PATIENT BY FIELD';
export const SET_CASEREF = 'SET CASEREF';
export const SET_SCREENER = 'SET SCREENER';
export const SET_PREAUTH = 'SET PREAUTH';
export const SET_PATIENTDEMO = 'SET PATIENTDEMO';
export const SET_PATIENT_ID = 'SET PATIENT ID';
export const SET_EXAM_ID = 'SET EXAM ID';
export const SET_PATIENTDOE = 'SET PATIENT DOE';

export const SET_TREAT_PLANX = 'SET TREAT PLANX';
export const SET_TREAT_PLAN = 'SET TREAT PLAN';
export const SET_PATIENT_MED = 'SET PATIENT MED';
export const SET_OTHER_MEDS = 'SET OTHER MEDS';
export const SET_TREAT_OTHER = 'SET TREAT OTHER';
export const SET_TREAT_MISC = 'SET TREAT MISC';

export const SET_ACTIVE_MTUS_TP = 'SET ACTIVE MTUS TP';
export const SET_ACTIVE_MTUS_BP = 'SET ACTIVE MTUS BP';
export const SET_SELECTED_MTUS = 'SET SELECTED MTUS';
export const SET_RECORD_REVIEW = 'SET RECORD REVIEW';
export const SET_DELETED_MTUS = 'SET DELETED MTUS';
export const SET_ACTIVE_MTUS_MED = 'SET ACTIVE MTUS MED';
export const SET_SELECTED_MED = 'SET SELECTED MED';

export const SET_DWR_PROVIDER = 'SET DWR PROVIDER';
export const SET_DWR_ALPHA = 'SET DWR ALPHA';
export const SET_PANTERA_RPT_CONTENT = 'SET PANTERA RPT CONTENT';
export const SET_SEPULTURA_RPT_CONTENT = 'SET SEPULTURA RPT CONTENT';
export const CLEAR_PANTERA_BODYPARTS = 'CLEAR PANTERA BODYPARTS';
export const CLEAR_SEPULTURA_RPT_CONTENT = 'CLEAR SEPULTURA RPT CONTENT';
export const CLEAR_DWR = 'CLEAR DWR';

export const SHOW_BODY_PART_MODAL = 'SHOW BODY PART MODAL';
export const SET_BODY_PARTS = 'SET BODY PARTS';

export const SET_PATIENT_DIAG = 'SET PATIENT DIAG';
export const SET_PATIENT_DIAG_OTHER = 'SET PATIENT DIAG OTHER';
export const SET_PAST_MEDICAL = 'SET PAST MEDICAL';
export const SET_PAST_RELATED_MEDICAL = 'SET PAST RELATED MEDICAL';
export const SET_JOB_DESC = 'SET JOB DESC';
export const SET_FUNC_IMP = 'SET FUNC IMP';
export const SET_WORKSTATUS = 'SET WORKSTATUS';
export const SET_NOTE = 'SET NOTE';
export const SET_DINJITEMS = 'SET DINJITEMS';
export const SET_SUPAH_BILL = 'SET SUPAH BILL';
export const SET_GENAPP_1 = 'SET GENAPP 1';
export const SET_MPN = 'SET MPN';
export const SET_MINATO = 'SET MINATO';

export const SET_PSNECK = 'SET PSNECK';
export const SET_PSUBACK = 'SET PSUBACK';
export const SET_PSLBACK = 'SET PSLBACK';
export const SET_PSRSHOULDER = 'SET PSRSHOULDER';
export const SET_PSLSHOULDER = 'SET PSLSHOULDER';
export const SET_PSRELBOW = 'SET PSRELBOW';
export const SET_PSLELBOW = 'SET PSLELBOW';
export const SET_PSRWRIST = 'SET PSRWRIST';
export const SET_PSLWRIST = 'SET PSLWRIST';
export const SET_PSRHAND = 'SET PSRHAND';
export const SET_PSLHAND = 'SET PSLHAND';
export const SET_PSOTHER = 'SET PSOTHER';

export const SET_PSRHIP = 'SET PSRHIP';
export const SET_PSLHIP = 'SET PSLHIP';
export const SET_PSRKNEE = 'SET PSRKNEE';
export const SET_PSLKNEE = 'SET PSLKNEE';
export const SET_PSRFOOT = 'SET PSRFOOT';
export const SET_PSLFOOT = 'SET PSLFOOT';

export const SET_PSINTERNAL = 'SET PSINTERNAL';
export const SET_PSNEURO = 'SET PSNEURO';
export const SET_PSNEVRO = 'SET PSNEVRO';
export const SET_PSPSYCHEQ = 'SET PSPSYCHEQ';

// PE
export const SET_GENERAL_APPEARANCE_AND_OTHERS = 'SET GENERAL APPEARANCE AND OTHERS';
export const SET_ABDOMEN = 'SET_ABDOMEN';
export const SET_VITAL_SIGNS = 'SET VITAL SIGNS';

export const SET_HIP_AND_PELVIS = 'SET HIP AND PELVIS';
export const SET_HEENT = 'SET HEENT';
export const SET_KNEES = 'SET KNEES';

export const SET_CERVICAL_SPINE = 'SET CERVICAL SPINE';
export const SET_ANKLE_AND_FOOT = 'SET ANKLE AND FOOT';
export const SET_FOOT = 'SET FOOT';
export const SET_THORACIC_SPINE = 'SET THORACIC SPINE';
// PE HANDS
export const SET_GRIP_STRENGTH = 'SET GRIP STRENGTH';

export const SET_LUMBAR_SPINE = 'SET LUMBAR SPINE';
export const SET_NEURO_EXAM_UPPER_EX = 'SET NEURO EXAM UPPER EX';
export const SET_CHEST_WALL = 'SET CHEST WALL';
export const SET_NEURO_EXAM_LOWER_EX = 'SET NEURO EXAM LOWER EX';
export const SET_SHOULDERS = 'SET SHOULDERS';
export const SET_MEASUREMENT_U_EXTREMITIES = 'SET MEASUREMENT: U EXTREMITIES';

export const SET_ELBOWS = 'SET ELBOWS';
export const SET_MEASUREMENT_L_EXTREMITIES = 'SET MEASUREMENT: L EXTREMITIES';
export const SET_WRIST = 'SET WRIST';
export const SET_HANDS_AND_FINGERS = 'SET HANDS AND FINGERS';

export const SET_BILL_REDUCTION = 'SET BILL REDUCTION';
export const SET_PROCEDURE_CODES = 'SET PROCEDURE CODE';

// /////////////////
// //////// A C U       P U N K K K K K K K K /////////
// /////////////////
// /////////////////
export const ACU_SET_ASST_PROVIDERS = 'ACU SET ASST PROVIDERS';
export const ACU_SET_DINJITEMS = 'ACU SET DINJITEMS';
export const ACU_SET_DWR_PROVIDER = 'ACU SET DWR PROVIDER';
export const ACU_SET_FUNC_IMP = 'ACU SET FUNC IMP';
export const ACU_SET_GENAPP_1 = 'ACU SET GENAPP 1';
export const ACU_SET_JOB_DESC = 'ACU SET JOB DESC';
export const ACU_SET_MINATO = 'ACU SET MINATO';
export const ACU_SET_MPN = 'ACU SET MPN';
export const ACU_SET_OTHER_MEDS = 'ACU SET OTHER MEDS';
export const ACU_SET_PAST_MEDICAL = 'ACU SET PAST MEDICAL';
export const ACU_SET_PAST_RELATED_MEDICAL = 'ACU SET PAST RELATED MEDICAL';
export const ACU_SET_PATIENT = 'ACU SET PATIENT';
export const ACU_SET_PATIENTDOE = 'ACU SET PATIENTDOE';
export const ACU_SET_PATIENT_DIAG = 'ACU SET PATIENT DIAG';
export const ACU_SET_PATIENT_MED = 'ACU SET PATIENT MED';
export const ACU_SET_PREAUTH = 'ACU SET PREAUTH';
export const ACU_SET_PROVIDER = 'ACU SET PROVIDER';
export const ACU_SET_RECORD_REVIEW = 'ACU SET RECORD REVIEW';
export const ACU_SET_SUPAH_BILL = 'ACU SET SUPAH BILL';
export const ACU_SET_TREAT_MISC = 'ACU SET TREAT MISC';
export const ACU_SET_TREAT_OTHER = 'ACU SET TREAT OTHER';
export const ACU_SET_TREAT_PLAN = 'ACU SET TREAT PLAN';
export const ACU_SET_WORKSTATUS = 'ACU_SET_WORKSTATUS';

export const SET_ACU1 = 'SET_ACU1';
export const SET_ACU1_PS = 'SET ACU1 PS';
export const SET_ACU1_PS_ALPHA = 'SET ACU1 PS ALPHA';

export const SET_ACU2 = 'SET_ACU2';

export const ACU_SET_DWR_ALPHA = 'ACU SET DWR ALPHA';
export const ACU_CLEAR_DWR = 'ACU_CLEAR DWR';
export const ACU_SET_PSNECK = 'ACU SET PSNECK';
export const ACU_SET_PSUBACK = 'ACU SET PSUBACK';
export const ACU_SET_PSLBACK = 'ACU SET PSLBACK';
export const ACU_SET_PSRSHOULDER = 'ACU SET PSRSHOULDER';
export const ACU_SET_PSLSHOULDER = 'ACU SET PSLSHOULDER';
export const ACU_SET_PSRELBOW = 'ACU SET PSRELBOW';
export const ACU_SET_PSLELBOW = 'ACU SET PSLELBOW';
export const ACU_SET_PSRWRIST = 'ACU SET PSRWRIST';
export const ACU_SET_PSLWRIST = 'ACU SET PSLWRIST';
export const ACU_SET_PSRHAND = 'ACU SET PSRHAND';
export const ACU_SET_PSLHAND = 'ACU SET PSLHAND';
export const ACU_SET_PSOTHER = 'ACU SET PSOTHER';

export const ACU_SET_PSRHIP = 'ACU SET PSRHIP';
export const ACU_SET_PSLHIP = 'ACU SET PSLHIP';
export const ACU_SET_PSRKNEE = 'ACU SET PSRKNEE';
export const ACU_SET_PSLKNEE = 'ACU SET PSLKNEE';
export const ACU_SET_PSRFOOT = 'ACU SET PSRFOOT';
export const ACU_SET_PSLFOOT = 'ACU SET PSLFOOT';

export const ACU_SET_PSINTERNAL = 'ACU SET PSINTERNAL';
export const ACU_SET_PSNEURO = 'ACU SET PSNEURO';
export const ACU_SET_PSNEVRO = 'ACU SET PSNEVRO';
export const ACU_SET_PSPSYCHEQ = 'ACU SET PSPSYCHEQ';

// PE
export const ACU_SET_GENERAL_APPEARANCE_AND_OTHERS = 'ACU SET GENERAL APPEARANCE AND OTHERS';
export const ACU_SET_ABDOMEN = 'ACU SET_ABDOMEN';
export const ACU_SET_VITAL_SIGNS = 'ACU SET VITAL SIGNS';

export const ACU_SET_HIP_AND_PELVIS = 'ACU SET HIP AND PELVIS';
export const ACU_SET_HEENT = 'ACU SET HEENT';
export const ACU_SET_KNEES = 'ACU SET KNEES';

export const ACU_SET_CERVICAL_SPINE = 'ACU SET CERVICAL SPINE';
export const ACU_SET_ANKLE_AND_FOOT = 'ACU SET ANKLE AND FOOT';
export const ACU_SET_THORACIC_SPINE = 'ACU SET THORACIC SPINE';
// PE HANDS
export const ACU_SET_GRIP_STRENGTH = 'ACU SET GRIP STRENGTH';

export const ACU_SET_LUMBAR_SPINE = 'ACU SET LUMBAR SPINE';
export const ACU_SET_NEURO_EXAM_UPPER_EX = 'ACU SET NEURO EXAM UPPER EX';
export const ACU_SET_CHEST_WALL = 'ACU SET CHEST WALL';
export const ACU_SET_NEURO_EXAM_LOWER_EX = 'ACU SET NEURO EXAM LOWER EX';
export const ACU_SET_SHOULDERS = 'ACU SET SHOULDERS';
export const ACU_SET_MEASUREMENT_U_EXTREMITIES = 'ACU SET MEASUREMENT: U EXTREMITIES';

export const ACU_SET_ELBOWS = 'ACU SET ELBOWS';
export const ACU_SET_MEASUREMENT_L_EXTREMITIES = 'ACU SET MEASUREMENT: L EXTREMITIES';
export const ACU_SET_WRIST = 'ACU SET WRIST';
export const ACU_SET_HANDS_AND_FINGERS = 'ACU SET HANDS AND FINGERS';

export const SET_PROVIDER_OFFICE_LIST = 'SET PROVIDER OFFICE LIST';
export const SET_PROVIDER_OFFICE = 'SET PROVIDER OFFICE';

export const SET_TREAT_CODE = 'SET TREAT CODE';
