import {
  fetchAnkleList,
  fetchAnkle,
  updateAnkleById,
  removeAnkleById,
  createAnkle
} from '@service/api/ankle';

// types
import * as types from '../types';

export const getAnkleList = (payload) => async (dispatch) => {
  return fetchAnkleList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_ANKLE_AND_FOOT,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeAnkle = (payload) => () => {
  return removeAnkleById(payload).then((res) => {
    return res;
  });
};

export const addAnkle = (payload) => () => {
  return createAnkle(payload).then((res) => {
    return res;
  });
};

export const getAnkle = (payload) => () => {
  return fetchAnkle(payload).then((res) => {
    return res;
  });
};

export const updateAnkle = (payload) => () => {
  return updateAnkleById(payload).then((res) => {
    return res;
  });
};
