import axios from 'axios';

// antd
import { message } from 'antd';

// api
import { login, logout, verifyAdmin, logdeeznuts } from '@service/api/account';
import { loginx } from '@service/api/accountx';

// utils
import { decode } from '@utils/methods';
// import { getLocation } from '@utils/common';

// types
import * as types from '../types';

export const setRole = (account) => (dispatch) => {
  dispatch({
    type: types.SET_USER_ROLE,
    payload: account.role === 'admin',
  });
};

export const setUnAuthenticated = () => ({
  type: types.SET_UNAUTHENTICATED,
});

export const setAuthenticated = () => ({
  type: types.SET_AUTHENTICATED,
});

export const checkAdmin = (payload) => async() => {
  const res = await verifyAdmin(payload);
  const { success, data } = res;
  const decoded = decode(data.token);
  let isAdmin = false;

  if (success) {
    isAdmin = (decoded.role === 'admin');
  }
  return isAdmin;
};

export const setAuthorizationHeader = (token) => {
  const bearerToken = `Bearer ${token}`;
  localStorage.setItem('token', token);
  axios.defaults.headers.common.Authorization = bearerToken;
};

export const setRefreshToken = (token) => {
  localStorage.setItem('rtoken', token);
};

export const setUserDetails = (account) => {
  localStorage.setItem('account', JSON.stringify(account));
  setRole(account);

  return {
    type: types.SET_USER_DETAILS,
    payload: account,
  };
};

export const userLogin = payload => async dispatch => {
  const { ht, hkey, ...nayload } = payload;

  if ((!ht || !hkey) && process.env.REACT_APP_NODE_ENV !== 'development') {
    message.error('Need to verify captcha.');
    return false;
  }

  // SB
  const resu = await loginx(nayload);
  if (resu.accessToken) {
    localStorage.setItem('tokenx', resu.accessToken);
  }

  // N
  const res = await login(payload)
  const { success, data, msg } = res;
  if (success) {
    if (data.token) {
      const user = decode(data.token);
      dispatch(setUserDetails(user));
      setAuthorizationHeader(data.token);
      setRefreshToken(data.refreshToken);
      const l = await logdeeznuts({
        email: user.email,
        action: 'LOGGED IN',
      });
      console.log('[L] ', l);
      if (l.success) {
        window.location.href = process.env.REACT_APP_ROUTER_BASE || '';        
      }
    }
  } else {
    message.error(msg);
  }
};

export const userLogout = () => async (dispatch) => {
  const { email } = JSON.parse(localStorage.getItem('account'));  
  const r = await logout({ dig: 'dug' });
  console.log('[LOGOUT RES] ', r);
  if (r.success) {
    const l = await logdeeznuts({ email, action: 'LOGGED OUT' });
    console.log('[LOGDEEZNUTS RES] ', l);
    if (l.success) {
      localStorage.removeItem('tokenx');
      localStorage.removeItem('token');
      localStorage.removeItem('rtoken');
      localStorage.removeItem('account');
      dispatch(setUnAuthenticated());
      window.location.href = process.env.REACT_APP_ROUTER_BASE || '';
    }    
  }
};
