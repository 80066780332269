import {
  fetchPsfootList,
  fetchPsfoot,
  updatePsfootById,
  removePsfootById,
  createPsfoot
} from '@service/api/psfoot';

// types
import * as types from '../types';

export const getPsfootList = (payload) => (dispatch) => {
  return fetchPsfootList(payload).then((res) => {
    if (res.success) {
      if (res.data.docs.length > 0) {
        res.data.docs.map(doc => {
          if (doc.lr === 'right') {
            dispatch({
              type: types.SET_PSRFOOT,
              payload: [doc],
            });
          }
          if (doc.lr === 'left') {
            dispatch({
              type: types.SET_PSLFOOT,
              payload: [doc],
            });
          }
          return doc;
        });
      }
    }
    return res;
  });
};

export const removePsfoot = (payload) => () => {
  return removePsfootById(payload).then((res) => {
    return res;
  });
};

export const addPsfoot = (payload) => () => {
  return createPsfoot(payload).then((res) => {
    return res;
  });
};

export const getPsfoot = (payload) => () => {
  return fetchPsfoot(payload).then((res) => {
    return res;
  });
};

export const updatePsfoot = (payload) => () => {
  return updatePsfootById(payload).then((res) => {
    return res;
  });
};
