// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch providers list
 * @param {*} payload
 */
export async function fetchProvidersList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`providers?${params}`);
}

/**
 * get providers
 * @param {*} payload
 */

export async function fetchProviders(payload) {
  return GET(`providers/${payload}`);
}

/**
 * get providers by pcode
 * @param {*} payload
 */

export async function fetchProvidersByCode(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`providers?${params}`);
}

/**
 * update providers by id
 * @param {*} payload
 */
export async function updateProvidersById(payload) {
  const { id, ...rest } = payload;
  return PUT(`providers/${id}`, rest);
}

/**
 * remove providers by id
 * @param {*} payload
 */
export async function removeProvidersById(payload) {
  return DELETE(`providers/${payload}`);
}

/**
 * create providers
 * @param {*} payload
 */
export async function createProviders(payload) {
  return POST(`providers`, payload);
}

/**
 * authenticate providers
 * @param {*} payload
 */
export async function authenticateProvider(payload) {
  return POST(`providers/authenticate`, payload);
}
