import { rptgen } from '@redux/combineActions';

import { fetchPatientsList, fetchPatients, updatePatientsById, removePatientsById, removePatientsByIds, createPatients } from '@service/api/patients';

// utils
import { loadPatientMed, loadReqTreatStoragex } from '@utils/common';

// types
import * as types from '../types';

export const setExamId = params => {
  if (params !== null) {
    sessionStorage.setItem('examId', params);
  } else {
    sessionStorage.removeItem('examId');
  }

  return {
    type: types.SET_EXAM_ID,
    payload: params,
  };
};

export const setPatientId = params => {
  if (params !== null) {
    sessionStorage.setItem('patientId', params);
  } else {
    sessionStorage.removeItem('patientId');
  }

  return {
    type: types.SET_PATIENT_ID,
    payload: params,
  };
};

export const setNewPatient = params => {
  return {
    type: types.SET_NEW_PATIENT,
    payload: params,
  };
};

export const setNewPatientByField = params => {
  return {
    type: types.SET_NEW_PATIENT_BY_FIELD,
    payload: params,
  };
};

export const setPatient = params => {
  if (params && params !== null) {
    const mutat = {
      ...params,
      ssn: params.ssn.length === 9 ? params.ssn : '',
    };
    sessionStorage.setItem('patient', JSON.stringify(mutat));
  } else {
    sessionStorage.removeItem('patient');
  }

  return {
    type: types.SET_PATIENT,
    payload: params,
  };
};

export const setCaseref = params => {
  return {
    type: types.SET_CASEREF,
    payload: params,
  };
};

export const setScreener = params => {
  return {
    type: types.SET_SCREENER,
    payload: params,
  };
};

export const setPreauth = params => {
  return {
    type: types.SET_PREAUTH,
    payload: [params],
  };
};

export const setPatientDemo = params => {
  return {
    type: types.SET_PATIENTDEMO,
    payload: params,
  };
};

export const setPatientDoe = params => {
  if (params && params !== null) {
    sessionStorage.setItem('patientdoe', JSON.stringify(params));
  } else {
    sessionStorage.removeItem('patientdoe');
  }

  return {
    type: types.SET_PATIENTDOE,
    payload: params,
  };
};

export const getPatientsList = payload => async () => {
  const res = await fetchPatientsList(payload);
  return res;
};

export const removePatient = payload => async () => {
  const res = await removePatientsById(payload);
  return res;
};

export const removePatients = payload => async () => {
  const res = await removePatientsByIds(payload);
  return res;
};

export const addPatients = payload => async () => {
  const res = await createPatients(payload);
  return res;
};

export const getPatients = payload => async () => {
  const res = await fetchPatients(payload);
  return res;
};

export const updatePatients = payload => async () => {
  const res = await updatePatientsById(payload);
  return res;
};

export const triggerDoeListTap = (record, patientid) => async (dispatch, getState) => {
  // console.log('[triggerDoeListTap]');

  const { _id } = record;
  const { pantera } = getState().rptgen.templates;

  dispatch(
    rptgen.preauth.getPreauthList({
      pageSize: 50,
      pageIndex: 1,
      patientid,
    })
  );

  const payload = { pageSize: 250, pageIndex: 1, patientid: _id };

  dispatch(rptgen.patients.setExamId(_id));
  dispatch(rptgen.patients.setPatientDoe(record));
  dispatch(rptgen.pastmedicalhistory.getPastmedicalhistoryList(payload));
  dispatch(rptgen.pastrelatedmedicalhistory.getPastrelatedmedicalhistoryList(payload));
  dispatch(rptgen.jobdesc.getJobdescList(payload));
  dispatch(rptgen.funcimp.getFuncimpList(payload));
  dispatch(rptgen.workstatus.getWorkstatusList(payload));
  dispatch(rptgen.note.getNoteList(payload));

  dispatch(rptgen.dateofinjuries.getDinjList(payload));
  dispatch(rptgen.superbill.getSuperbillList(payload));
  dispatch(rptgen.recordreview.getRecordReviewList(payload));
  dispatch(rptgen.billreduc.getBillReducList(payload));

  // PSCOMPLAINTS
  dispatch(rptgen.psneck.getPsneckList(payload));
  dispatch(rptgen.psuback.getPsubackList(payload));
  dispatch(rptgen.pslback.getPslbackList(payload));
  dispatch(rptgen.psshoulder.getPsshoulderList(payload));
  dispatch(rptgen.pselbow.getPselbowList(payload));
  dispatch(rptgen.pswrist.getPswristList(payload));
  dispatch(rptgen.pshand.getPshandList(payload));
  dispatch(rptgen.pship.getPshipList(payload));
  dispatch(rptgen.psknee.getPskneeList(payload));
  dispatch(rptgen.psfoot.getPsfootList(payload));
  dispatch(rptgen.neuro.getNeuroList(payload));
  dispatch(rptgen.internalmed.getInternalmedList(payload));
  dispatch(rptgen.psyche.getPsycheList(payload));
  dispatch(rptgen.psotherbodypart.getPsotherbodypartList(payload));
  dispatch(rptgen.nevro.getNevroList(payload));
    
  // PE EXAMINATION
  dispatch(rptgen.abdomen.getAbdomenList(payload));
  dispatch(rptgen.ankle.getAnkleList(payload));
  dispatch(rptgen.foot.getFootList(payload));
  dispatch(rptgen.cervicalspine.getCervicalspineList(payload));
  dispatch(rptgen.chestwall.getChestwallList(payload));
  dispatch(rptgen.elbows.getElbowsList(payload));

  // USING FEET MODEL
  // WILL RENAME IN THA FUTURE
  dispatch(rptgen.neurologicalexamle.getNeurologicalexamleList(payload));

  dispatch(rptgen.fingers.getFingersList(payload));
  dispatch(rptgen.genapp.getGenappList(payload));
  dispatch(rptgen.hands.getHandsList(payload));
  dispatch(rptgen.heent.getHeentList(payload));
  dispatch(rptgen.hipthigh.getHipthighList(payload));
  dispatch(rptgen.knees.getKneesList(payload));
  dispatch(rptgen.lumbarspine.getLumbarspineList(payload));
  dispatch(rptgen.neurologicalexamle.getNeurologicalexamleList(payload));
  dispatch(rptgen.neurologicalexamue.getNeurologicalexamueList(payload));
  dispatch(rptgen.neurologicalexamue.getNeurologicalexamueList(payload));
  dispatch(rptgen.pelvis.getPelvisList(payload));
  dispatch(rptgen.shoulders.getShouldersList(payload));
  dispatch(rptgen.thoracicspine.getThoracicspineList(payload));
  dispatch(rptgen.vitalsign.getVitalsignList(payload));
  dispatch(rptgen.wrist.getWristList(payload));
  dispatch(rptgen.genapp1.getGenapp1List(payload));
  dispatch(rptgen.othermeds.getOthermedsList(payload));
  dispatch(rptgen.treatother.getTreatotherList(payload));
  dispatch(rptgen.acu1.getAcu1List(payload));
  dispatch(rptgen.acu2.getAcu2List(payload));

  // DIAGNOSIS
  dispatch(rptgen.patientdiag.getPatientDiagList(payload));
  dispatch(rptgen.patientdiagother.getPatientDiagOtherList(payload));
  dispatch(rptgen.treatmentplan.getTreatmentplanList(payload)).then(res => {
    if (res.data.docs.length > 0) {
      const { ptera, stura } = loadReqTreatStoragex(pantera, res.data.docs);
      dispatch(rptgen.patientmed.getPatientmedList(payload)).then(pmedRes => {
        if (pmedRes.data.docs.length > 0) {
          const { pmtera, smtura } = loadPatientMed(ptera, stura, pmedRes.data.docs);

          dispatch(rptgen.templates.updatePanteraTemplate({ bodyparts: pmtera.bodyparts }));
          dispatch(rptgen.templates.updatePanteraTemplate({ rfa: pmtera.rfa }));
          dispatch(rptgen.templates.updateSepulturaTemplate(smtura));
        }
      });
      dispatch(rptgen.templates.updatePanteraTemplate({ bodyparts: ptera.bodyparts }));
      dispatch(rptgen.templates.updatePanteraTemplate({ rfa: ptera.rfa }));
      dispatch(rptgen.templates.updateSepulturaTemplate(stura));
    }
  });
  /*
  // DIAGNOSIS
  dispatch(rptgen.patientdiag.getPatientDiagList(payload))
  .then(resD => {
    // TREATMENT PLAN
    dispatch(rptgen.patientmed.getPatientmedList(payload))
    dispatch(rptgen.treatmentplan.getTreatmentplanList(payload))
    .then(res => {
      dispatch(rptgen.templates.updatePanteraTemplate({ bodyparts: undefined }));
      dispatch(rptgen.templates.updatePanteraTemplate({ rfa: undefined }));
      // dispatch(rptgen.templates.updateSepulturaTemplate({ });
      if (res.data.docs.length > 0) {
        const { ptera, stura } = loadReqTreatStoragex(pantera, res.data.docs);
        dispatch(rptgen.templates.updatePanteraTemplate({ bodyparts: ptera.bodyparts }));
        dispatch(rptgen.templates.updatePanteraTemplate({ rfa: ptera.rfa }));
        dispatch(rptgen.templates.updateSepulturaTemplate(stura));
      }
    });
  });
  */
  return {};
};
