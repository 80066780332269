import { evaluateBooleanFields, evaluateBooleanFieldsArray } from '@utils/methods';
import * as types from '../types';


const initialState = {
  provider: undefined,
  minato: {
    provider: undefined,
    providers: undefined,
    digital: false,
    level: 100,
    acu: undefined,
    dinjitems: undefined,
    funcimp: undefined,
    hoaid: undefined,
    hoaitems: undefined,
    hoctitems: undefined,
    hotitems: undefined,
    hpm: undefined,
    hpm1: undefined,
    hrpm: undefined,
    hrpm1: undefined,
    intrnl: undefined,
    jobdesc: undefined,
    lback: undefined,
    lelbow: undefined,
    lfoot: undefined,
    lhip: undefined,
    lhwrist: undefined,
    lknee: undefined,
    lshoulder: undefined,
    lwwrist: undefined,
    meval: undefined,
    mpn: undefined,
    neck: undefined,
    neu: undefined,
    nev: undefined,
    otha: undefined,
    othabp: undefined,
    overlaybodypart: undefined,
    patdoe: undefined,
    patient: undefined,
    patientid: undefined,
    patitems: undefined,
    pauth: undefined,
    pdiag: undefined,
    peabdomen: undefined,
    peankle: undefined,
    pecspine: undefined,
    pecwall: undefined,
    peelbows: undefined,
    pefeet: undefined,
    pefoot: undefined,
    pefingers: undefined,
    pegenapp: undefined,
    pegenapp1: undefined,
    pehands: undefined,
    peheent: undefined,
    pehpthigh: undefined,
    peknees: undefined,
    pelspine: undefined,
    penlexle: undefined,
    penlexue: undefined,
    pepelv: undefined,
    peshldrs: undefined,
    petspine: undefined,
    pevitalsign: undefined,
    pewrist: undefined,
    pmed: undefined,
    psy: undefined,
    recv: undefined,
    relbow: undefined,
    rfoot: undefined,
    rhip: undefined,
    rhwrist: undefined,
    rknee: undefined,
    rshoulder: undefined,
    rwwrist: undefined,
    sbill: undefined,
    tplan: undefined,
    tpr: undefined,
    tro: undefined,
    trp: undefined,
    uback: undefined,
    urden: undefined,
    workstatus: undefined,
    othermeds: undefined,
    misc: undefined,
    acu1: undefined,
    acu2: undefined,
    acu3: undefined,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ACU_CLEAR_DWR:
      return { ...state, ...initialState };
    case types.ACU_SET_DWR_PROVIDER:
      return { ...state, provider: action.payload };
    case types.ACU_SET_DWR_ALPHA:
      return { ...state, minato: { ...state.minato, ...action.payload } };
    case types.ACU_SET_MINATO:
      return {
        ...state,
        minato: action.payload,
      };
    case types.ACU_SET_PATIENT:
      return {
        ...state,
        minato: { ...state.minato, ...{ patient: action.payload, patitems: [action.payload] }},
      };
    case types.ACU_SET_PREAUTH:
      return {
        ...state,
        minato: { ...state.minato, ...{ pauth: action.payload[0] ? action.payload[0] : undefined }},
      };
    case types.ACU_SET_JOB_DESC:
      return {
        ...state,
        minato: { ...state.minato, ...{ jobdesc: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_FUNC_IMP:
      return {
        ...state,
        minato: { ...state.minato, ...{ funcimp: action.payload[0] ? action.payload[0] : undefined }},
      };
    case types.ACU_SET_WORKSTATUS:
      return {
        ...state,
        minato: { ...state.minato, ...{ workstatus: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };

    case types.ACU_SET_PAST_MEDICAL:
      return {
        ...state,
        minato: { ...state.minato, ...{ hpm1: evaluateBooleanFieldsArray(action.payload) }},
      };
    case types.ACU_SET_PAST_RELATED_MEDICAL:
      return {
        ...state,
        minato: { ...state.minato, ...{ hrpm1: evaluateBooleanFieldsArray(action.payload) }},
      };

    case types.ACU_SET_PATIENTDOE:
      return {
        ...state,
        minato: { ...state.minato, ...{ patientdoe: action.payload }},
      };

    case types.ACU_SET_PATIENT_DIAG:
      return {
        ...state,
        minato: { ...state.minato, ...{ pdiag: action.payload[0] ? action.payload[0] : undefined }},
      };

    case types.ACU_SET_PROVIDER:
      return {
        ...state,
        minato: { ...state.minato, ...{ provider: action.payload }},
      };
    case types.ACU_SET_ASST_PROVIDERS:
      return {
        ...state,
        minato: { ...state.minato, ...{ providers: action.payload }},
      };
    case types.ACU_SET_DINJITEMS:
      return {
        ...state,
        minato: { ...state.minato, ...{ dinjitems: evaluateBooleanFieldsArray(action.payload) }},
      };

    case types.ACU_SET_RECORD_REVIEW:
      return {
        ...state,
        minato: { ...state.minato, ...{ recv: action.payload ? action.payload : undefined }},
      };
    case types.ACU_SET_SUPAH_BILL:
      return {
        ...state,
        minato: { ...state.minato, ...{ sbill: action.payload[0] ? action.payload[0] : undefined }},
      };
    case types.ACU_SET_GENAPP_1:
      return {
        ...state,
        minato: { ...state.minato, ...{ pegenapp1: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_MPN:
      return {
        ...state,
        minato: { ...state.minato, ...{ mpn: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };

    // TREATMENT PLAN
    case types.ACU_SET_TREAT_PLAN:
      return {
        ...state,
        minato: { ...state.minato, ...{ tplan: action.payload ? action.payload : undefined }},
      };

  case types.ACU_SET_TREAT_OTHER:
    return {
      ...state,
      minato: { ...state.minato, ...{ tro: action.payload ? action.payload : undefined }},
    };

    case types.ACU_SET_OTHER_MEDS:
      return {
        ...state,
        minato: { ...state.minato, ...{ othermeds: action.payload ? action.payload : undefined }},
      };

    case types.ACU_SET_PATIENT_MED:
      return {
        ...state,
        minato: { ...state.minato, ...{ pmed: action.payload ? action.payload : undefined }},
      };

    case types.ACU_SET_TREAT_MISC:
      return {
        ...state,
        minato: { ...state.minato, ...{ misc: action.payload ? action.payload : undefined }},
      };

    // PS
    case types.ACU_SET_PSNECK:
      return {
        ...state,
        minato: { ...state.minato, ...{ neck: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSUBACK:
      return {
        ...state,
        minato: { ...state.minato, ...{ uback: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSLBACK:
      return {
        ...state,
        minato: { ...state.minato, ...{ lback: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSRSHOULDER:
      return {
        ...state,
        minato: { ...state.minato, ...{ rshoulder: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSLSHOULDER:
      return {
        ...state,
        minato: { ...state.minato, ...{ lshoulder: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSRELBOW:
      return {
        ...state,
        minato: { ...state.minato, ...{ relbow: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSLELBOW:
      return {
        ...state,
        minato: { ...state.minato, ...{ lelbow: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSRWRIST:
      return {
        ...state,
        minato: { ...state.minato, ...{ rwwrist: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSLWRIST:
      return {
        ...state,
        minato: { ...state.minato, ...{ lwwrist: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSRHAND:
      return {
        ...state,
        minato: { ...state.minato, ...{ rhwrist: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSLHAND:
      return {
        ...state,
        minato: { ...state.minato, ...{ lhwrist: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSOTHER:
      return {
        ...state,
        minato: { ...state.minato, ...{ othabp: action.payload ? action.payload : undefined }},
      };
    case types.ACU_SET_PSRHIP:
      return {
        ...state,
        minato: { ...state.minato, ...{ rhip: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSLHIP:
      return {
        ...state,
        minato: { ...state.minato, ...{ lhip: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSRKNEE:
      return {
        ...state,
        minato: { ...state.minato, ...{ rknee: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSLKNEE:
      return {
        ...state,
        minato: { ...state.minato, ...{ lknee: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSRFOOT:
      return {
        ...state,
        minato: { ...state.minato, ...{ rfoot: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSLFOOT:
      return {
        ...state,
        minato: { ...state.minato, ...{ lfoot: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSINTERNAL:
      return {
        ...state,
        minato: { ...state.minato, ...{ intrnl: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSNEURO:
      return {
        ...state,
        minato: { ...state.minato, ...{ neu: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_PSPSYCHEQ:
      return {
        ...state,
        minato: { ...state.minato, ...{ psy: action.payload ? action.payload : undefined }},
      };
    case types.ACU_SET_PSNEVRO:
      return {
        ...state,
        minato: { ...state.minato, ...{ nev: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };


    // PE
    case types.ACU_SET_GENERAL_APPEARANCE_AND_OTHERS:
      return {
        ...state,
        minato: { ...state.minato, ...{ pegenapp: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_ABDOMEN:
      return {
        ...state,
        minato: { ...state.minato, ...{ peabdomen: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_VITAL_SIGNS:
      return {
        ...state,
        minato: { ...state.minato, ...{ pevitalsign: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };

    case types.ACU_SET_HIP_AND_PELVIS:
      return {
        ...state,
        minato: { ...state.minato, ...{ pehpthigh: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_HEENT:
      return {
        ...state,
        minato: { ...state.minato, ...{ peheent: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_KNEES:
      return {
        ...state,
        minato: { ...state.minato, ...{ peknees: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };

    case types.ACU_SET_CERVICAL_SPINE:
      return {
        ...state,
        minato: { ...state.minato, ...{ pecspine: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_ANKLE_AND_FOOT:
      return {
        ...state,
        minato: { ...state.minato, ...{ peankle: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_THORACIC_SPINE:
      return {
        ...state,
        minato: { ...state.minato, ...{ petspine: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    // PE HANDS
    case types.ACU_SET_GRIP_STRENGTH:
      return {
        ...state,
        minato: { ...state.minato, ...{ pehands: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };

    case types.ACU_SET_LUMBAR_SPINE:
      return {
        ...state,
        minato: { ...state.minato, ...{ pelspine: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_NEURO_EXAM_UPPER_EX:
      return {
        ...state,
        minato: { ...state.minato, ...{ penlexue: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_CHEST_WALL:
      return {
        ...state,
        minato: { ...state.minato, ...{ pecwall: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_NEURO_EXAM_LOWER_EX:
      return {
        ...state,
        minato: { ...state.minato, ...{ penlexle: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_SHOULDERS:
      return {
        ...state,
        minato: { ...state.minato, ...{ peshldrs: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_MEASUREMENT_U_EXTREMITIES:
      return {
        ...state,
        minato: { ...state.minato, ...{ pepelv: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };

    case types.ACU_SET_ELBOWS:
      return {
        ...state,
        minato: { ...state.minato, ...{ peelbows: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_MEASUREMENT_L_EXTREMITIES:
      return {
        ...state,
        minato: { ...state.minato, ...{ pefeet: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_WRIST:
      return {
        ...state,
        minato: { ...state.minato, ...{ pewrist: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };
    case types.ACU_SET_HANDS_AND_FINGERS:
      return {
        ...state,
        minato: { ...state.minato, ...{ pefingers: action.payload[0] ? evaluateBooleanFields(action.payload[0]) : undefined }},
      };


    default:
      return state;
  }
};

export default reducer;
