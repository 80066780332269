import {
  fetchMiscList,
  fetchMisc,
  updateMiscById,
  removeMiscById,
  createMisc,
  removeMiscByPatientId,
} from '@service/api/misc';

// types
import * as types from '../types';

export const getMiscList = (payload) => async (dispatch) => {
  return fetchMiscList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_TREAT_MISC,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeMisc = (payload) => () => {
  return removeMiscById(payload).then((res) => {
    return res;
  });
};

export const clearMisc = (payload) => async () => {
  const res = await removeMiscByPatientId(payload);
  return res;
};

export const addMisc = (payload) => () => {
  return createMisc(payload).then((res) => {
    return res;
  });
};

export const getMisc = (payload) => () => {
  return fetchMisc(payload).then((res) => {
    return res;
  });
};

export const updateMisc = (payload) => () => {
  return updateMiscById(payload).then((res) => {
    return res;
  });
};
