import { lazy } from 'react';

export const mil = lazy(() => import('./mil'));
export const superbills = lazy(() => import('./superbills'));
export const workstatus = lazy(() => import('./workstatus'));
export const physicalexamination = lazy(() => import('./physicalexamination'));
export const diagnosis = lazy(() => import('./diagnosis'));
export const recrev = lazy(() => import('./recrev'));
export const billreduc = lazy(() => import('./billreduc'));
export const treatmentplan = lazy(() => import('./treatmentplan'));
