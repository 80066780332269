// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch dateofinjuries list
 * @param {*} payload
 */
export async function fetchDateofinjuriesList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`dateofinjuries?${params}`);
}

/**
 * get dateofinjuries
 * @param {*} payload
 */

export async function fetchDateofinjuries(payload) {
  return GET(`dateofinjuries/${payload}`);
}

/**
 * update dateofinjuries by id
 * @param {*} payload
 */
export async function updateDateofinjuriesById(payload) {
  const { id, ...rest } = payload;
  return PUT(`dateofinjuries/${id}`, rest);
}

/**
 * remove dateofinjuries by id
 * @param {*} payload
 */
export async function removeDateofinjuriesById(payload) {
  return DELETE(`dateofinjuries/${payload}`);
}

/**
 * create dateofinjuries
 * @param {*} payload
 */
export async function createDateofinjuries(payload) {
  return POST(`dateofinjuries`, payload);
}
