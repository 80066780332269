// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch misc list
 * @param {*} payload
 */
export async function fetchMiscList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`misc?${params}`);
}

/**
 * get misc
 * @param {*} payload
 */

export async function fetchMisc(payload) {
  return GET(`misc/${payload}`);
}

/**
 * update misc by id
 * @param {*} payload
 */
export async function updateMiscById(payload) {
  const { id, ...rest } = payload;
  return PUT(`misc/${id}`, rest);
}

/**
 * remove misc by id
 * @param {*} payload
 */
export async function removeMiscById(payload) {
  return DELETE(`misc/${payload}`);
}

/**
 * remove misc by id
 * @param {*} payload
 */
export async function removeMiscByPatientId(payload) {
  const params = methods.convertQueryString(payload);
  return DELETE(`misc?${params}`);
}

/**
 * create misc
 * @param {*} payload
 */
export async function createMisc(payload) {
  return POST(`misc`, payload);
}
