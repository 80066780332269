import axios from 'axios';

import { message } from 'antd';

const baseUrl = process.env.REACT_APP_SPRING_URL;

const axiosx = axios.create();
// request header configuration
const tokenx = localStorage.getItem('tokenx');
if (tokenx !== null) {
  axiosx.defaults.headers.common.Authorization = `Bearer ${tokenx}`;
  axios.defaults.headers.common.AccessControlAllowHeaders = 'Content-Dispostion';
}
axiosx.defaults.headers.post.Accept = 'application/json, text/plain, */*';
/**
 * handle check request method
 * based on method, use axios to handle request
 * @param url
 * @param options
 * @returns {Promise<AxiosResponse<T>>}
 */
const requestMethod = (url, options = {}) => {
  const { method = 'GET', ...option } = options;
  switch (method) {
    case 'GET':
      return axiosx.get(baseUrl + url, option);
    case 'POST':
      return axiosx.post(baseUrl + url, option);
    case 'POST_X':
      return axiosx.post(baseUrl + url, option, { responseType: 'arraybuffer' });
    case 'POST_FORM_DATA':
      return axiosx.post(baseUrl + url, option.data);
    case 'PUT':
      return axiosx.put(baseUrl + url, option);
    case 'DELETE':
      return axiosx.delete(baseUrl + url, { data: option });
    case 'GET_BLOB':
      return axiosx.get(baseUrl + url, { responseType: 'blob', timeout: 30000 });
    case 'GET_ABUF':
      return axiosx.get(baseUrl + url, { responseType: 'arraybuffer', timeout: 30000 });      
    default:
      return axiosx.get(baseUrl + url, option);
  }
};

/**
 * Check the response status
 * @param res
 * @returns {*}
 */
const checkApiStatus = res => {
  if (res.status >= 200 && res.status < 300) return res;
  message.error(`${res}`);
  return false;
};

/**
 * handle response data format
 * @param res
 * @returns {Promise<{data: *, message: *, status: *}>}
 */
const handleResponseData = res => {
  const { data } = res;
  return Promise.resolve(data);
};

/**
 * handle throw error
 * @param err
 * @returns {Promise<never>}
 */
const handleThrowError = err => {
  //console.log('[PEOW HERE1] ', err, err.response);
  if (err.response) {
    if (err.response.status === 401) {
      //console.log('[PEOW HERE2] ', err, err.response);
      if (err.response.data.msg) {
        message.error(err.response.data.msg);
      }
      if (err.response.data.message) {
        message.error(err.response.data.message);
      }

      return Promise.reject(err);
    } else {
      //console.log('[PEOW HERE3] ', err, err.response.data);
      if (err.response.data) {
        if (err.response.data.msg) {
          message.error(err.response.data.msg);
          return Promise.reject(err);
        }
      }
    }
  }

  if (err.toJSON()) {
    // console.log('[PEOW HERE4] ', err, err.response);
    const { message: msg } = err.toJSON();
    message.error(`[SB]: ${msg}`);
  }

  //console.log('[PEOW HERE5] ', err, err.response);
  return Promise.reject(err);
};

/**
 * Overall fetch method
 * @param url
 * @param options
 * @returns {Promise<{data: *, status: *} | never>}
 */
const fetch = (url, options) => {
  return requestMethod(url, options).then(checkApiStatus).then(handleResponseData).catch(handleThrowError);
};

/**
 * handle GET request
 * @param url
 * @param options
 * @constructor
 */
export const GET = (url, options) => fetch(url, { ...options, method: 'GET' });

/**
 * handle POST request
 * @param url
 * @param options
 * @constructor
 */
export const POST = (url, options) => fetch(url, { ...options, method: 'POST' });
export const POST_X = (url, options) => fetch(url, { ...options, method: 'POST_X' });
/**
 * handle POST request
 * @param url
 * @param options
 * @constructor
 */
export const POST_FORM_DATA = (url, options) => fetch(url, { ...options, method: 'POST_FORM_DATA' });

/**
 * handle PUT request
 * @param url
 * @param options
 * @constructor
 */
export const PUT = (url, options) => fetch(url, { ...options, method: 'PUT' });

/**
 * handle delete request
 * @param url
 * @param options
 * @constructor
 */
export const DELETE = (url, options) => fetch(url, { ...options, method: 'DELETE' });

/**
 * handle GET BLOB request
 * @param url
 * @param options
 * @constructor
 */
export const GET_BLOB = (url, options) => fetch(url, { ...options, method: 'GET_BLOB' });

export const GET_ABUF = (url, options) => fetch(url, { ...options, method: 'GET_ABUF' });
