import {
  fetchPastrelatedmedicalhistoryList,
  fetchPastrelatedmedicalhistory,
  updatePastrelatedmedicalhistoryById,
  removePastrelatedmedicalhistoryById,
  createPastrelatedmedicalhistory
} from '@service/api/pastrelatedmedicalhistory';

// types
import * as types from '../types';

export const setPastRelatedMedical = (params) => {
  return {
    type: types.SET_PAST_RELATED_MEDICAL,
    payload: params,
  };
};

export const getPastrelatedmedicalhistoryList = (payload) => (dispatch) => {
  return fetchPastrelatedmedicalhistoryList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PAST_RELATED_MEDICAL,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePastrelatedmedicalhistory = (payload) => () => {
  return removePastrelatedmedicalhistoryById(payload).then((res) => {
    return res;
  });
};

export const addPastrelatedmedicalhistory = (payload) => () => {
  return createPastrelatedmedicalhistory(payload).then((res) => {
    return res;
  });
};

export const getPastrelatedmedicalhistory = (payload) => () => {
  return fetchPastrelatedmedicalhistory(payload).then((res) => {
    return res;
  });
};

export const updatePastrelatedmedicalhistory = (payload) => () => {
  return updatePastrelatedmedicalhistoryById(payload).then((res) => {
    return res;
  });
};
