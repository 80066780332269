import {
  fetchPatientDiagOtherList,
  fetchPatientDiagOther,
  updatePatientDiagOtherById,
  removePatientDiagOtherById,
  createPatientDiagOther
} from '@service/api/patientdiagother';

// types
import * as types from '../types';

export const getPatientDiagOtherList = (payload) => async (dispatch) => {
  return fetchPatientDiagOtherList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PATIENT_DIAG_OTHER,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePatientDiagOther = (payload) => () => {
  return removePatientDiagOtherById(payload).then((res) => {
    return res;
  });
};

export const addPatientDiagOther = (payload) => () => {
  return createPatientDiagOther(payload).then((res) => {
    return res;
  });
};

export const getPatientDiagOther = (payload) => () => {
  return fetchPatientDiagOther(payload).then((res) => {
    return res;
  });
};

export const updatePatientDiagOther = (payload) => () => {
  return updatePatientDiagOtherById(payload).then((res) => {
    return res;
  });
};
