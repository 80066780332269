import {
  fetchPsubackList,
  fetchPsuback,
  updatePsubackById,
  removePsubackById,
  createPsuback
} from '@service/api/psuback';

// types
import * as types from '../types';

export const getPsubackList = (payload) => async (dispatch) => {
  return fetchPsubackList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PSUBACK,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePsuback = (payload) => () => {
  return removePsubackById(payload).then((res) => {
    return res;
  });
};

export const addPsuback = (payload) => () => {
  return createPsuback(payload).then((res) => {
    return res;
  });
};

export const getPsuback = (payload) => () => {
  return fetchPsuback(payload).then((res) => {
    return res;
  });
};

export const updatePsuback = (payload) => () => {
  return updatePsubackById(payload).then((res) => {
    return res;
  });
};
