import {
  fetchGenappList,
  fetchGenapp,
  updateGenappById,
  removeGenappById,
  createGenapp
} from '@service/api/genapp';

// types
import * as types from '../types';

export const getGenappList = (payload) => async (dispatch) => {
  return fetchGenappList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_GENERAL_APPEARANCE_AND_OTHERS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeGenapp = (payload) => () => {
  return removeGenappById(payload).then((res) => {
    return res;
  });
};

export const addGenapp = (payload) => () => {
  return createGenapp(payload).then((res) => {
    return res;
  });
};

export const getGenapp = (payload) => () => {
  return fetchGenapp(payload).then((res) => {
    return res;
  });
};

export const updateGenapp = (payload) => () => {
  return updateGenappById(payload).then((res) => {
    return res;
  });
};
