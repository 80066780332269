// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch genapp1 list
 * @param {*} payload
 */
export async function fetchGenapp1List(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`genapp1?${params}`);
}

/**
 * get genapp1
 * @param {*} payload
 */

export async function fetchGenapp1(payload) {
  return GET(`genapp1/${payload}`);
}

/**
 * update genapp1 by id
 * @param {*} payload
 */
export async function updateGenapp1ById(payload) {
  const { id, ...rest } = payload;
  return PUT(`genapp1/${id}`, rest);
}

/**
 * remove genapp1 by id
 * @param {*} payload
 */
export async function removeGenapp1ById(payload) {
  return DELETE(`genapp1/${payload}`);
}

/**
 * create genapp1
 * @param {*} payload
 */
export async function createGenapp1(payload) {
  return POST(`genapp1`, payload);
}
