// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch nevro list
 * @param {*} payload
 */
export async function fetchNevroList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`nevro?${params}`);
}

/**
 * get nevro
 * @param {*} payload
 */

export async function fetchNevro(payload) {
  return GET(`nevro/${payload}`);
}

/**
 * update nevro by id
 * @param {*} payload
 */
export async function updateNevroById(payload) {
  const { id, ...rest } = payload;
  return PUT(`nevro/${id}`, rest);
}

/**
 * remove nevro by id
 * @param {*} payload
 */
export async function removeNevroById(payload) {
  return DELETE(`nevro/${payload}`);
}

/**
 * create nevro
 * @param {*} payload
 */
export async function createNevro(payload) {
  return POST(`nevro`, payload);
}
