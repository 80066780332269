import { rptgen } from '@redux/combineActions';

import {
  fetchTreatmentplanList,
  fetchTreatmentplan,
  updateTreatmentplanById,
  removeTreatmentplanById,
  createTreatmentplan,
  removeTreatmentplanByPatientId,
} from '@service/api/treatmentplan';

import {
  fetchTreatmentPlansetList,
} from '@service/api/treatmentplanset';

// utils
import { loadPatientMed, loadReqTreatStoragex } from '@utils/common';

// types
import * as types from '../types';

export const handleRefreshTreatmentPlanList = () => (dispatch, getState) => {
  const { examId } = getState().rptgen.patients;
  const { pantera } = getState().rptgen.templates;
  const payload = { pageSize: 250, pageIndex: 1, patientid: examId };
  // DIAGNOSIS
  dispatch(rptgen.patientdiag.getPatientDiagList(payload));
  dispatch(rptgen.patientdiagother.getPatientDiagOtherList(payload));

  dispatch(rptgen.treatmentplan.getTreatmentplanList(payload))
  .then(res => {
    const { ptera, stura } = loadReqTreatStoragex(pantera, res.data.docs.length > 0 ? res.data.docs : []);
    /*
    dispatch(rptgen.templates.updatePanteraTemplate({ bodyparts: ptera.bodyparts }));
    dispatch(rptgen.templates.updatePanteraTemplate({ rfa: ptera.rfa }));
    */

    dispatch(rptgen.templates.updatePanteraTemplate({
      bodyparts: ptera.bodyparts,
      rfa: ptera.rfa,
    }));
    dispatch(rptgen.templates.updateSepulturaTemplate(stura));

    /*
    if (res.data.docs.length > 0) {
      // const { ptera, stura } = loadReqTreatStoragex(pantera, res.data.docs);
    }
    */

    dispatch(rptgen.patientmed.getPatientmedList(payload))
    .then(pmedRes => {
      // console.log('[handleRefreshTreatmentPlanList 2] ', pmedRes);
      const { pmtera, smtura } = loadPatientMed(ptera, stura, pmedRes.data.docs.length > 0 ? pmedRes.data.docs : []);
      /*
      dispatch(rptgen.templates.updatePanteraTemplate({ bodyparts: pmtera.bodyparts }));
      dispatch(rptgen.templates.updatePanteraTemplate({ rfa: pmtera.rfa }));
      */
      dispatch(rptgen.templates.updatePanteraTemplate({
        bodyparts: pmtera.bodyparts,
        rfa: pmtera.rfa,
      }));
      dispatch(rptgen.templates.updateSepulturaTemplate(smtura));

      /*
      if (pmedRes.data.docs.length > 0) {

      }
      */
    });
  });
};

export const getTreatmentplanList = (payload) => async (dispatch) => {
  return fetchTreatmentplanList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_TREAT_PLAN,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const getMtusListByTpBp = (payload) => async () => {
  const mutat = payload.tp === 'treatemg'
  ? payload
  : { 
    ...payload,
    bp: payload.bp.replace("RIGHT ", "").replace("LEFT ", "")
  };
  const res = await fetchTreatmentPlansetList(mutat);
  return res;
};

export const removeTreatmentplan = (payload) => () => {
  return removeTreatmentplanById(payload).then((res) => {
    return res;
  });
};

export const clearTreatmentplan = (payload) => async () => {
  const res = await removeTreatmentplanByPatientId(payload);
  return res;
};

export const addTreatmentplan = (payload) => () => {
  return createTreatmentplan(payload).then((res) => {
    return res;
  });
};

export const getTreatmentplan = (payload) => () => {
  return fetchTreatmentplan(payload).then((res) => {
    return res;
  });
};

export const updateTreatmentplan = (payload) => () => {
  return updateTreatmentplanById(payload).then((res) => {
    return res;
  });
};

export const setSelectedMed = (params) => {
  return {
    type: types.SET_SELECTED_MED,
    payload: params,
  };
};

export const setActiveMtusMed = (params) => {
  return {
    type: types.SET_ACTIVE_MTUS_MED,
    payload: params,
  };
};

export const setActiveMtusTp = (params) => {
  return {
    type: types.SET_ACTIVE_MTUS_TP,
    payload: params,
  };
};

export const setActiveMtusBp = (params) => {
  return {
    type: types.SET_ACTIVE_MTUS_BP,
    payload: params,
  };
};

export const setSelectedMtus = (params) => {
  return {
    type: types.SET_SELECTED_MTUS,
    payload: params,
  };
};

export const setDeletedMtus = (params) => {
  return {
    type: types.SET_DELETED_MTUS,
    payload: params,
  };
};
