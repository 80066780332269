import {
  fetchMpnList,
  fetchMpn,
  updateMpnById,
  removeMpnById,
  createMpn
} from '@service/api/mpn';

// types
import * as types from '../types';

export const getMpnList = (payload) => (dispatch) => {
  return fetchMpnList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_MPN,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeMpn = (payload) => () => {
  return removeMpnById(payload).then((res) => {
    return res;
  });
};

export const addMpn = (payload) => (dispatch) => {
  return createMpn(payload).then((res) => {
    return res;
  });
};

export const getMpn = (payload) => () => {
  return fetchMpn(payload).then((res) => {
    return res;
  });
};

export const updateMpn = (payload) => () => {
  return updateMpnById(payload).then((res) => {
    return res;
  });
};
