import {
  fetchMedicationList,
  fetchMedication,
  updateMedicationById,
  removeMedicationById,
  createMedication
} from '@service/api/medication';

// types
import * as types from '../types';

export const getMedicationList = (payload) => (dispatch) => {
  return fetchMedicationList(payload).then((res) => {
    if (res.success) {
      /*
      dispatch({
        type: types.SET_JOB_DESC,
        payload: res.data.docs,
      });
      */
    }
    return res;
  });
};

export const removeMedication = (payload) => () => {
  return removeMedicationById(payload).then((res) => {
    return res;
  });
};

export const addMedication = (payload) => () => {
  return createMedication(payload).then((res) => {
    return res;
  });
};

export const getMedication = (payload) => () => {
  return fetchMedication(payload).then((res) => {
    return res;
  });
};

export const updateMedication = (payload) => async () => {
  const res = await updateMedicationById(payload);
  return res;
};
