import {
  fetchAcu2List,
  fetchAcu2,
  updateAcu2ById,
  removeAcu2ById,
  createAcu2
} from '@service/api/acu2';

// types
import * as types from '../types';

export const getAcu2List = (payload) => async (dispatch) => {
  return fetchAcu2List(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_ACU2,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeAcu2 = (payload) => () => {
  return removeAcu2ById(payload).then((res) => {
    return res;
  });
};

export const addAcu2 = (payload) => () => {
  return createAcu2(payload).then((res) => {
    return res;
  });
};

export const getAcu2 = (payload) => () => {
  return fetchAcu2(payload).then((res) => {
    return res;
  });
};

export const updateAcu2 = (payload) => () => {
  return updateAcu2ById(payload).then((res) => {
    return res;
  });
};
