import {
  fetchPselbowList,
  fetchPselbow,
  updatePselbowById,
  removePselbowById,
  createPselbow
} from '@service/api/pselbow';

// types
import * as types from '../types';


export const getPselbowList = (payload) => (dispatch) => {
  return fetchPselbowList(payload).then((res) => {
    if (res.success) {
      if (res.data.docs.length > 0) {
        res.data.docs.map(doc => {
          if (doc.lr === 'right') {
            dispatch({
              type: types.SET_PSRELBOW,
              payload: [doc],
            });
          }
          if (doc.lr === 'left') {
            dispatch({
              type: types.SET_PSLELBOW,
              payload: [doc],
            });
          }
          return doc;
        });
      }
    }
    return res;
  });
};

export const removePselbow = (payload) => () => {
  return removePselbowById(payload).then((res) => {
    return res;
  });
};

export const addPselbow = (payload) => () => {
  return createPselbow(payload).then((res) => {
    return res;
  });
};

export const getPselbow = (payload) => () => {
  return fetchPselbow(payload).then((res) => {
    return res;
  });
};

export const updatePselbow = (payload) => () => {
  return updatePselbowById(payload).then((res) => {
    return res;
  });
};
