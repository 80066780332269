// constants
import { tlight, tplanFields, medFields, bpFields} from '@utils/common';

import {
  fetchTreatcodeList,
  fetchTreatcode,
  updateTreatcodeById,
  removeTreatcodeById,
  createTreatcode,
} from '@service/api/treatcode';

// types
import * as types from '../types';

export const getTreatcodeListTank = (payload) => async (dispatch) => {
  return fetchTreatcodeList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_TREAT_CODE,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const getTreatcodeList = (payload) => async (dispatch) => {
  const res = await fetchTreatcodeList(payload);
  return res;
};

export const removeTreatcode = (payload) => async (dispatch) => {
  const res = await removeTreatcodeById(payload);
  return res;
};

export const addTreatcode = (payload) => async (dispatch) => {
  const res = await createTreatcode(payload);
  return res;
};

export const getTreatcode = (payload) => async (dispatch) => {
  const res = await fetchTreatcode(payload);
  return res;
};

export const updateTreatcode = (payload) => async (dispatch) => {
  const res = await updateTreatcodeById(payload);
  return res;
};
