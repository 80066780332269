import { fetchNoteList, fetchNote, updateNoteById, removeNoteById, createNote } from '@service/api/note';

// types
import * as types from '../types';

export const getNoteList = payload => dispatch => {
  return fetchNoteList(payload).then(res => {
    if (res.success) {
      dispatch({
        type: types.SET_NOTE,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeNote = payload => dispatch => {
  return removeNoteById(payload).then(res => {
    if (res.success) {
      dispatch({
        type: types.SET_NOTE,
        payload: [res.data],
      });
    }
    return res;
  });
};

export const addNote = payload => dispatch => {
  return createNote(payload).then(res => {
    if (res.success) {
      dispatch({
        type: types.SET_NOTE,
        payload: [res.data],
      });
    }
    return res;
  });
};

export const getNote = payload => dispatch => {
  return fetchNote(payload).then(res => {
    if (res.success) {
      dispatch({
        type: types.SET_NOTE,
        payload: [res.data],
      });
    }
    return res;
  });
};

export const updateNote = payload => dispatch => {
  return updateNoteById(payload).then(res => {
    if (res.success) {
      dispatch({
        type: types.SET_NOTE,
        payload: [res.data],
      });
    }
    return res;
  });
};
