// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch funcimp list
 * @param {*} payload
 */
export async function fetchFuncimpList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`funcimp?${params}`);
}

/**
 * get funcimp
 * @param {*} payload
 */

export async function fetchFuncimp(payload) {
  return GET(`funcimp/${payload}`);
}

/**
 * update funcimp by id
 * @param {*} payload
 */
export async function updateFuncimpById(payload) {
  const { id, ...rest } = payload;
  return PUT(`funcimp/${id}`, rest);
}

/**
 * remove funcimp by id
 * @param {*} payload
 */
export async function removeFuncimpById(payload) {
  return DELETE(`funcimp/${payload}`);
}

/**
 * create funcimp
 * @param {*} payload
 */
export async function createFuncimp(payload) {
  return POST(`funcimp`, payload);
}
