import {
  fetchRecordReviewList,
  fetchRecordReview,
  updateRecordReviewById,
  removeRecordReviewById,
  createRecordReview,
  uploadDocument,
} from '@service/api/recordreview';

// types
import * as types from '../types';

export const uploadRecordReview = (payload) => async() => {
  const res = await uploadDocument(payload);
  return res;
};

export const getRecordReviewList = (payload) => async (dispatch) => {
  const res = await fetchRecordReviewList(payload);
  if (res.success) {
    dispatch({
      type: types.SET_RECORD_REVIEW,
      payload: res.data.docs,
    });
  }
  return res;
};

export const removeRecordReview = (payload) => () => {
  return removeRecordReviewById(payload).then((res) => {
    return res;
  });
};

export const addRecordReview = (payload) => () => {
  return createRecordReview(payload).then((res) => {
    return res;
  });
};

export const getRecordReview = (payload) => () => {
  return fetchRecordReview(payload).then((res) => {
    return res;
  });
};

export const updateRecordReview = (payload) => () => {
  return updateRecordReviewById(payload).then((res) => {
    return res;
  });
};
