// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch hipthigh list
 * @param {*} payload
 */
export async function fetchHipthighList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`hipthigh?${params}`);
}

/**
 * get hipthigh
 * @param {*} payload
 */

export async function fetchHipthigh(payload) {
  return GET(`hipthigh/${payload}`);
}

/**
 * update hipthigh by id
 * @param {*} payload
 */
export async function updateHipthighById(payload) {
  const { id, ...rest } = payload;
  return PUT(`hipthigh/${id}`, rest);
}

/**
 * remove hipthigh by id
 * @param {*} payload
 */
export async function removeHipthighById(payload) {
  return DELETE(`hipthigh/${payload}`);
}

/**
 * create hipthigh
 * @param {*} payload
 */
export async function createHipthigh(payload) {
  return POST(`hipthigh`, payload);
}
