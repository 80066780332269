import * as types from '../types';

const initialState = {
  showBodyPartModal: false,
  bodyParts: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_BODY_PART_MODAL:
      return {
        ...state,
        showBodyPartModal: action.payload,
      };
    case types.SET_BODY_PARTS:
      return {
        ...state,
        bodyParts: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
