// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch superbill list
 * @param {*} payload
 */
export async function fetchSuperbillList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`superbill?${params}`);
}

/**
 * get superbill
 * @param {*} payload
 */

export async function fetchSuperbill(payload) {
  return GET(`superbill/${payload}`);
}

/**
 * update superbill by id
 * @param {*} payload
 */
export async function updateSuperbillById(payload) {
  const { id, ...rest } = payload;
  return PUT(`superbill/${id}`, rest);
}

/**
 * remove superbill by id
 * @param {*} payload
 */
export async function removeSuperbillById(payload) {
  return DELETE(`superbill/${payload}`);
}

/**
 * create superbill
 * @param {*} payload
 */
export async function createSuperbill(payload) {
  return POST(`superbill`, payload);
}
