import { lazy } from 'react';

export const patientdemo = lazy(() => import('./patientdemo'));
export const jobdesc = lazy(() => import('./jobdesc'));
export const pscomplaints = lazy(() => import('./pscomplaints'));
export const pastmedicalhistory = lazy(() => import('./pastmedicalhistory'));
export const pastrelatedmedicalhistory = lazy(() => import('./pastrelatedmedicalhistory'));
export const specificaccident = lazy(() => import('./specificaccident'));
export const cumulativetrauma = lazy(() => import('./cumulativetrauma'));
export const funcimp = lazy(() => import('./funcimp'));
export const mpn = lazy(() => import('./mpn'));
export const othermedicalhx = lazy(() => import('./othermedicalhx'));
export const preauth = lazy(() => import('./preauth'));
