// api
import {
  authenticateProvider,
  fetchProvidersByCode,
} from '@service/api/providers';

import {
  fetchPofficeList,
} from '@service/api/poffice';

// types
import * as types from '../types';

export const authenticate = (payload) => async (dispatch) => {
  const res = await authenticateProvider(payload);
  if (res.success) {
    dispatch({
      type: types.SET_PROVIDER,
      payload: res.data,
    });
  }

  return res;
};

export const getAsstProvider = (provider) => async (dispatch) => {
  return fetchProvidersByCode(provider).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_ASST_PROVIDERS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const getProvider = (payload) => () => {
  return fetchProvidersByCode(payload).then((res) => {
    return res;
  });
};
