import {
  fetchPreauthList,
  fetchPreauth,
  updatePreauthById,
  removePreauthById,
  removePreauthByIds,
  createPreauth,
} from '@service/api/preauth';

// types
import * as types from '../types';

export const getPreauthList = (payload) => async (dispatch) => {
  const res = await fetchPreauthList(payload);
  if (res.success) {
    dispatch({
      type: types.SET_PREAUTH,
      payload: res.data.docs,
    });
  }
  return res;
};

export const removePatient = (payload) => async () => {
  const res = await removePreauthById(payload);
  return res;
};

export const removePreauth = (payload) => async () => {
  const res = await removePreauthByIds(payload)
  return res;
};

export const addPreauth = (payload) => async () => {
  const res = await createPreauth(payload);
  return res;
};

export const getPreauth = (payload) => async () => {
  const res = await fetchPreauth(payload);
  return res;
};

export const updatePreauth = (payload) => async () => {
  const res = await updatePreauthById(payload);
  return res;
};
