import {
  fetchPsyche,
  fetchQme,
  fetchInternal,
  fetchPft,
  fetchPml,
  fetchBr,
  fetchFcp,
  fetchFds,
  fetchTaa,
  fetchPdi,
  fetchAlien, // Alien and Pal is 1
  fetchPos,
  fetchAcu1,
  fetchAcu2,
  fetchAcu3,
  fetchPl,
  fetchDFR,
  fetchDFRy,
  fetchRFA,
  fetchPR2,
  fetchPR2x,
  fetchInitialReportx,
  fetchScreenerx,
  fetchWorkStatus,
  fetchBillReduction,
  // fetchDocx,
} from '@service/api/accountx';

import { downloadReport, fetchDocumentUrl } from '@utils/common';

import { fetchPofficeList } from '@service/api/poffice';

// types
import * as types from '../types';

// `${document.location.protocol}//${window.location.host}/`;
const baseUrl = process.env.REACT_APP_SPRING_URL;
const docPath = process.env.REACT_APP_DOCUMENT_PATH;
const tokenx = localStorage.getItem('tokenx');

// USING ARRAYBUFFER
// const downloadReport = (abuffer, fn) => {
//   const blob = new Blob([abuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
//   // { type: 'application/octet-stream' });
//   const fileUrl = window.URL.createObjectURL(blob);
//   const link = document.createElement('a');
//   link.href = fileUrl;
//   link.setAttribute('download', `${fn}.docx`);
//   document.body.appendChild(link);
//   link.click();
//   // return { blob, fileUrl };
// };

//* Convert resBlob to base64
export const blobToData = blob => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const generateDFRPreview = payload => async () => {
  const resv = await fetchDFRy(payload);
  const { filename } = resv.fileTransfer;
  const documentUrl = `${baseUrl}rpt/docx/${filename}`;
  return { documentUrl, filename };
};

export const generateQme = payload => async () => {
  const res = await fetchQme(payload);
  downloadReport(res, payload.fn);
  return res;
};

export const generateBrx = payload => async () => {
  const res = await fetchBr(payload);
  downloadReport(res, payload.fn);
  return res;
};

export const generatePlx = payload => async () => {
  const res = await fetchPl(payload);
  downloadReport(res, payload.fn);
  return res;
};

/////// BEGIN ANNATAR ////////
//////////////////////////////
export const generatePftx = payload => async () => {
  const res = await fetchPft(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generateTaax = payload => async () => {
  const res = await fetchTaa(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generateAlienx = payload => async () => {
  const res = await fetchAlien(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
}; // Alien and Pal is 1
export const generatePosx = payload => async () => {
  const res = await fetchPos(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generatePsychex = payload => async () => {
  const res = await fetchPsyche(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generateInternalx = payload => async () => {
  const res = await fetchInternal(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generateFcpx = payload => async () => {
  const res = await fetchFcp(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generateFdsx = payload => async () => {
  const res = await fetchFds(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generatePdix = payload => async () => {
  const res = await fetchPdi(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generatePmlx = payload => async () => {
  const res = await fetchPml(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};
export const generateBillRedux = payload => async () => {
  const res = await fetchBillReduction(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  return { downloadUrl, filename };
};
export const generateWorkStatusx = payload => async () => {
  const res = await fetchWorkStatus(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  downloadReport(downloadUrl, filename);
  return res;
};


export const generateInitialReportx = payload => async () => {
  const res = await fetchInitialReportx(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  return { downloadUrl, filename };
};
export const generateDFRx = payload => async () => {
  const res = await fetchDFR(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  return { downloadUrl, filename };
};
export const generateRFAx = payload => async () => {
  const res = await fetchRFA(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  return { downloadUrl, filename };
};
export const generatePR2x = payload => async () => {
  const res = await fetchPR2(payload);
  const { documentId, filename } = res;
  const downloadUrl = `${baseUrl}${docPath}${documentId}`;
  return { downloadUrl, filename };
};
////////////////////////////
/////// END ANNATAR ////////


export const generateRevisedPR2 = payload => async () => {
  const res = await fetchPR2x(payload);
  const { filename } = res.fileTransfer;
  const documentUrl = `${baseUrl}rpt/docx/${filename}`;
  return { documentUrl, filename };
};

export const generateAcu1x = payload => async () => {
  const res = await fetchAcu1(payload);
  const { filename } = res.fileTransfer;
  const documentUrl = `${baseUrl}rpt/docx/${filename}`;
  return { documentUrl, filename };
};
export const generateAcu2x = payload => async () => {
  const res = await fetchAcu2(payload);
  const { filename } = res.fileTransfer;
  const documentUrl = `${baseUrl}rpt/docx/${filename}`;
  return { documentUrl, filename };
};
export const generateAcu3x = payload => async () => {
  const res = await fetchAcu3(payload);
  const { filename } = res.fileTransfer;
  const documentUrl = `${baseUrl}rpt/docx/${filename}`;
  return { documentUrl, filename };
};

/*
export const generateAcu1x = (payload) => async() => {
  const res = await fetchAcu1(payload);
  downloadReport(res, payload.fn);
  return res;
};
export const generateAcu2x = (payload) => async() => {
  const res = await fetchAcu2(payload);
  downloadReport(res, payload.fn);
  return res;
};
export const generateAcu3x = (payload) => async() => {
  const res = await fetchAcu3(payload);
  downloadReport(res, payload.fn);
  return res;
};
*/

export const setProvider = params => async dispatch => {
  if (params && params !== null) {
    sessionStorage.setItem('provider', JSON.stringify(params));
    const res = await fetchPofficeList({ pageIndex: 1, pageSize: 20, pcode: params.pcode });
    dispatch({
      type: types.SET_PROVIDER_OFFICE_LIST,
      payload: res.data.docs,
    });

    dispatch({
      type: types.SET_DWR_PROVIDER,
      payload: params,
    });
    return { success: true };
  } else {
    sessionStorage.removeItem('provider');
    return { success: false };
  }
};

export const updateMinato = params => ({
  type: types.SET_DWR_ALPHA,
  payload: params,
});

export const clearMinato = params => {
  return {
    type: types.SET_MINATO,
    payload: params,
  };
};

export const clearDwr = params => {
  return {
    type: types.CLEAR_DWR,
    payload: params,
  };
};

export const generateScreenerx = payload => async () => {
  const res = await fetchScreenerx(payload);
  return res;
};

export const clearPanteraBodyParts = () => {
  return {
    type: types.CLEAR_PANTERA_BODYPARTS,
    payload: {},
  };
};
