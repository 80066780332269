import React from 'react';
 import { withRouter } from "react-router";

// components
import ErrorComponent from '@components/ErrorComponent';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.log('[getDerivedStateFromError] ', error);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { history } = this.props;
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    // message.error(errorInfo);
    history.push('/error');
    console.log('[componentDidCatch 1]]]] ', error);
    console.log('[componentDidCatch 2]]]] ', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorComponent />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
