// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch pastrelatedmedicalhistory list
 * @param {*} payload
 */
export async function fetchPastrelatedmedicalhistoryList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`pastrelatedmedicalhistory?${params}`);
}

/**
 * get pastrelatedmedicalhistory
 * @param {*} payload
 */

export async function fetchPastrelatedmedicalhistory(payload) {
  return GET(`pastrelatedmedicalhistory/${payload}`);
}

/**
 * update pastrelatedmedicalhistory by id
 * @param {*} payload
 */
export async function updatePastrelatedmedicalhistoryById(payload) {
  const { id, ...rest } = payload;
  return PUT(`pastrelatedmedicalhistory/${id}`, rest);
}

/**
 * remove pastrelatedmedicalhistory by id
 * @param {*} payload
 */
export async function removePastrelatedmedicalhistoryById(payload) {
  return DELETE(`pastrelatedmedicalhistory/${payload}`);
}

/**
 * create pastrelatedmedicalhistory
 * @param {*} payload
 */
export async function createPastrelatedmedicalhistory(payload) {
  return POST(`pastrelatedmedicalhistory`, payload);
}
