import {
  fetchVserList,
  fetchVser,
  updateVserById,
  removeVserById,
  removeVserByIds,
  createVser
} from '@service/api/vsers';

// types
import * as types from '../types';

export const getVserList = (payload) => async (dispatch) => {
  return fetchVserList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_VSER_LIST,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeVser = (payload) => () => {
  return removeVserById(payload).then((res) => {
    return res;
  });
};

export const removeVsers = (payload) => async () => {
  const res = await removeVserByIds(payload)
  return res;
};

export const addVser = (payload) => () => {
  return createVser(payload).then((res) => {
    return res;
  });
};

export const getVser = (payload) => () => {
  return fetchVser(payload).then((res) => {
    return res;
  });
};

export const updateVser = (payload) => () => {
  return updateVserById(payload).then((res) => {
    return res;
  });
};
