import {
  fetchPelvisList,
  fetchPelvis,
  updatePelvisById,
  removePelvisById,
  createPelvis
} from '@service/api/pelvis';

// types
import * as types from '../types';

export const getPelvisList = (payload) => async (dispatch) => {
  return fetchPelvisList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_MEASUREMENT_U_EXTREMITIES,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePelvis = (payload) => () => {
  return removePelvisById(payload).then((res) => {
    return res;
  });
};

export const addPelvis = (payload) => () => {
  return createPelvis(payload).then((res) => {
    return res;
  });
};

export const getPelvis = (payload) => () => {
  return fetchPelvis(payload).then((res) => {
    return res;
  });
};

export const updatePelvis = (payload) => () => {
  return updatePelvisById(payload).then((res) => {
    return res;
  });
};
