import { POST, GET_ABUF } from '../request';

/**
 * login
 * @param {*} payload
 */
export async function login(payload) {
  return POST('account/login', payload);
}

/**
 * logout
 * @param {*} payload
 */
export async function logout(payload) {
  return POST('account/logout', payload);
}

/**
 * validate-token
 * @param {*} payload
 */
export async function validateToken(payload) {
  return POST('account/validate-token', payload);
}

/**
 * verify
 * @param {*} payload
 */
export async function verifyAdmin(payload) {
  return POST('account/verify', payload);
}

export async function logdeeznuts(params) {
  const payload = {
    username: params.email,
    platform: process.env.REACT_APP_PLATFORM_NAME,
    action_done: `[${process.env.REACT_APP_NODE_ENV}] ${params.action}`,
  };
  return POST('rpt/logdeeznuts', payload);
};

export async function superproxy(payload) {
  return GET_ABUF(`rpt/pxy?url=${payload}`);
};
