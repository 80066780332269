import React from 'react';

import { Modal } from 'antd';

import { SettingFilled } from '@ant-design/icons';

const ProgressModal = ({ visible, onCancel, label }) => {
  return (
    <Modal
      closable={false}
      centered
      visible={visible}
      onCancel={onCancel}
      width={300}
      height={200}
      header={null}
      footer={null}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <SettingFilled spin style={{ marginRight: 10 }}/>
        {label}
      </div>
    </Modal>
  );
}

export default ProgressModal;
