import {
  fetchFootList,
  fetchFoot,
  updateFootById,
  removeFootById,
  createFoot
} from '@service/api/foot';

// types
import * as types from '../types';

export const getFootList = (payload) => async (dispatch) => {
  return fetchFootList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_FOOT,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeFoot = (payload) => () => {
  return removeFootById(payload).then((res) => {
    return res;
  });
};

export const addFoot = (payload) => () => {
  return createFoot(payload).then((res) => {
    return res;
  });
};

export const getFoot = (payload) => () => {
  return fetchFoot(payload).then((res) => {
    return res;
  });
};

export const updateFoot = (payload) => () => {
  return updateFootById(payload).then((res) => {
    return res;
  });
};
