import { lazy } from 'react';

export const vsers = lazy(() => import('./vsers'));
export const patientsadmin = lazy(() => import ('./patients_admin'));
export const vserlocation = lazy(() => import('./vserlocation'));
export const templates = lazy(() => import('./templates'));
export const diagnosis = lazy(() => import('./diagnosis'));
export const medication = lazy(() => import('./medication'));
export const poffice = lazy(() => import('./poffice'));
export const procedurecode = lazy(() => import('./procedurecodes'));
export const treatmentplan = lazy(() => import('./treatmentplan'));
export const treatcode = lazy(() => import('./treatcode'));
