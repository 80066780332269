// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch psshoulder list
 * @param {*} payload
 */
export async function fetchPsshoulderList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`psshoulder?${params}`);
}

/**
 * get psshoulder
 * @param {*} payload
 */

export async function fetchPsshoulder(payload) {
  return GET(`psshoulder/${payload}`);
}

/**
 * update psshoulder by id
 * @param {*} payload
 */
export async function updatePsshoulderById(payload) {
  const { id, ...rest } = payload;
  return PUT(`psshoulder/${id}`, rest);
}

/**
 * remove psshoulder by id
 * @param {*} payload
 */
export async function removePsshoulderById(payload) {
  return DELETE(`psshoulder/${payload}`);
}

/**
 * create psshoulder
 * @param {*} payload
 */
export async function createPsshoulder(payload) {
  return POST(`psshoulder`, payload);
}
