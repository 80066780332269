import {
  fetchMilList,
  fetchMil,
  updateMilById,
  removeMilById,
  createMil
} from '@service/api/mil';

export const getMilList = (payload) => () => {
  return fetchMilList(payload).then((res) => {
    return res;
  });
};

export const removeMil = (payload) => () => {
  return removeMilById(payload).then((res) => {
    return res;
  });
};

export const addMil = (payload) => () => {
  return createMil(payload).then((res) => {
    return res;
  });
};

export const getMil = (payload) => () => {
  return fetchMil(payload).then((res) => {
    return res;
  });
};

export const updateMil = (payload) => () => {
  return updateMilById(payload).then((res) => {
    return res;
  });
};
