import {
  fetchDateofinjuriesList,
  fetchDateofinjuries,
  updateDateofinjuriesById,
  removeDateofinjuriesById,
  createDateofinjuries
} from '@service/api/dateofinjuries';

// types
import * as types from '../types';

export const getDinjList = (payload) => async (dispatch) => {
  const res = await fetchDateofinjuriesList(payload);
  if (res.success) {
    dispatch({
      type: types.SET_DINJITEMS,
      payload: res.data.docs,
    });
  }
  return res;
};

export const removeDinj = (payload) => () => {
  return removeDateofinjuriesById(payload).then((res) => {
    return res;
  });
};

export const addDinj = (payload) => () => {
  return createDateofinjuries(payload).then((res) => {
    return res;
  });
};

export const getDinj = (payload) => () => {
  return fetchDateofinjuries(payload).then((res) => {
    return res;
  });
};

export const updateDinj = (payload) => () => {
  return updateDateofinjuriesById(payload).then((res) => {
    return res;
  });
};
