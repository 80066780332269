// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * execute FollowUp
 * @param {*} payload
 */
export async function executeFollowUpx(payload) {
  return POST(`patientdoe/executeFollowUp`, payload);
}

/**
 * fetch patientdoe list
 * @param {*} payload
 */
export async function fetchPatientDoeList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`patientdoe?${params}`);
}

/**
 * get patientdoe
 * @param {*} payload
 */

export async function fetchPatientDoe(payload) {
  return GET(`patientdoe/${payload}`);
}

/**
 * update patientdoe by id
 * @param {*} payload
 */
export async function updatePatientDoeById(payload) {
  const { id, ...rest } = payload;
  return PUT(`patientdoe/${id}`, rest);
}

/**
 * remove patientdoe by id
 * @param {*} payload
 */
export async function removePatientDoeById(payload) {
  return DELETE(`patientdoe/${payload}`);
}

/**
 * create patientdoe
 * @param {*} payload
 */
export async function createPatientDoe(payload) {
  return POST(`patientdoe`, payload);
}
