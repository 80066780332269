import * as types from '../types';

const initialState = {
  hasegawa: {
    prov: '',
  },
  treatcodes: undefined,
  sepultura: undefined,
  pantera: {
    qme: {},
    initial: {},
    lockd: false,
    activePS: 0,
    isInitialExam: false,
    isSavePs: false,
    treatmentPlan: undefined,
    specificMeds: '',
    isSaveTreatmentPlan: false,
    alternateFaxNumber: '',
    autoFaxToInsurance: false,
    br: '',
    gl: '',
    analgesic: '',
    nsaid: '',
    nsaidICE: '',
    musclerelaxant: '',
    musclerelaxantICE: '',
    anticonvulsant: '',
    anticonvulsantICE: '',
    antiulcerative: '',
    antiulcerativeICE: '',
    sedative: '',
    sedativeICE: '',
    compoundcream: '',
    compoundcreamICE: '',
    chartNumber: '',
    pcode: '',
    lname: '',
    fname: '',
    mname: '',
    forDownload: false,
    fromPatlist: false,
    gender: '',
    dob: '',
    doe: '',
    digital: false,
    doeFromListTap: '',
    descspecacc: '',
    diagcspine: '',
    diagcspineICD: '',
    diaglspine: '',
    diaglspineICD: '',
    diagtspine: '',
    diagtspineICD: '',
    diagmisc: '',
    diagmiscICD: '',
    diagshoulder: '',
    diagshoulderICD: '',
    diagelbow: '',
    diagelbowICD: '',
    diagwrist: '',
    diagwristICD: '',
    diaghand: '',
    diaghandICD: '',

    diagfinger: '',
    diagfingerICD: '',

    diagthumb: '',
    diagthumbICD: '',

    diaghip: '',
    diaghipICD: '',
    diagknee: '',
    diagkneeICD: '',
    diagankfoot: '',
    diagankfootICD: '',
    hoaid: '',
    hoa: '',
    patientid: '',
    patientDemo: '',
    patientdiag: '',
    p4tientid: '',
    patientinfo: '',
    posNod: '',
    screener: '',
    saveHoaMode: '',
    sendPreauth: '',
    doe2: '',
    doe2id: '',

    activeFieldId: '',
    bpActiveId: '',
    pPosNegActiveId: '',
    pshowoftenActiveId: '',
    psTypeActiveId: '',

    RPT_APP_VERSION: 'v2.0b',
    daysBetween: '',
    isAcute: false,
    isChronic: false,
    isSubAcute: false,
    hasSurgery: false,
    hasOthers: false,
    hasRotatorCuffProblem: false,
    hasShoulderPain: false,
    imgWidth: '',
    level: '100',
    loadNumberParent: '',
    loadNumberField: '',
    dfr: '',
    multiPb: '',
    emgmultiPb: '',
    supportmultiPb: '',
    newPatient: 'false',
    newDenial: false,
    addStarter: '',
    preAuth: '',
    TRT: '',
    urTimeSpent1: '',
    urTimeSpent2: '',
    username: '',
    updateNameDob: '',
    dropinInstance: '',
    braintree: {
      clientToken: '',
    },
    token: '',
    toastr: {
      treathot: 'Hot Pack',
      treatcold: 'Cold Pack',
      treattens: 'TENS Unit',
      treatsling: 'Sling Support',
      treatsplint: 'Splint Support',
      treatbraces: 'Braces',
      treatcane: 'Cane',
      treatcrutches: 'Crutches',

      treathek: 'Home Exercise Kit',
      treatifunit: 'IF Unit',

      treatchronic: 'Chronic Pain Management',
      treatfrp: 'Functional Restoration Program',
      treatfre: 'Functional Restoration Evaluation',
      treatmultidisc: 'Multidisciplinary',
      treatbiofeed: 'Biofeed',
      treatctscan: 'CT Scan',
      treatfce: 'Functional Capacity Evaluation',
      treatmri: 'MRI',
      treatemg: 'EMG/NCV',

      // treatacu: 'ACU Trial',
      // treatphymed: 'PT Trial',
      // treatchiro: 'Chiro Trial',

      treatacu: 'Acupuncture Trial',
      treatphymed: 'Physical Therapy Trial',
      treatchiro: 'Chiropractic Trial',

      treatfim: 'Functional Improvement Measurement',
      treatinternal: 'Internal Medicine Evaluation and Treatment',
      treatpsyche: 'Psyche Evaluation and Treatment',
      treatortho: 'Orthopedic Evaluation and Treatment',
      treatneuro: 'Neurologic Evaluation and Treatment',
      treatxray: 'XRAY',
      treatiniteval: 'Primary Treating Physician Evaluation',
      treatfmc: 'Future Medical Care',
      treatpharma: 'Pharmacological Management Evaluation and Treatment',
      treatxpt: 'Extended Physical Therapy',

      // treatot: 'OT Trial',
      treatot: 'Occupational Therapy Trial',

      treatxot: 'Extended Occupational Therapy',
      treatxacu: 'Extended E-Acupuncture regimen',
      treatxchiro: 'Continued Chiropractic regimen',
      treatsupp: 'Support Treatment',
      treatmisc: 'Miscellaneous Treatment',
      treatopthal: 'Ophthalmological Evaluation and Treatment',
      treatcpharma: 'Pharmacological Management Re-evaluation',
      treatipm: 'Interventional Pain Management',
      treatinj: 'Injection',
      treatsurg: 'Surgery',
      treatpodia: 'Podiatry Evaluation and Treatment',
      treatent: 'ENT Evaluation and Treatment',
      treatexer: 'Exercise',
      treatrheuma: 'Rheumatology Evaluation and Treatment',
      treatgensurg: 'General Surgeon',

      treatpulmo: 'Pulmonology Evaluation and Treatment',
      treatderma: 'Dermatology Evaluation and Treatment',
      treatcardio: 'Cardiology Evaluation and Treatment',

      analgesic: 'Analgesic',
      acetaminophen: 'Acetaminophen',
      antidepressant: 'Anti-Depressants',
      antiepileptic: 'Anti-Epileptic Agents',
      antiinfla: 'Anti-Inflammatory',
      anticonvulsant: 'Anti-Convulsant',
      antiulcerative: 'Anti-Ulcerative',
      biphosponates: 'BIPHOSPONATES',
      calciumblockers: 'CALCIUM CHANNEL BLOCKERS',
      capsaicin: 'CAPSAICIN',
      steroids: 'GLUCOCORTICOSTEROIDS',
      intra1: 'INTRAARTICULAR INJECTIONS',
      intra2: 'INTRAARTICULAR HYALURONATE',
      lidocaine: 'LIDOCAINE PATCHES',
      nsaid: 'NSAID',
      musclerelaxant: 'Muscle Relaxant',
      opioids: 'OPIOIDS (Oral, Transdermal, and Parenteral (Includes Tramadol))',

      sedative: 'SEDATIVE',
      compoundcream: 'COMPOUND CREAM',
      // treatinj: 'INJECTION',
      injection1: 'COLLAGENASE INJECTION',
      injection2: 'ANESTHETIC INTRAARTICULAR INJECTIONS',
      injection3: 'PLATELET RICH PLASMA INJECTIONS',
      injection4: 'AUTOLOGOUS BLOOD INJECTIONS',
      tetanus: 'TETANUS IMMUNIZATION',
      topical: 'TOPICAL MEDICATIONS',
      treatothers: 'OTHER TREATMENT PLAN',
      treatothermeds: 'OTHER MEDICATIONS',
    },
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TREAT_CODE:
      return { ...state, treatcodes: action.payload };
    case types.SET_SEPULTURA_RPT_CONTENT:
      return { ...state, sepultura: { ...state.sepultura, ...action.payload } };
    case types.SET_PANTERA_RPT_CONTENT:
      return { ...state, pantera: { ...state.pantera, ...action.payload } };
    case types.CLEAR_SEPULTURA_RPT_CONTENT:
      return { ...state, sepultura: undefined };
    case types.CLEAR_PANTERA_BODYPARTS:
      return { ...state, pantera: { ...state.pantera, bodyparts: {} } };
    default:
      return state;
  }
};

export default reducer;
