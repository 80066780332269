// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch mil list
 * @param {*} payload
 */
export async function fetchMilList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`mil?${params}`);
}

/**
 * get mil
 * @param {*} payload
 */

export async function fetchMil(payload) {
  return GET(`mil/${payload}`);
}

/**
 * update mil by id
 * @param {*} payload
 */
export async function updateMilById(payload) {
  const { id, ...rest } = payload;
  return PUT(`mil/${id}`, rest);
}

/**
 * remove mil by id
 * @param {*} payload
 */
export async function removeMilById(payload) {
  return DELETE(`mil/${payload}`);
}

/**
 * create mil
 * @param {*} payload
 */
export async function createMil(payload) {
  return POST(`mil`, payload);
}
