import {
  fetchShouldersList,
  fetchShoulders,
  updateShouldersById,
  removeShouldersById,
  createShoulders
} from '@service/api/shoulders';

// types
import * as types from '../types';

export const getShouldersList = (payload) => async (dispatch) => {
  return fetchShouldersList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_SHOULDERS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeShoulders = (payload) => () => {
  return removeShouldersById(payload).then((res) => {
    return res;
  });
};

export const addShoulders = (payload) => () => {
  return createShoulders(payload).then((res) => {
    return res;
  });
};

export const getShoulders = (payload) => () => {
  return fetchShoulders(payload).then((res) => {
    return res;
  });
};

export const updateShoulders = (payload) => () => {
  return updateShouldersById(payload).then((res) => {
    return res;
  });
};
