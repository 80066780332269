import { DELETE, GET, POST, POST_X , GET_ABUF, GET_BLOB } from '../requestx';
/*

export async function login(payload) {
  return POST('account/login', payload);
}


export async function logout(payload) {
  return DELETE('account/logout', payload);
}


export async function validateToken(payload) {
  return POST('account/validate-token', payload);
}
*/

/**
 * loginx
 * @param {*} payload
 */
export async function loginx(payload) {
  return POST('auth/signin', payload);
}

/**
 * signupx: name, username, email, password
 * @param {*} payload
 */
export async function signupx(payload) {
  return POST('auth/signup', payload);
}

export async function fetchXsers() {
  return GET('auth/');
}

export async function deleteXser(payload) {
  return DELETE(`auth/${payload}`);
}

export async function fetchScreenerx(payload) {
  return GET('reportgen/screener', payload);
}

export async function fetchQme(payload) {
  return POST_X('reportgen/qme', payload);
}

export async function fetchDFRy(payload) {
  return POST('reportgen/dfry', payload);
}

//////// BEGIN ANNATAR ///////////
export async function fetchPft(payload) {
  // return POST_X('reportgen/pft', payload);
  return POST('reportgen/pft', payload);
}
export async function fetchTaa(payload) {
  // return POST_X('reportgen/taa', payload);
  return POST('reportgen/taa', payload);  
}
export async function fetchAlien(payload) {
  // Alien and Pal is 1
  // return POST_X('reportgen/alien', payload);
  return POST('reportgen/alien', payload);
}
export async function fetchPos(payload) {
  // return POST_X('reportgen/pos', payload);
  return POST('reportgen/pos', payload);  
}
export async function fetchPsyche(payload) {
  // return POST_X('reportgen/psyche', payload);
  return POST('reportgen/psyche', payload);  
}
export async function fetchInternal(payload) {
  // return POST_X('reportgen/internal', payload);
  return POST('reportgen/intrnl', payload);  
}
export async function fetchFcp(payload) {
  // return POST_X('reportgen/fcp', payload);
  return POST('reportgen/fcp', payload);  
}
export async function fetchFds(payload) {
  // return POST_X('reportgen/fds', payload);
  return POST('reportgen/fds', payload);  
}
export async function fetchPdi(payload) {
  // return POST_X('reportgen/pdi', payload);
  return POST('reportgen/pdi', payload);  
}
export async function fetchPml(payload) {
  // return POST_X('reportgen/pml', payload);
  return POST('reportgen/pml', payload);
}
export async function fetchBillReduction(payload) {
  return POST('reportgen/billreduc', payload);
}
export async function fetchWorkStatus(payload) {
  // return POST_X('reportgen/wstatus', payload);
  return POST('reportgen/wstatus', payload);  
}



export async function fetchInitialReportx(payload) {
  // return POST_X('reportgen/initial', payload);
  return POST('reportgen/initial', payload);  
}
export async function fetchDFR(payload) {
  return POST('reportgen/dfr', payload);
}
export async function fetchRFA(payload) {
  // return POST_X('reportgen/rfa', payload);
  return POST('reportgen/rfa', payload);  
}
export async function fetchPR2(payload) {
  return POST('reportgen/pr2', payload);
}
/////// END ANNATAR ////////


///////
export async function fetchPR2x(payload) {
  return POST('reportgen/pr2x', payload);
}

export async function fetchAcu1(payload) {
  return POST('reportgen/acu1', payload);
}

export async function fetchAcu2(payload) {
  return POST('reportgen/acu2', payload);
}

export async function fetchAcu3(payload) {
  return POST('reportgen/acu3', payload);
}
/*
export async function fetchAcu1(payload) {
  return POST_X('reportgen/acu1', payload);
}

export async function fetchAcu2(payload) {
  return POST_X('reportgen/acu2', payload);
}

export async function fetchAcu3(payload) {
  return POST_X('reportgen/acu3', payload);
}
*/

/*
export async function fetchBillReduction(payload) {
  return POST_X('reportgen/billreduc', payload);
}
*/

export async function fetchBr(payload) {
  return POST_X('reportgen/br', payload);
}

export async function fetchPl(payload) {
  return POST_X('reportgen/pl', payload);
}

export async function fetchDocx(payload) {
  return GET_BLOB(`reportgen/docx/${payload}`);
}

export async function fetchDocux(payload) {
  return GET_ABUF(`reportgen/docx/${payload}`);
}