// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch psuback list
 * @param {*} payload
 */
export async function fetchPsubackList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`psuback?${params}`);
}

/**
 * get psuback
 * @param {*} payload
 */

export async function fetchPsuback(payload) {
  return GET(`psuback/${payload}`);
}

/**
 * update psuback by id
 * @param {*} payload
 */
export async function updatePsubackById(payload) {
  const { id, ...rest } = payload;
  return PUT(`psuback/${id}`, rest);
}

/**
 * remove psuback by id
 * @param {*} payload
 */
export async function removePsubackById(payload) {
  return DELETE(`psuback/${payload}`);
}

/**
 * create psuback
 * @param {*} payload
 */
export async function createPsuback(payload) {
  return POST(`psuback`, payload);
}
