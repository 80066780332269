// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch acupuncture2 list
 * @param {*} payload
 */
export async function fetchAcu2List(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`acupuncture2?${params}`);
}

/**
 * get acupuncture2
 * @param {*} payload
 */

export async function fetchAcu2(payload) {
  return GET(`acupuncture2/${payload}`);
}

/**
 * update acupuncture2 by id
 * @param {*} payload
 */
export async function updateAcu2ById(payload) {
  const { id, ...rest } = payload;
  return PUT(`acupuncture2/${id}`, rest);
}

/**
 * remove acupuncture2 by id
 * @param {*} payload
 */
export async function removeAcu2ById(payload) {
  return DELETE(`acupuncture2/${payload}`);
}

/**
 * create acupuncture2
 * @param {*} payload
 */
export async function createAcu2(payload) {
  return POST(`acupuncture2`, payload);
}
