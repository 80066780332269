import {
  fetchContent,
} from '@service/api/templates';

// types
import * as types from '../types';


export const petroleum = (c, r, reportContent) => {
  return c.replace(r, (matched => {
    return reportContent[matched];
  }));
};

export const updatePanteraTemplate = (params) => {
  return {
    type: types.SET_PANTERA_RPT_CONTENT,
    payload: params
  }
};

export const updateSepulturaTemplate = (params) => {
  return {
    type: types.SET_SEPULTURA_RPT_CONTENT,
    payload: params
  }
};

export const getTemplateByPcode = (payload) => async (dispatch) => {
  return fetchContent(payload).then(res => {
    const { success, data } = res;
    if (success) {
      const initial = data.find(v => v.reportType === 'initial');
      dispatch(updatePanteraTemplate({ initial }));

      const qme = data.find(v => v.reportType === 'qme');      
      dispatch(updatePanteraTemplate({ qme }));      
    }
  })
};
