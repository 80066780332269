// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch hands list
 * @param {*} payload
 */
export async function fetchHandsList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`hands?${params}`);
}

/**
 * get hands
 * @param {*} payload
 */

export async function fetchHands(payload) {
  return GET(`hands/${payload}`);
}

/**
 * update hands by id
 * @param {*} payload
 */
export async function updateHandsById(payload) {
  const { id, ...rest } = payload;
  return PUT(`hands/${id}`, rest);
}

/**
 * remove hands by id
 * @param {*} payload
 */
export async function removeHandsById(payload) {
  return DELETE(`hands/${payload}`);
}

/**
 * create hands
 * @param {*} payload
 */
export async function createHands(payload) {
  return POST(`hands`, payload);
}
