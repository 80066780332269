import {
  fetchPsneckList,
  fetchPsneck,
  updatePsneckById,
  removePsneckById,
  createPsneck
} from '@service/api/psneck';

// types
import * as types from '../types';

export const getPsneckList = (payload) => async (dispatch) => {
  return fetchPsneckList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PSNECK,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePsneck = (payload) => () => {
  return removePsneckById(payload).then((res) => {
    return res;
  });
};

export const addPsneck = (payload) => () => {
  return createPsneck(payload).then((res) => {
    return res;
  });
};

export const getPsneck = (payload) => () => {
  return fetchPsneck(payload).then((res) => {
    return res;
  });
};

export const updatePsneck = (payload) => () => {
  return updatePsneckById(payload).then((res) => {
    return res;
  });
};
