import {
  fetchNeurologicalexamueList,
  fetchNeurologicalexamue,
  updateNeurologicalexamueById,
  removeNeurologicalexamueById,
  createNeurologicalexamue
} from '@service/api/neurologicalexamue';

// types
import * as types from '../types';

export const getNeurologicalexamueList = (payload) => async (dispatch) => {
  return fetchNeurologicalexamueList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_NEURO_EXAM_UPPER_EX,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeNeurologicalexamue= (payload) => () => {
  return removeNeurologicalexamueById(payload).then((res) => {
    return res;
  });
};

export const addNeurologicalexamue= (payload) => () => {
  return createNeurologicalexamue(payload).then((res) => {
    return res;
  });
};

export const getNeurologicalexamue= (payload) => () => {
  return fetchNeurologicalexamue(payload).then((res) => {
    return res;
  });
};

export const updateNeurologicalexamue= (payload) => () => {
  return updateNeurologicalexamueById(payload).then((res) => {
    return res;
  });
};
