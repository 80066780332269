// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch medication list
 * @param {*} payload
 */
export async function fetchMedicationList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`medication?${params}`);
}

/**
 * get medication
 * @param {*} payload
 */

export async function fetchMedication(payload) {
  return GET(`medication/${payload}`);
}

/**
 * update medication by id
 * @param {*} payload
 */
export async function updateMedicationById(payload) {
  const { id, ...rest } = payload;
  return PUT(`medication/${id}`, rest);
}

/**
 * remove medication by id
 * @param {*} payload
 */
export async function removeMedicationById(payload) {
  return DELETE(`medication/${payload}`);
}

/**
 * remove medication by id
 * @param {*} payload
 */
export async function removeMedicationByPatientId(payload) {
  const params = methods.convertQueryString(payload);
  return DELETE(`medication?${params}`);
}

/**
 * create medication
 * @param {*} payload
 */
export async function createMedication(payload) {
  return POST(`medication`, payload);
}
