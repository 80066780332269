// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch ankle list
 * @param {*} payload
 */
export async function fetchAnkleList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`ankle?${params}`);
}

/**
 * get ankle
 * @param {*} payload
 */

export async function fetchAnkle(payload) {
  return GET(`ankle/${payload}`);
}

/**
 * update ankle by id
 * @param {*} payload
 */
export async function updateAnkleById(payload) {
  const { id, ...rest } = payload;
  return PUT(`ankle/${id}`, rest);
}

/**
 * remove ankle by id
 * @param {*} payload
 */
export async function removeAnkleById(payload) {
  return DELETE(`ankle/${payload}`);
}

/**
 * create ankle
 * @param {*} payload
 */
export async function createAnkle(payload) {
  return POST(`ankle`, payload);
}
