// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch psyche list
 * @param {*} payload
 */
export async function fetchPsycheList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`psyche?${params}`);
}

/**
 * get psyche
 * @param {*} payload
 */

export async function fetchPsyche(payload) {
  return GET(`psyche/${payload}`);
}

/**
 * update psyche by id
 * @param {*} payload
 */
export async function updatePsycheById(payload) {
  const { id, ...rest } = payload;
  return PUT(`psyche/${id}`, rest);
}

/**
 * remove psyche by id
 * @param {*} payload
 */
export async function removePsycheById(payload) {
  return DELETE(`psyche/${payload}`);
}

/**
 * create psyche
 * @param {*} payload
 */
export async function createPsyche(payload) {
  return POST(`psyche`, payload);
}
