import {
  fetchAddressBookList,
  fetchAddressBook,
  updateAddressBookById,
  removeAddressBookById,
  createAddressBook,
  fetchPatientDoeList,
} from '@service/api/addressbook';

export const getAddressBookList = (payload) => () => {
  return fetchAddressBookList(payload).then((res) => {
    return res;
  });
};

export const removeAddressBook = (payload) => () => {
  return removeAddressBookById(payload).then((res) => {
    return res;
  });
};

export const addAddressBook = (payload) => () => {
  return createAddressBook(payload).then((res) => {
    return res;
  });
};

export const getAddressBook = (payload) => () => {
  return fetchAddressBook(payload).then((res) => {
    return res;
  });
};

export const updateAddressBook = (payload) => () => {
  return updateAddressBookById(payload).then((res) => {
    return res;
  });
};


export const getPatientDoeList = (payload) => () => {
  return fetchPatientDoeList(payload).then((res) => {
    return res;
  });
};
