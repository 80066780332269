// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch mpn list
 * @param {*} payload
 */
export async function fetchMpnList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`mpn?${params}`);
}

/**
 * get mpn
 * @param {*} payload
 */

export async function fetchMpn(payload) {
  return GET(`mpn/${payload}`);
}

/**
 * update mpn by id
 * @param {*} payload
 */
export async function updateMpnById(payload) {
  const { id, ...rest } = payload;
  return PUT(`mpn/${id}`, rest);
}

/**
 * remove mpn by id
 * @param {*} payload
 */
export async function removeMpnById(payload) {
  return DELETE(`mpn/${payload}`);
}

/**
 * create mpn
 * @param {*} payload
 */
export async function createMpn(payload) {
  return POST(`mpn`, payload);
}
