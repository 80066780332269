// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * get rpt templates
 * @param {*} payload
 */

export async function fetchContent(payload) {
  return GET(`rptcontent/pcode/${payload}`);
}



/**
 * fetch rptcontent list
 * @param {*} payload
 */
export async function fetchRptContentList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`rptcontent?${params}`);
}

/**
 * get rptcontent
 * @param {*} payload
 */

export async function fetchRptContent(payload) {
  return GET(`rptcontent/${payload}`);
}

/**
 * update rptcontent by id
 * @param {*} payload
 */
export async function updateRptContentById(payload) {
  const { id, ...rest } = payload;
  return PUT(`rptcontent/${id}`, rest);
}

/**
 * remove rptcontent by id
 * @param {*} payload
 */
export async function removeRptContentById(payload) {
  return DELETE(`rptcontent/${payload}`);
}

/**
 * create rptcontent
 * @param {*} payload
 */
export async function createRptContent(payload) {
  return POST(`rptcontent`, payload);
}

