// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch knees list
 * @param {*} payload
 */
export async function fetchKneesList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`knees?${params}`);
}

/**
 * get knees
 * @param {*} payload
 */

export async function fetchKnees(payload) {
  return GET(`knees/${payload}`);
}

/**
 * update knees by id
 * @param {*} payload
 */
export async function updateKneesById(payload) {
  const { id, ...rest } = payload;
  return PUT(`knees/${id}`, rest);
}

/**
 * remove knees by id
 * @param {*} payload
 */
export async function removeKneesById(payload) {
  return DELETE(`knees/${payload}`);
}

/**
 * create knees
 * @param {*} payload
 */
export async function createKnees(payload) {
  return POST(`knees`, payload);
}
