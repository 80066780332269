// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch thoracicspine list
 * @param {*} payload
 */
export async function fetchThoracicspineList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`thoracicspine?${params}`);
}

/**
 * get thoracicspine
 * @param {*} payload
 */

export async function fetchThoracicspine(payload) {
  return GET(`thoracicspine/${payload}`);
}

/**
 * update thoracicspine by id
 * @param {*} payload
 */
export async function updateThoracicspineById(payload) {
  const { id, ...rest } = payload;
  return PUT(`thoracicspine/${id}`, rest);
}

/**
 * remove thoracicspine by id
 * @param {*} payload
 */
export async function removeThoracicspineById(payload) {
  return DELETE(`thoracicspine/${payload}`);
}

/**
 * create thoracicspine
 * @param {*} payload
 */
export async function createThoracicspine(payload) {
  return POST(`thoracicspine`, payload);
}
