// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch patientdiagother list
 * @param {*} payload
 */
export async function fetchPatientDiagOtherList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`patientdiagother?${params}`);
}

/**
 * get patientdiagother
 * @param {*} payload
 */

export async function fetchPatientDiagOther(payload) {
  return GET(`patientdiagother/${payload}`);
}

/**
 * update patientdiagother by id
 * @param {*} payload
 */
export async function updatePatientDiagOtherById(payload) {
  const { id, ...rest } = payload;
  return PUT(`patientdiagother/${id}`, rest);
}

/**
 * remove patientdiagother by id
 * @param {*} payload
 */
export async function removePatientDiagOtherById(payload) {
  return DELETE(`patientdiagother/${payload}`);
}

/**
 * create patientdiagother
 * @param {*} payload
 */
export async function createPatientDiagOther(payload) {
  return POST(`patientdiagother`, payload);
}
