import * as types from '../types';

const initialState = {
  patient: undefined,
  patientdoe: undefined,
  caseref: undefined,
  screener: undefined,
  preauth: undefined,
  patientdemo: undefined,
  newPatient: false,
  examId: '',
  patientId: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PATIENTDOE:
      return {
        ...state,
        patientdoe: action.payload,
      };
    case types.SET_EXAM_ID:
      return {
        ...state,
        examId: action.payload,
      };
    case types.SET_PATIENT_ID:
      return {
        ...state,
        patientId: action.payload,
      };
    case types.SET_CASEREF:
      return {
        ...state,
        caseref: action.payload,
      };
    case types.SET_SCREENER:
      return {
        ...state,
        screener: action.payload,
      };
    case types.SET_PREAUTH:
      return {
        ...state,
        preauth: action.payload[0] ? action.payload[0] : undefined,
      };
    case types.SET_PATIENTDEMO:
      return {
        ...state,
        patientdemo: action.payload,
      };
    case types.SET_PATIENT:
      return {
        ...state,
        patient: action.payload,
      };
    case types.SET_NEW_PATIENT_BY_FIELD:
      return {
        ...state,
        patient: { ...state.patient, ...action.payload },
      };
    case types.SET_NEW_PATIENT:
      return {
        ...state,
        newPatient: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
