// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch acupuncture1 list
 * @param {*} payload
 */
export async function fetchAcu1List(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`acupuncture1?${params}`);
}

/**
 * get acupuncture1
 * @param {*} payload
 */

export async function fetchAcu1(payload) {
  return GET(`acupuncture1/${payload}`);
}

/**
 * update acupuncture1 by id
 * @param {*} payload
 */
export async function updateAcu1ById(payload) {
  const { id, ...rest } = payload;
  return PUT(`acupuncture1/${id}`, rest);
}

/**
 * remove acupuncture1 by id
 * @param {*} payload
 */
export async function removeAcu1ById(payload) {
  return DELETE(`acupuncture1/${payload}`);
}

/**
 * create acupuncture1
 * @param {*} payload
 */
export async function createAcu1(payload) {
  return POST(`acupuncture1`, payload);
}
