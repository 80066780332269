// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch cervicalspine list
 * @param {*} payload
 */
export async function fetchCervicalspineList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`cervicalspine?${params}`);
}

/**
 * get cervicalspine
 * @param {*} payload
 */

export async function fetchCervicalspine(payload) {
  return GET(`cervicalspine/${payload}`);
}

/**
 * update cervicalspine by id
 * @param {*} payload
 */
export async function updateCervicalspineById(payload) {
  const { id, ...rest } = payload;
  return PUT(`cervicalspine/${id}`, rest);
}

/**
 * remove cervicalspine by id
 * @param {*} payload
 */
export async function removeCervicalspineById(payload) {
  return DELETE(`cervicalspine/${payload}`);
}

/**
 * create cervicalspine
 * @param {*} payload
 */
export async function createCervicalspine(payload) {
  return POST(`cervicalspine`, payload);
}
