import {
  fetchBillReducItemList,
  fetchBillReducItem,
  updateBillReducItemById,
  removeBillReducItemById,
  createBillReducItem,
  removeBillReducItemList
} from '@service/api/billreducitem';

// types
import * as types from '../types';

export const getBillReducItemList = (payload) => async (dispatch) => {
  return fetchBillReducItemList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_BILL_REDUCTION,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeBillReducItem = (payload) => () => {
  return removeBillReducItemById(payload).then((res) => {
    return res;
  });
};

export const addBillReducItem = (payload) => () => {
  return createBillReducItem(payload).then((res) => {
    return res;
  });
};

export const getBillReducItem = (payload) => () => {
  return fetchBillReducItem(payload).then((res) => {
    return res;
  });
};

export const updateBillReducItem = (payload) => () => {
  return updateBillReducItemById(payload).then((res) => {
    return res;
  });
};

export const removeBillReducItemListByParams = (payload) => () => {
  return removeBillReducItemList(payload).then((res) => {
    return res;
  });
};
