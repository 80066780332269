import * as types from '../types';

const initialState = {
  procCodes: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROCEDURE_CODES:
      return {
          ...state,
          procCodes: action.payload,
        };
    default:
      return state;
  }
};

export default reducer;
