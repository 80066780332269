// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch pswrist list
 * @param {*} payload
 */
export async function fetchPswristList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`pswrist?${params}`);
}

/**
 * get pswrist
 * @param {*} payload
 */

export async function fetchPswrist(payload) {
  return GET(`pswrist/${payload}`);
}

/**
 * update pswrist by id
 * @param {*} payload
 */
export async function updatePswristById(payload) {
  const { id, ...rest } = payload;
  return PUT(`pswrist/${id}`, rest);
}

/**
 * remove pswrist by id
 * @param {*} payload
 */
export async function removePswristById(payload) {
  return DELETE(`pswrist/${payload}`);
}

/**
 * create pswrist
 * @param {*} payload
 */
export async function createPswrist(payload) {
  return POST(`pswrist`, payload);
}
