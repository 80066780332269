import React from 'react';

// antd
import { Button, Card, Col, Row, Space } from 'antd';

// redux
import { useDispatch } from 'react-redux';
import { common } from '@redux/combineActions';


// components
import PageHeaderContainer from '@components/common/PageHeaderContainer';

const ErrorComponent = () => {
  const dispatch = useDispatch();

  const handleBackToLogin = () => {
    dispatch(common.user.userLogout());
    window.location.href = '/login';
  };

  return (
    <PageHeaderContainer
      custom={false}
      showHeader={false}>

      <div className="label-list-section">
        <Row style={{ height: '75vh' }} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-around" align="top">
          <Col className="gutter-row" span={8} />
          <Col className="gutter-row" span={8}>
            <Card hoverable title="Error">
              <Space className="flex-col flex-center-center" size={25} direction="vertical">
                <p style={{ fontSize: 12, minHeight: 100, maxHeight: 100 }}>
                  The system encountered an error. This event is logged and will be investigated. Sorry for the trouble.
                </p>
                <Button onClick={handleBackToLogin} block size="large" type="primary">Back to Login</Button>
              </Space>
            </Card>
          </Col>
          <Col className="gutter-row" span={8} />
        </Row>
      </div>


    </PageHeaderContainer>
  );
};

export default ErrorComponent;
