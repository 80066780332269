import {
  fetchFuncimpList,
  fetchFuncimp,
  updateFuncimpById,
  removeFuncimpById,
  createFuncimp
} from '@service/api/funcimp';

// types
import * as types from '../types';

export const getFuncimpList = (payload) => (dispatch) => {
  return fetchFuncimpList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_FUNC_IMP,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeFuncimp = (payload) => () => {
  return removeFuncimpById(payload).then((res) => {
    return res;
  });
};

export const addFuncimp = (payload) => () => {
  return createFuncimp(payload).then((res) => {
    return res;
  });
};

export const getFuncimp = (payload) => () => {
  return fetchFuncimp(payload).then((res) => {
    return res;
  });
};

export const updateFuncimp = (payload) => () => {
  return updateFuncimpById(payload).then((res) => {
    return res;
  });
};
