import {
  fetchPsycheList,
  fetchPsyche,
  updatePsycheById,
  removePsycheById,
  createPsyche
} from '@service/api/psyche';

// types
import * as types from '../types';

export const getPsycheList = (payload) => async (dispatch) => {
  return fetchPsycheList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PSPSYCHEQ,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePsyche = (payload) => () => {
  return removePsycheById(payload).then((res) => {
    return res;
  });
};

export const addPsyche = (payload) => () => {
  return createPsyche(payload).then((res) => {
    return res;
  });
};

export const getPsyche = (payload) => () => {
  return fetchPsyche(payload).then((res) => {
    return res;
  });
};

export const updatePsyche = (payload) => () => {
  return updatePsycheById(payload).then((res) => {
    return res;
  });
};
