import {
  fetchPatientmedList,
  fetchPatientmed,
  updatePatientmedById,
  removePatientmedById,
  createPatientmed,
  removePatientmedByPatientId,
} from '@service/api/patientmed';

import {
  fetchMedicationList,
} from '@service/api/medication';

// types
import * as types from '../types';

export const getPatientmedList = (payload) => async (dispatch) => {
  return fetchPatientmedList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PATIENT_MED,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const getMtusListByTpBp = (payload) => async () => {
  const res = await fetchMedicationList(payload);
  return res;
};

export const removePatientmed = (payload) => () => {
  return removePatientmedById(payload).then((res) => {
    return res;
  });
};

export const clearPatientmed = (payload) => async () => {
  const res = await removePatientmedByPatientId(payload);
  return res;
};

export const addPatientmed = (payload) => () => {
  return createPatientmed(payload).then((res) => {
    return res;
  });
};

export const getPatientmed = (payload) => () => {
  return fetchPatientmed(payload).then((res) => {
    return res;
  });
};

export const updatePatientmed = (payload) => () => {
  return updatePatientmedById(payload).then((res) => {
    return res;
  });
};

export const setActiveMtusTp = (params) => {
  return {
    type: types.SET_ACTIVE_MTUS_TP,
    payload: params,
  };
};

export const setActiveMtusBp = (params) => {
  return {
    type: types.SET_ACTIVE_MTUS_BP,
    payload: params,
  };
};

export const setSelectedMtus = (params) => {
  return {
    type: types.SET_SELECTED_MTUS,
    payload: params,
  };
};
