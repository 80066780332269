// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch psfoot list
 * @param {*} payload
 */
export async function fetchPsfootList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`psfoot?${params}`);
}

/**
 * get psfoot
 * @param {*} payload
 */

export async function fetchPsfoot(payload) {
  return GET(`psfoot/${payload}`);
}

/**
 * update psfoot by id
 * @param {*} payload
 */
export async function updatePsfootById(payload) {
  const { id, ...rest } = payload;
  return PUT(`psfoot/${id}`, rest);
}

/**
 * remove psfoot by id
 * @param {*} payload
 */
export async function removePsfootById(payload) {
  return DELETE(`psfoot/${payload}`);
}

/**
 * create psfoot
 * @param {*} payload
 */
export async function createPsfoot(payload) {
  return POST(`psfoot`, payload);
}
