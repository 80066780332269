// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch historyaccident list
 * @param {*} payload
 */
export async function fetchHistoryaccidentList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`historyaccident?${params}`);
}

/**
 * get historyaccident
 * @param {*} payload
 */

export async function fetchHistoryaccident(payload) {
  return GET(`historyaccident/${payload}`);
}

/**
 * update historyaccident by id
 * @param {*} payload
 */
export async function updateHistoryaccidentById(payload) {
  const { id, ...rest } = payload;
  return PUT(`historyaccident/${id}`, rest);
}

/**
 * remove historyaccident by id
 * @param {*} payload
 */
export async function removeHistoryaccidentById(payload) {
  return DELETE(`historyaccident/${payload}`);
}

/**
 * create historyaccident
 * @param {*} payload
 */
export async function createHistoryaccident(payload) {
  return POST(`historyaccident`, payload);
}
