import {
  fetchGenapp1List,
  fetchGenapp1,
  updateGenapp1ById,
  removeGenapp1ById,
  createGenapp1
} from '@service/api/genapp1';

// types
import * as types from '../types';

export const getGenapp1List = (payload) => (dispatch) => {
  return fetchGenapp1List(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_GENAPP_1,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeGenapp1 = (payload) => () => {
  return removeGenapp1ById(payload).then((res) => {
    return res;
  });
};

export const addGenapp1 = (payload) => (dispatch) => {
  return createGenapp1(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_GENAPP_1,
        payload: [res.data],
      });
    }
    return res;
  });
};

export const getGenapp1 = (payload) => () => {
  return fetchGenapp1(payload).then((res) => {
    return res;
  });
};

export const updateGenapp1 = (payload) => (dispatch) => {
  return updateGenapp1ById(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_GENAPP_1,
        payload: [res.data],
      });
    }
    return res;
  });
};
