import {
  fetchPslbackList,
  fetchPslback,
  updatePslbackById,
  removePslbackById,
  createPslback
} from '@service/api/pslback';

// types
import * as types from '../types';

export const getPslbackList = (payload) => async (dispatch) => {
  return fetchPslbackList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PSLBACK,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePslback = (payload) => () => {
  return removePslbackById(payload).then((res) => {
    return res;
  });
};

export const addPslback = (payload) => () => {
  return createPslback(payload).then((res) => {
    return res;
  });
};

export const getPslback = (payload) => () => {
  return fetchPslback(payload).then((res) => {
    return res;
  });
};

export const updatePslback = (payload) => () => {
  return updatePslbackById(payload).then((res) => {
    return res;
  });
};
