// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch pshand list
 * @param {*} payload
 */
export async function fetchPshandList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`pshand?${params}`);
}

/**
 * get pshand
 * @param {*} payload
 */

export async function fetchPshand(payload) {
  return GET(`pshand/${payload}`);
}

/**
 * update pshand by id
 * @param {*} payload
 */
export async function updatePshandById(payload) {
  const { id, ...rest } = payload;
  return PUT(`pshand/${id}`, rest);
}

/**
 * remove pshand by id
 * @param {*} payload
 */
export async function removePshandById(payload) {
  return DELETE(`pshand/${payload}`);
}

/**
 * create pshand
 * @param {*} payload
 */
export async function createPshand(payload) {
  return POST(`pshand`, payload);
}
