import * as types from '../types';

const initialState = {
  poffice: undefined,
  poffices: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_PROVIDER_OFFICE_LIST:
      return {
          ...state,
          poffices: action.payload,
        };

    case types.SET_PROVIDER_OFFICE:
      return {
        ...state,
        poffice: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
