// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch pselbow list
 * @param {*} payload
 */
export async function fetchPselbowList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`pselbow?${params}`);
}

/**
 * get pselbow
 * @param {*} payload
 */

export async function fetchPselbow(payload) {
  return GET(`pselbow/${payload}`);
}

/**
 * update pselbow by id
 * @param {*} payload
 */
export async function updatePselbowById(payload) {
  const { id, ...rest } = payload;
  return PUT(`pselbow/${id}`, rest);
}

/**
 * remove pselbow by id
 * @param {*} payload
 */
export async function removePselbowById(payload) {
  return DELETE(`pselbow/${payload}`);
}

/**
 * create pselbow
 * @param {*} payload
 */
export async function createPselbow(payload) {
  return POST(`pselbow`, payload);
}
