import {
  fetchNeuroList,
  fetchNeuro,
  updateNeuroById,
  removeNeuroById,
  createNeuro
} from '@service/api/neuro';

// types
import * as types from '../types';

export const getNeuroList = (payload) => async(dispatch) => {
  return fetchNeuroList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PSNEURO,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeNeuro = (payload) => () => {
  return removeNeuroById(payload).then((res) => {
    return res;
  });
};

export const addNeuro = (payload) => () => {
  return createNeuro(payload).then((res) => {
    return res;
  });
};

export const getNeuro = (payload) => () => {
  return fetchNeuro(payload).then((res) => {
    return res;
  });
};

export const updateNeuro = (payload) => () => {
  return updateNeuroById(payload).then((res) => {
    return res;
  });
};
