import {
  fetchPskneeList,
  fetchPsknee,
  updatePskneeById,
  removePskneeById,
  createPsknee
} from '@service/api/psknee';

// types
import * as types from '../types';

export const getPskneeList = (payload) => (dispatch) => {
  return fetchPskneeList(payload).then((res) => {
    if (res.success) {
      if (res.data.docs.length > 0) {
        res.data.docs.map(doc => {
          if (doc.lr === 'right') {
            dispatch({
              type: types.SET_PSRKNEE,
              payload: [doc],
            });
          }
          if (doc.lr === 'left') {
            dispatch({
              type: types.SET_PSLKNEE,
              payload: [doc],
            });
          }
          return doc;
        });
      }
    }
    return res;
  });
};

export const removePsknee = (payload) => () => {
  return removePskneeById(payload).then((res) => {
    return res;
  });
};

export const addPsknee = (payload) => () => {
  return createPsknee(payload).then((res) => {
    return res;
  });
};

export const getPsknee = (payload) => () => {
  return fetchPsknee(payload).then((res) => {
    return res;
  });
};

export const updatePsknee = (payload) => () => {
  return updatePskneeById(payload).then((res) => {
    return res;
  });
};
