import {
  fetchOthermedsList,
  fetchOthermeds,
  updateOthermedsById,
  removeOthermedsById,
  createOthermeds,
  removeOthermedsByPatientId,
} from '@service/api/othermeds';

// types
import * as types from '../types';

export const getOthermedsList = (payload) => async (dispatch) => {
  return fetchOthermedsList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_OTHER_MEDS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeOthermeds = (payload) => () => {
  return removeOthermedsById(payload).then((res) => {
    return res;
  });
};

export const clearOthermeds = (payload) => async () => {
  const res = await removeOthermedsByPatientId(payload);
  return res;
};

export const addOthermeds = (payload) => () => {
  return createOthermeds(payload).then((res) => {
    return res;
  });
};

export const getOthermeds = (payload) => () => {
  return fetchOthermeds(payload).then((res) => {
    return res;
  });
};

export const updateOthermeds = (payload) => () => {
  return updateOthermedsById(payload).then((res) => {
    return res;
  });
};
