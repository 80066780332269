// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch neuro list
 * @param {*} payload
 */
export async function fetchNeuroList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`neuro?${params}`);
}

/**
 * get neuro
 * @param {*} payload
 */

export async function fetchNeuro(payload) {
  return GET(`neuro/${payload}`);
}

/**
 * update neuro by id
 * @param {*} payload
 */
export async function updateNeuroById(payload) {
  const { id, ...rest } = payload;
  return PUT(`neuro/${id}`, rest);
}

/**
 * remove neuro by id
 * @param {*} payload
 */
export async function removeNeuroById(payload) {
  return DELETE(`neuro/${payload}`);
}

/**
 * create neuro
 * @param {*} payload
 */
export async function createNeuro(payload) {
  return POST(`neuro`, payload);
}
