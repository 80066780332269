// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch psknee list
 * @param {*} payload
 */
export async function fetchPskneeList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`psknee?${params}`);
}

/**
 * get psknee
 * @param {*} payload
 */

export async function fetchPsknee(payload) {
  return GET(`psknee/${payload}`);
}

/**
 * update psknee by id
 * @param {*} payload
 */
export async function updatePskneeById(payload) {
  const { id, ...rest } = payload;
  return PUT(`psknee/${id}`, rest);
}

/**
 * remove psknee by id
 * @param {*} payload
 */
export async function removePskneeById(payload) {
  return DELETE(`psknee/${payload}`);
}

/**
 * create psknee
 * @param {*} payload
 */
export async function createPsknee(payload) {
  return POST(`psknee`, payload);
}
