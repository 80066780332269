// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch note list
 * @param {*} payload
 */
export async function fetchNoteList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`note?${params}`);
}

/**
 * get note
 * @param {*} payload
 */

export async function fetchNote(payload) {
  return GET(`note/${payload}`);
}

/**
 * update note by id
 * @param {*} payload
 */
export async function updateNoteById(payload) {
  const { id, ...rest } = payload;
  return PUT(`note/${id}`, rest);
}

/**
 * remove note by id
 * @param {*} payload
 */
export async function removeNoteById(payload) {
  return DELETE(`note/${payload}`);
}

/**
 * create note
 * @param {*} payload
 */
export async function createNote(payload) {
  return POST(`note`, payload);
}
