import {
  fetchChestwallList,
  fetchChestwall,
  updateChestwallById,
  removeChestwallById,
  createChestwall
} from '@service/api/chestwall';

// types
import * as types from '../types';

export const getChestwallList = (payload) => async (dispatch) => {
  return fetchChestwallList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_CHEST_WALL,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeChestwall = (payload) => () => {
  return removeChestwallById(payload).then((res) => {
    return res;
  });
};

export const addChestwall = (payload) => () => {
  return createChestwall(payload).then((res) => {
    return res;
  });
};

export const getChestwall = (payload) => () => {
  return fetchChestwall(payload).then((res) => {
    return res;
  });
};

export const updateChestwall = (payload) => () => {
  return updateChestwallById(payload).then((res) => {
    return res;
  });
};
