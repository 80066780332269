import {
  fetchPatientDiagList,
  fetchPatientDiag,
  updatePatientDiagById,
  removePatientDiagById,
  createPatientDiag
} from '@service/api/patientdiag';

// types
import * as types from '../types';

export const getPatientDiagList = (payload) => async (dispatch) => {
  return fetchPatientDiagList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PATIENT_DIAG,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePatientDiag = (payload) => () => {
  return removePatientDiagById(payload).then((res) => {
    return res;
  });
};

export const addPatientDiag = (payload) => () => {
  return createPatientDiag(payload).then((res) => {
    return res;
  });
};

export const getPatientDiag = (payload) => () => {
  return fetchPatientDiag(payload).then((res) => {
    return res;
  });
};

export const updatePatientDiag = (payload) => () => {
  return updatePatientDiagById(payload).then((res) => {
    return res;
  });
};
