// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch preauth list
 * @param {*} payload
 */
export async function fetchPreauthList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`preauth?${params}`);
}

/**
 * get preauth
 * @param {*} payload
 */

export async function fetchPreauth(payload) {
  return GET(`preauth/${payload}`);
}

/**
 * update preauth by id
 * @param {*} payload
 */
export async function updatePreauthById(payload) {
  const { id, ...rest } = payload;
  return PUT(`preauth/${id}`, rest);
}

/**
 * remove preauth by id
 * @param {*} payload
 */
export async function removePreauthById(payload) {
  return DELETE(`preauth/${payload}`);
}

/**
 * remove preauth by id
 * @param {*} payload
 */
export async function removePreauthByIds(payload) {
  return DELETE(`preauth?ids=${payload}`);
}

/**
 * create preauth
 * @param {*} payload
 */
export async function createPreauth(payload) {
  return POST(`preauth`, payload);
}
