// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch patients list
 * @param {*} payload
 */
export async function fetchPatientsList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`patients?${params}`);
}

/**
 * get patients
 * @param {*} payload
 */

export async function fetchPatients(payload) {
  return GET(`patients/${payload}`);
}

/**
 * update patients by id
 * @param {*} payload
 */
export async function updatePatientsById(payload) {
  const { id, ...rest } = payload;
  return PUT(`patients/${id}`, rest);
}

/**
 * remove patients by id
 * @param {*} payload
 */
export async function removePatientsById(payload) {
  return DELETE(`patients/${payload}`);
}

/**
 * remove patients by id
 * @param {*} payload
 */
export async function removePatientsByIds(payload) {
  return DELETE(`patients?ids=${payload}`);
}

/**
 * create patients
 * @param {*} payload
 */
export async function createPatients(payload) {
  return POST(`patients`, payload);
}

/**
 * generate chart no
 * @param {*} payload
 */
export async function generateChartNo(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`patients/genchartno?${params}`);
}