// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch heent list
 * @param {*} payload
 */
export async function fetchHeentList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`heent?${params}`);
}

/**
 * get heent
 * @param {*} payload
 */

export async function fetchHeent(payload) {
  return GET(`heent/${payload}`);
}

/**
 * update heent by id
 * @param {*} payload
 */
export async function updateHeentById(payload) {
  const { id, ...rest } = payload;
  return PUT(`heent/${id}`, rest);
}

/**
 * remove heent by id
 * @param {*} payload
 */
export async function removeHeentById(payload) {
  return DELETE(`heent/${payload}`);
}

/**
 * create heent
 * @param {*} payload
 */
export async function createHeent(payload) {
  return POST(`heent`, payload);
}
