import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import axios from 'axios';

// redux
import { useSelector } from 'react-redux';
import store from '@redux/store';
import { common, rptgen } from '@redux/combineActions';

// layout
import BasicLayout from '@layout/BasicLayout';

// page
import Login from '@pages/login';

// utils
import { adminConfig, afterLoginConfig, newPatientConfig, existingPatientConfig, menuConfig } from '@utils/menu-config';
import { checkToken, decode } from '@utils/methods';

const token = localStorage.getItem('token');
const tokenx = localStorage.getItem('tokenx');
const account = JSON.parse(localStorage.getItem('account'));

const provider = JSON.parse(sessionStorage.getItem('provider'));
const patient = JSON.parse(sessionStorage.getItem('patient'));
const examId = sessionStorage.getItem('examId');
const patientdoe = JSON.parse(sessionStorage.getItem('patientdoe'));

if (token && tokenx) {
  const decodedToken1 = decode(token);
  const decodedToken2 = decode(tokenx);

  if (checkToken(decodedToken1) || checkToken(decodedToken2)) {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenx');

    store.dispatch(common.user.userLogout());
    window.location.href = '/login';
  } else {
    store.dispatch(common.user.setUserDetails(account));
    store.dispatch(common.user.setRole(account));
    common.user.setAuthorizationHeader(token);
    if (provider) {
      store.dispatch(rptgen.directwebremote.setProvider(provider));
      store.dispatch(rptgen.templates.getTemplateByPcode(provider.pcode));
    }

    if (patient && patient !== null) {
      store.dispatch(rptgen.patients.setPatient(patient));

      if (examId) store.dispatch(rptgen.patients.setExamId(examId));
      if (patientdoe) {
        const { _id } = patient;
        store.dispatch(rptgen.patients.triggerDoeListTap(patientdoe, _id));
      }
    }
  }
} else {
  axios.defaults.headers.common.Authorization = `Bearer`;
}

const App = () => {
  const {
    user: { authenticated },
  } = useSelector(state => state.common);

  const getSubRoutes = route => {
    return route.map(subRoute => {
      const parentRoute = <Route path={subRoute.key} component={subRoute.component} key={subRoute.key} exact />;
      if (subRoute.subRoute) {
        return [parentRoute, ...getSubRoutes(subRoute.subRoute)];
      }
      return parentRoute;
    });
  };

  const adminRoutes = adminConfig.map(route => {
    if (route.subRoute.length === 0) {
      return <Route path={route.key} component={route.component} key={route.key} exact />;
    }
    return getSubRoutes(route.subRoute).flat();
  });

  return (
    <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
      {authenticated ? (
        <BasicLayout>
          <Switch>
            {/* routes */}

            {existingPatientConfig.map(route => (route.subRoute.length === 0 ? <Route path={route.key} component={route.component} key={route.key} exact /> : route.subRoute.map(subRoute => <Route path={subRoute.key} component={subRoute.component} key={subRoute.key} exact />)))}

            {newPatientConfig.map(route => (route.subRoute.length === 0 ? <Route path={route.key} component={route.component} key={route.key} exact /> : route.subRoute.map(subRoute => <Route disabled path={subRoute.key} component={subRoute.component} key={subRoute.key} exact />)))}

            {menuConfig.map(level1 => (level1.subRoute.length === 0 ? <Route path={level1.key} component={level1.component} key={level1.key} exact /> : level1?.subRoute?.map(level2 => (level2?.subRoute?.length === 0 ? <Route path={level2.key} component={level2.component} key={level2.key} exact /> : level2?.subRoute?.map(level3 => <Route path={level3.key} component={level3.component} key={level3.key} exact />)))))}

            {/* {acuConfig.map(level1 => (level1.subRoute.length === 0 ? <Route path={level1.key} component={level1.component} key={level1.key} exact /> : level1?.subRoute?.map(level2 => (level2?.subRoute?.length === 0 ? <Route path={level2.key} component={level2.component} key={level2.key} exact /> : level2?.subRoute?.map(level3 => <Route path={level3.key} component={level3.component} key={level3.key} exact />)))))} */}

            {adminRoutes}
            {/*adminConfig.map((route) =>
              route.subRoute.length === 0 ? (
                <Route path={route.key} component={route.component} key={route.key} exact />
              ) : (
                route.subRoute.map((subRoute) => (
                  <Route
                    path={subRoute.key}
                    component={subRoute.component}
                    key={subRoute.key}
                    exact
                  />
                ))
              ),
            )*/}

            {afterLoginConfig.map(route => (route.subRoute.length === 0 ? <Route path={route.key} component={route.component} key={route.key} exact /> : route.subRoute.map(subRoute => <Route path={subRoute.key} component={subRoute.component} key={subRoute.key} exact />)))}
            <Redirect from="/" to="/welcome" />
          </Switch>
        </BasicLayout>
      ) : (
        <Switch>
          <Route path="/login" component={Login} exact />
          <Redirect from="/" to="/login" />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default App;
