import { combineReducers } from 'redux';

import common from './common/reducers';
import form from './common/reducers/form';
import rptgen from './rptgen/reducers';

const reducers = combineReducers({
  common,
  form,
  rptgen,
});

export default reducers;
