import {
  fetchFeetList,
  fetchFeet,
  updateFeetById,
  removeFeetById,
  createFeet
} from '@service/api/feet';

// types
import * as types from '../types';

export const getFeetList = (payload) => async (dispatch) => {
  return fetchFeetList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_MEASUREMENT_L_EXTREMITIES,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeFeet = (payload) => () => {
  return removeFeetById(payload).then((res) => {
    return res;
  });
};

export const addFeet = (payload) => () => {
  return createFeet(payload).then((res) => {
    return res;
  });
};

export const getFeet = (payload) => () => {
  return fetchFeet(payload).then((res) => {
    return res;
  });
};

export const updateFeet = (payload) => () => {
  return updateFeetById(payload).then((res) => {
    return res;
  });
};
