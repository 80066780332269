// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch procedurecode list
 * @param {*} payload
 */
export async function fetchProcedureCodeList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`procedurecode?${params}`);
}

/**
 * get procedurecode
 * @param {*} payload
 */

export async function fetchProcedureCode(payload) {
  return GET(`procedurecode/${payload}`);
}

/**
 * update procedurecode by id
 * @param {*} payload
 */
export async function updateProcedureCodeById(payload) {
  const { id, ...rest } = payload;
  return PUT(`procedurecode/${id}`, rest);
}

/**
 * remove procedurecode by id
 * @param {*} payload
 */
export async function removeProcedureCodeById(payload) {
  return DELETE(`procedurecode/${payload}`);
}

/**
 * create procedurecode
 * @param {*} payload
 */
export async function createProcedureCode(payload) {
  return POST(`procedurecode`, payload);
}
