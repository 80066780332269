// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch othermeds list
 * @param {*} payload
 */
export async function fetchOthermedsList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`othermeds?${params}`);
}

/**
 * get othermeds
 * @param {*} payload
 */

export async function fetchOthermeds(payload) {
  return GET(`othermeds/${payload}`);
}

/**
 * update othermeds by id
 * @param {*} payload
 */
export async function updateOthermedsById(payload) {
  const { id, ...rest } = payload;
  return PUT(`othermeds/${id}`, rest);
}

/**
 * remove othermeds by id
 * @param {*} payload
 */
export async function removeOthermedsById(payload) {
  return DELETE(`othermeds/${payload}`);
}

/**
 * remove othermeds by id
 * @param {*} payload
 */
export async function removeOthermedsByPatientId(payload) {
  const params = methods.convertQueryString(payload);
  return DELETE(`othermeds?${params}`);
}

/**
 * create othermeds
 * @param {*} payload
 */
export async function createOthermeds(payload) {
  return POST(`othermeds`, payload);
}
