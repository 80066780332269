// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch pslback list
 * @param {*} payload
 */
export async function fetchPslbackList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`pslback?${params}`);
}

/**
 * get pslback
 * @param {*} payload
 */

export async function fetchPslback(payload) {
  return GET(`pslback/${payload}`);
}

/**
 * update pslback by id
 * @param {*} payload
 */
export async function updatePslbackById(payload) {
  const { id, ...rest } = payload;
  return PUT(`pslback/${id}`, rest);
}

/**
 * remove pslback by id
 * @param {*} payload
 */
export async function removePslbackById(payload) {
  return DELETE(`pslback/${payload}`);
}

/**
 * create pslback
 * @param {*} payload
 */
export async function createPslback(payload) {
  return POST(`pslback`, payload);
}
