let lat;
let lng;
let gMap;

export const findNeedle = (needle, haystack) => {
  let results = [];
  let idx = haystack.indexOf(needle);
  while (idx !== -1) {
    results.push(idx);
    idx = haystack.indexOf(needle, idx + 1);
  }
  return results;
};

export const extractPageFromMtus = (v, key2 = undefined) => {
  // console.log('[extractPageFromMtus] ', v);

  if (key2 && key2 === 'treatfim') {
    // console.log('[returned fim');
    return 'Chronic Pain Medical Treatment Guidelines MTUS – 8 C.C.R. § 9792.24.2 (July 28, 2016); page 86 of 1063';
  } else {
    const results = findNeedle('(p.', v).map(idx => {
      let pageInfo = '';
      const chop1 = v.substr(idx, v.length);

      if (chop1.indexOf('Guidelines)') > -1) {
        pageInfo = chop1.substr(0, chop1.indexOf('Guidelines)') + 11).replace('MTUS MTUS', 'MTUS');
      }

      if (chop1.indexOf('2017)') > -1) {
        pageInfo = chop1.substr(0, chop1.indexOf('2017)') + 5).replace('MTUS MTUS', 'MTUS');
      }
      return pageInfo;
    });

    // console.log('[ return extractPageFromMtus results]]]]]]] ', results);
    return results.join(',').length > 0 ? results.join(',') : v;
  }
};

export const downloadReport = (url, fn) => {
  // window.open(url, '_blank');
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fn);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const fetchDocumentUrl = async (url, token) => {
  try {
    const res = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!res.ok) {
      throw new Error('Failed fetch.');
    }

    const blob = await res.blob();
    const objUrl = window.URL.createObjectURL(blob);
    console.log('[B L O B] ', objUrl);
    return objUrl;
  } catch (err) {
    console.error(err);
    throw err;  
  }
};

export const downloadReportWithToken = (url, fn, token) => {
  fetch(url, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
  // .then(r => {
  //   console.log('[R] ', r);
  // })
  .then(response => response.blob())
  .then(blob => {
    console.log('[B L O B] ', blob);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fn;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  })
  .catch(err => console.error(err));
};

const showPosition = pos => {
  const { latitude, longitude } = pos.coords;
  // pos.coords.accuracy
  lat = latitude;
  lng = longitude;
  gMap = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
};

const geoLocOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const geoLocErr = err => {
  console.warn(`ERROR: (${err.code}): ${err.message}`);
  console.log('[geolocation not supported]');
  lat = '[NOT SUPPORTED]';
  lng = '[NOT SUPPORTED]';
  gMap = '[NOT SUPPORTED]';
};

export const getLocation = () => {
  console.log('[GET LOC]');
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, geoLocErr, geoLocOptions);
  } else {
    console.log('[geolocation not supported]');
    lat = '[NOT SUPPORTED]';
    lng = '[NOT SUPPORTED]';
    gMap = '[NOT SUPPORTED]';
  }
};

export const getMapAndCoordinates = { lat, lng, gMap };

export const requiredFields = [
  'pcode',
  'scn18',
  'scn21',
  'scn23',
  'scn24',
  'scn27',
  'chartno',
  // 'scn28', trix request not to require SSN
  // 'scn29', trix request not to require Gender
  // 'scn30', trix request not to require Marital Status
];

export const disableSave = (v, reqFlds) => {
  let ctr = 0;
  if (v) {
    Object.keys(v).map(fld => {
      if (reqFlds.includes(fld)) {
        if (v[fld]) {
          ctr += 1;
          // trix request commented SSN not a required field
          // check if length is correct else disable save
          // if (fld === 'scn28' && v[fld].length < 9) ctr -= 1;
        }
      }
      return fld;
    });
  }
  // console.log('[disableSave] ', ctr < 10);
  return ctr < 7;
};

export const providahMatrix = {
  balian: ['gasp', 'seli', 'spra', 'mulc', 'price', 'mthomas', 'srobinson'],
  mah: ['gasp', 'issa', 'mulc', 'price', 'mthomas', 'srobinson'],
};

// removed treatfre 01/24/2021
// removed treatmultidisc 01/25/2021

export const tplanSpecRef = ['treatinternal', 'treatopthal', 'treatpsyche', 'treatderma', 'treatortho', 'treatpodia', 'treatrheuma', 'treatcardio', 'treatneuro', 'treatent', 'treatpulmo', 'treatgensurg'];

// removed 'treatpodia' updated record tp2: ALL to tp2: FOOT/ANKLE
export const tlight = ['treatfim', 'treatinternal', 'treatneuro', 'treatopthal', 'treatent', 'treatiniteval', 'treatpharma', 'treatcpharma', 'treatrheuma', 'treatpulmo', 'treatderma', 'treatcardio', 'treatfmc', 'treatgensurg']; // , 'treatpsyche'

export const tplanFields = ['treatmri', 'treatemg', 'treatacu', 'treatphymed', 'treatchiro', 'treatfim', 'treatinternal', 'treatpsyche', 'treatortho', 'treatneuro', 'treatxray', 'treatiniteval', 'treatpharma', 'treatxpt', 'treatxacu', 'treatbraces', 'treation', 'treatift', 'treathot', 'treattens', 'treatcold', 'treatsling', 'treatsplint', 'treatxchiro', 'treatsupp', 'treatmisc', 'treatopthal', 'treatcpharma', 'treatmultidisc', 'treatbiofeed', 'treatctscan', 'treatfrp', 'treatfre', 'treatchronic', 'treatfce', 'treatmed', 'treatcane', 'treatcrutches', 'treathek', 'treatifunit', 'treatipm', 'treatinj', 'treatsurg', 'treatsf', 'treatpodia', 'treatent', 'treatexer', 'treatrheuma', 'treatpulmo', 'treatderma', 'treatcardio', 'treatfmc', 'treatgensurg', 'treatot', 'treatxot'];

// RPT-051	RFA, PR2, INITIAL, DFR	CHRONIC PAIN MANAGEMENT		PER BODY PART
export const tplanRfa = [
  'treatfre',
  'treatfce',
  'treatfrp',
  'treatmultidisc',
  'treatpharma',
  'treatcpharma',
  'treatiniteval',
  'treatfim',
  'treatfmc', // 'treatchronic'
];

export const medFields = ['analgesic', 'acetaminophen', 'antidepressant', 'antiepileptic', 'antiinfla', 'anticonvulsant', 'antiulcerative', 'biphosponates', 'calciumblockers', 'capsaicin', 'steroids', 'intra1', 'intra2', 'lidocaine', 'nsaid', 'musclerelaxant', 'opioids', 'sedative', 'compoundcream', 'injection', 'injection1', 'injection2', 'injection3', 'injection4', 'tetanus', 'topical'];

export const bpFields = ['CERVICAL SPINE', 'THORACIC SPINE', 'LUMBAR SPINE', 'RIGHT SHOULDER', 'LEFT SHOULDER', 'RIGHT ELBOW', 'LEFT ELBOW', 'RIGHT WRIST', 'LEFT WRIST', 'RIGHT HAND', 'LEFT HAND', 'RIGHT HIP', 'LEFT HIP', 'RIGHT KNEE', 'LEFT KNEE', 'RIGHT FOOT/ANKLE', 'LEFT FOOT/ANKLE', 'RIGHT TOE', 'LEFT TOE'];

export const sortRef = {
  'CERVICAL SPINE': 1,
  'THORACIC SPINE': 2,
  'LUMBAR SPINE': 3,
  'RIGHT SHOULDER': 4,
  'LEFT SHOULDER': 5,
  'RIGHT ELBOW': 6,
  'LEFT ELBOW': 7,
  'RIGHT WRIST': 8,
  'LEFT WRIST': 9,
  'RIGHT HAND': 10,
  'LEFT HAND': 11,
  'RIGHT HIP': 12,
  'LEFT HIP': 13,
  'RIGHT KNEE': 14,
  'LEFT KNEE': 15,
  'RIGHT FOOT/ANKLE': 16,
  'LEFT FOOT/ANKLE': 17,
  'RIGHT TOE': 18,
  'LEFT TOE': 19,  
  'LEFT UPPER EXTREMITY': 20,
  'RIGHT UPPER EXTREMITY': 21,
};

export const diagEmgNcv = {
  'RIGHT AND LEFT UPPER EXTREMITIES': ['diagrshoulder', 'diagrelbow', 'diagrwrist', 'diagrhand', 'diaglshoulder', 'diaglelbow', 'diaglwrist', 'diaglhand'],
  'RIGHT AND LEFT LOWER EXTREMITIES': ['diagrhip', 'diagrknee', 'diagrankfoot', 'diaglhip', 'diaglknee', 'diaglankfoot', 'diagrtoe', 'diagltoe'],
  'LEFT UPPER EXTREMITY': ['diaglshoulder', 'diaglelbow', 'diaglwrist', 'diaglhand'],
  'RIGHT UPPER EXTREMITY': ['diagrshoulder', 'diagrelbow', 'diagrwrist', 'diagrhand'],
  'LEFT LOWER EXTREMITY': ['diaglhip', 'diaglknee', 'diaglankfoot', 'diagltoe'],
  'RIGHT LOWER EXTREMITY': ['diagrhip', 'diagrknee', 'diagrankfoot', 'diagrtoe'],
};

// export const diagSpecRef = {
//   'treatinternal', 'treatopthal', 'treatpsyche', 'treatderma', 'treatortho', 'treatpodia', 'treatrheuma', 'treatcardio', 'treatneuro', 'treatent', 'treatpulmo', 'treatgensurg'
// };

export const tpDiagSpecRef = {  
  'Abdominal Pain': 'treatinternal',
  'Gastrointestinal Complaints': 'treatinternal',
  'Hypertension': 'treatinternal',
  'Shortness of Breath': 'treatinternal',
  'Weight Gain (Obesity)': 'treatinternal',

  'Blurred Vision': 'treatopthal',

  'Anxiety': 'treatpsyche',
  'Depression': 'treatpsyche',
  'Sleep Disorder': 'treatpsyche',
  'Stress': 'treatpsyche',
  
  'Skin Irritation': 'treatderma',
  'Skin Rash': 'treatderma',
  'Burn': 'treatderma',

  'Fibromyalgia': 'treatrheuma',
  'Complex Regional Syndrome': 'treatrheuma',
  'Complex Regional Pain Syndrome': 'treatrheuma',  

  'Chest Pain': 'treatcardio',
  
  'Cervicogenic Headaches': 'treatneuro',
  'Cephalalgia Headaches': 'treatneuro',
  'Dizziness': 'treatneuro',
  
  'Tinnitus': 'treatent',
  
  'Shortness of Breath': 'treatpulmo',
  'Lungs Issues': 'treatpulmo',
  
  'Hernia': 'treatgensurg',
};

export const diagBodyPart = {
  'CERVICAL SPINE': 'diagcspine',
  'THORACIC SPINE': 'diagtspine',
  'LUMBAR SPINE': 'diaglspine',

  'RIGHT SHOULDER': 'diagrshoulder',
  'LEFT SHOULDER': 'diaglshoulder',

  'RIGHT ELBOW': 'diagrelbow',
  'LEFT ELBOW': 'diaglelbow',

  'RIGHT WRIST': 'diagrwrist',
  'LEFT WRIST': 'diaglwrist',
  //
  'RIGHT HAND/FINGER/THUMB': 'diagrhand',
  'LEFT HAND/FINGER/THUMB': 'diaglhand',  
  'RIGHT HAND': 'diagrhand',
  'LEFT HAND': 'diaglhand',

  'RIGHT FINGER': 'diagrfinger',
  'LEFT FINGER': 'diaglfinger',

  'RIGHT THUMB': 'diagrthumb',
  'LEFT THUMB': 'diaglthumb',

  'RIGHT HIP': 'diagrhip',
  'LEFT HIP': 'diaglhip',

  'RIGHT KNEE': 'diagrknee',
  'LEFT KNEE': 'diaglknee',

  'RIGHT FOOT/ANKLE': 'diagrankfoot',
  'LEFT FOOT/ANKLE': 'diaglankfoot',

  'RIGHT TOE': 'diagrtoe',
  'LEFT TOE': 'diagltoe',

  'MISCELLANEOUS': 'diagmisc',
  'OTHER': 'diagother',
};

export const medicationType = {
  ebm: 'EVIDENCE BASED',
  new: 'NEW',
  old: 'OLD',
};

export const med = {
  analgesic: 'ANALGESIC',
  acetaminophen: 'ACETAMINOPHEN',
  antidepressant: 'ANTI-DEPRESSANTS',
  antiepileptic: 'ANTI-EPILEPTIC AGENTS',
  antiinfla: 'ANTI-INFLAMMATORY',
  anticonvulsant: 'ANTI-CONVULSANT',
  antiulcerative: 'ANTI-ULCERATIVE',
  biphosponates: 'BIPHOSPONATES',
  calciumblockers: 'CALCIUM CHANNEL BLOCKERS',
  capsaicin: 'CAPSAICIN',
  steroids: 'GLUCOCORTICOSTEROIDS',
  intra1: 'INTRAARTICULAR INJECTIONS',
  intra2: 'INTRAARTICULAR HYALURONATE',
  lidocaine: 'LIDOCAINE PATCHES',
  nsaid: 'NSAID',
  musclerelaxant: 'MUSCLE RELAXANT',
  opioids: 'OPIOIDS (Oral, Transdermal, and Parenteral (Includes Tramadol))',

  sedative: 'SEDATIVE',
  compoundcream: 'COMPOUND CREAM',
  injection: 'INJECTION',
  injection1: 'COLLAGENASE INJECTION',
  injection2: 'ANESTHETIC INTRAARTICULAR INJECTIONS',
  injection3: 'PLATELET RICH PLASMA INJECTIONS',
  injection4: 'AUTOLOGOUS BLOOD INJECTIONS',
  tetanus: 'TETANUS IMMUNIZATION',
  topical: 'TOPICAL MEDICATIONS',
};

export const bodyPartDiag = {
  diagcspine: 'CERVICAL SPINE',
  diagtspine: 'THORACIC SPINE',
  diaglspine: 'LUMBAR SPINE',

  diagrshoulder: 'RIGHT SHOULDER',
  diaglshoulder: 'LEFT SHOULDER',

  diagrelbow: 'RIGHT ELBOW',
  diaglelbow: 'LEFT ELBOW',

  diagrwrist: 'RIGHT WRIST',
  diaglwrist: 'LEFT WRIST',

  diagrhand: 'RIGHT HAND/FINGER/THUMB',
  diaglhand: 'LEFT HAND/FINGER/THUMB',

  diagrfinger: 'RIGHT FINGER',
  diaglfinger: 'LEFT FINGER',

  diagrthumb: 'RIGHT THUMB',
  diaglthumb: 'LEFT THUMB',

  diagrhip: 'RIGHT HIP',
  diaglhip: 'LEFT HIP',

  diagrknee: 'RIGHT KNEE',
  diaglknee: 'LEFT KNEE',

  diagrankfoot: 'RIGHT FOOT/ANKLE',
  diaglankfoot: 'LEFT FOOT/ANKLE',

  diagrtoe: 'RIGHT TOE',
  diagltoe: 'LEFT TOE',

  diagmisc: 'MISCELLANEOUS',
};

export const getKeyByValue = (v, obj) => {
  let fldKey = '';
  Object.keys(obj).map(key => {
    if (obj[key] === v) fldKey = key;
    return key;
  });
  return fldKey;
};

export const isTreatmentPlanMedication = tp => {
  if (tp === 'capsaicin') return false;
  if (medFields.includes(tp)) return true;
  return false;
};

export const isTreatmentPlanSpecialReferral = tp => {
  if (tp === 'treatfim') return false;
  if (tp === 'treatpharma') return false;
  if (tp === 'treatcpharma') return false;
  if (tp === 'treatpsyche') return true;
  if (tp === 'treatortho') return true;
  if (tlight.includes(tp)) return true;
  return false;
};

var miscDiagPlan = {
  treatortho: ['fibromyalgia', 'orthopedic condition'],
  treatneuro: ['depression', 'cephalgia', 'neuralgia', 'cervicogenic', 'rsd', 'regional pain', 'dizziness'],
  treatinternal: ['obesity', 'gastrointestinal', 'hernia: inguinal', 'hernia: umbilical', 'hernia: abdominal', 'fibromyalgia', 'hypertension', 'dizziness', 'abdominal pain', 'peptic ulcer'],
  treatpsyche: ['stress', 'anxiety', 'depression', 'cephalgia', 'sleep disorder', 'psychological complaints', 'fibromyalgia', 'insomnia'],
  treatrheuma: ['autoimmune disorder/inflammatory arthropathy'],
  treatpulmo: ['shortness of breath'],
  treatderma: [],
  treatcardio: [],
  treatopthal: [],
  treatpodia: ['feet', 'foot'],
  treatent: [],
  treatgensurg: [],
};

export const isMiscTreatmentPlan = (x, v) => {
  for (var tp in miscDiagPlan) {
    if (x === tp) {
      for (var ctr in miscDiagPlan[x]) {
        if (v.toLowerCase().indexOf(miscDiagPlan[x][ctr]) > -1) {
          return true;
        }
      }
      return false;
    }
  }
  return true;
};

export const otherMedsNarrative = 'Medications are recommended. Frequency/Dose/Duration: Observe for functional gains after a prescription. ' + 'There is no specific limit to treatment duration, yet one should be cognizant of adverse effects that may develop with prolonged ' + 'use. Additional medication(s) are reasonable provided there is further, incremental functional gain that should be assessed for ' + 'each. Medication use should cease when there is end of healing, non-compliance, and/or plateau. Resumption of medication may be ' + 'reasonable if there is demonstrated need after cessation. Observations should emphasize objective measures of functional gain in ' + 'preference to subjective measures and/or subjective functional instruments (p. 10, Initial Approaches to Treatment MTUS Guidelines)';

export const medLegalNarrative =
  '<p style="text-align: left">To date, we have not received authorization from the employer or its insurance carrier, thus ' +
  'requiring the preparation of this medical legal evaluation on this case per § 9793 (c) (2) by ' +
  "primary treating physician and § 9793 (g) (2) request of a party (applicant's attorney), for the " +
  'purpose of proving and disproving a contested claim. I will indicate the necessity of current ' +
  'treatment and the relationship of the injuries to the claimed work accident or injury, because it is ' +
  'either you rejected liability for a claimed benefit, you failed to accept liability for the claim and ' +
  'the claim has become presumptively compensable under Section 5402, OR you accepted liability ' +
  'for a claim and a disputed medical fact exists. </p>' +
  '<p style="text-align: left">Moreover, we believe that the employer or its insurance carrier violated § 9792.6 (f) (4) (i) Every ' +
  'claims administrator shall maintain telephone access form 9:00 AM to 5:30 PM Pacific Time, on ' +
  'normal business days, for health care providers to request authorization for medical services. ' +
  'Every claims administrator shall have a facsimile number available for physicians to request ' +
  'authorization for medical services. Every claims administrator shall maintain a process to receive ' +
  'communications from health care providers requesting authorization for medical services after ' +
  'business hours. For purpose of this section a normal business day means a business day as ' +
  'defined in Labor Code section 4600.4 and Civil Code section 9. In addition, for purposes of this ' +
  'section the requirement that the claims administrator maintain a process to receive ' +
  'communications from requesting physicians after business hours shall be satisfied by ' +
  'maintaining a voice mail system or a facsimile number for after business hours requests. </p>' +
  '<p style="text-align: left">Moreover, since no authorization is received, it is deemed defacto denial or refusal to provide ' +
  'medical treatment. Therefore, Medical Provider Network is not applicable in this case, if we ' +
  'happen not to be in your network. </p>' +
  '<p style="text-align: left">A petition for the determination of Medical-Legal Expense disputes will be submitted if the ' +
  'defendant fails to file its petition for the determination of Medical Legal Expense Dispute and ' +
  'DOR within 60 days of our service per LC Section § 10451.1 (B), if payment is not received ' +
  'within 60 days per Labor Section § 4622. </p>' +
  '<p style="text-align: left">Also please see also Labor Code Section § 10451.1 (D) A medical-legal provider is not required ' +
  'to file a claim of costs in the form of a lien in conjunction with the petition or DOR and Labor ' +
  "Code Section § 10451.1 (g) (1) If the Worker's Compensation Appeals Board determines that, as " +
  'a result of bad faith actions or tactics, a defendant failed to comply with the requirements, ' +
  'timelines, and procedures set forth in Labor Code sections 4622, 4603.3, and 4603.6 and the ' +
  'related Rules of the Administrative Director, the defendant shall be liable for the medical-legal ' +
  "provider's reasonable attorney's fees and costs, if any, and for sanctions under Labor Code " +
  "section 5813 and Rule 10561. The amount of the attorney's fees, costs, and sanctions payable " +
  "shall be determined by the Worker's Compensation Appeals Board; however, for bad faith " +
  'actions or tactics occurring on or after the effective date of this section, the monetary sanctions ' +
  'shall not be less than $500. </p><p style="text-align: left">If this is an admitted case and it is deemed ' +
  'authorized, please advise my office.  Fees will be amended under the Evaluation and Management (E/M) services.</p>';

const mEndx = s => {
  const x = s.split(',');
  let fin = '';
  if (s === '') return '';
  if (x.length > 1) {
    for (const l in x) {
      if (+l === x.length - 1) {
        fin += `and ${x[l].trim()}`;
        return fin;
      } else {
        fin += `${x[l].trim()}, `;
      }
    }
  } else if (x.length === 1) {
    return x[0];
  }
  return fin;
};

const mEnd = s => {
  if (s.trim().substring(s.trim().length - 1, s.trim().length) === ',') {
    return s
      .trim()
      .substring(0, s.trim().length - 1)
      .replace(/,([^,]*)$/, ' and$1')
      .toLowerCase();
  }
  return mEndx(s.trim());
};

export const clearReqTreatStorages = () => {
  const jupiter = {
    stura: {},
    ptera: {
      bodyparts: {},
      rfa: {},
      meds: {},
    },
  };

  // SEPULTURA CLEAN UP
  tplanFields.map(fld => {
    if (fld === 'treatmisc') {
      jupiter.stura[fld] = { bp: '', items: [] };
    } else {
      jupiter.stura[fld] = '';
    }
    return fld;
  });

  medFields.map(fld => {
    jupiter.stura[fld] = '';
    jupiter.ptera.meds[fld] = [];
    return fld;
  });
  jupiter.stura.treatother = '';
  jupiter.stura.othermeds = '';
  // END

  return jupiter;
};

export const loadPatientMed = (p, s, tpr) => {
  const specificMeds = ['diclofenac', 'fluriprofen', 'naproxen', 'omeprazole', 'pantropazole', 'gabapentin', 'cyclobenzaprine', 'orphenadrine'];

  const uranus = {
    pmtera: { ...p },
    smtura: { ...s },
  };

  uranus.smtura.treatmed = '';

  if (tpr.length > 0) {
    tpr.map(t => {
      if (!uranus.pmtera.rfa[t.type]) {
        uranus.pmtera.rfa[t.type] = {};
        uranus.pmtera.rfa[t.type].b = [];
      }

      if (!uranus.pmtera.bodyparts[t.bp]) {
        uranus.pmtera.bodyparts[t.bp] = {};
      }

      if (!uranus.pmtera.bodyparts[t.bp][t.type]) {
        uranus.pmtera.bodyparts[t.bp][t.type] = [];
      }

      if (t.type === 'nsaid') {
        if (uranus.smtura.nsaid.indexOf(t.bp) === -1) uranus.smtura.nsaid += `${t.bp}, `;
        if (uranus.smtura.nsaid.indexOf(t.bp) > -1) uranus.smtura.treatmed = '1';
        uranus.pmtera.bodyparts[t.bp][t.type].push(t.frequency.replace(t.bp, 'NSAID'));
        // uranus.pmtera.rfa[t.type].reqtreat = 'NSAID for the ';
        uranus.pmtera.rfa[t.type].reqtreat = 'NSAID - Naproxen Sodium 375mg #90 ';
        uranus.pmtera.rfa[t.type].a = '99070';
        uranus.pmtera.rfa[t.type].b.push({ ordinal: 500, bp: t.bp, freq: `${t.frequency}\n\n` });
        specificMeds.map(sm => {
          if (t.frequency.toLowerCase().indexOf(sm) > -1) {
            // uranus.pmtera.meds[t.type] = specificMeds[z];
            if (!uranus.pmtera.meds[t.type].includes(sm)) {
              uranus.pmtera.meds[t.type].push(sm);
            }
          }
          return sm;
        });
      }

      if (t.type === 'acetaminophen') {
        if (uranus.smtura.acetaminophen.indexOf(t.bp) === -1) uranus.smtura.acetaminophen += `${t.bp}, `;
        if (uranus.smtura.acetaminophen.indexOf(t.bp) > -1) uranus.smtura.treatmed = '1';
        uranus.pmtera.bodyparts[t.bp][t.type].push(t.frequency.replace(t.bp, 'acetaminophen'));
        uranus.pmtera.rfa[t.type].reqtreat = 'Acetaminophen (Acetaminophen) ';
        uranus.pmtera.rfa[t.type].a = '99070';
        uranus.pmtera.rfa[t.type].b.push({ ordinal: 600, bp: t.bp, freq: `${t.frequency}\n\n` });
      }

      if (t.type === 'antiulcerative') {
        if (uranus.smtura.antiulcerative.indexOf(t.bp) === -1) uranus.smtura.antiulcerative += `${t.bp}, `;
        if (uranus.smtura.antiulcerative.indexOf(t.bp) > -1) uranus.smtura.treatmed = '1';
        uranus.pmtera.bodyparts[t.bp][t.type].push(t.frequency.replace(t.bp, 'antiulcerative'));
        // uranus.pmtera.rfa[t.type].reqtreat = 'Antiulcerative for the ';
        uranus.pmtera.rfa[t.type].reqtreat = 'Anti-Ulcerative - Omeprazole 20mg #30 ';
        uranus.pmtera.rfa[t.type].a = '99070';
        uranus.pmtera.rfa[t.type].b.push({ ordinal: 700, bp: t.bp, freq: `${t.frequency}\n\n` });
        specificMeds.map(sm => {
          if (t.frequency.toLowerCase().indexOf(sm) > -1) {
            // uranus.pmtera.meds[t.type] = specificMeds[z];
            if (!uranus.pmtera.meds[t.type].includes(sm)) {
              uranus.pmtera.meds[t.type].push(sm);
            }
          }
          return sm;
        });
      }

      if (t.type === 'antidepressant') {
        if (uranus.smtura.antidepressant.indexOf(t.bp) === -1) uranus.smtura.antidepressant += `${t.bp}, `;
        if (uranus.smtura.antidepressant.indexOf(t.bp) > -1) uranus.smtura.treatmed = '1';
        uranus.pmtera.bodyparts[t.bp][t.type].push(t.frequency.replace(t.bp, 'antidepressant'));
        uranus.pmtera.rfa[t.type].reqtreat = 'Antidepressant for the ';
        uranus.pmtera.rfa[t.type].a = '99070';
        uranus.pmtera.rfa[t.type].b.push({ ordinal: 800, bp: t.bp, freq: `${t.frequency}\n\n` });
      }

      if (t.type === 'antiepileptic') {
        if (uranus.smtura.antiepileptic.indexOf(t.bp) === -1) uranus.smtura.antiepileptic += `${t.bp}, `;
        if (uranus.smtura.antiepileptic.indexOf(t.bp) > -1) uranus.smtura.treatmed = '1';
        uranus.pmtera.bodyparts[t.bp][t.type].push(t.frequency.replace(t.bp, 'antiepileptic'));
        uranus.pmtera.rfa[t.type].reqtreat = 'Antiepileptic for the ';
        uranus.pmtera.rfa[t.type].a = '99070';
        uranus.pmtera.rfa[t.type].b.push({ ordinal: 900, bp: t.bp, freq: `${t.frequency}\n\n` });
        specificMeds.map(sm => {
          if (t.frequency.toLowerCase().indexOf(sm) > -1) {
            // uranus.pmtera.meds[t.type] = specificMeds[z];
            if (!uranus.pmtera.meds[t.type].includes(sm)) {
              uranus.pmtera.meds[t.type].push(sm);
            }
          }
          return sm;
        });
      }

      if (t.type === 'capsaicin') {
        if (uranus.smtura.capsaicin.indexOf(t.bp) === -1) uranus.smtura.capsaicin += `${t.bp}, `;
        if (uranus.smtura.capsaicin.indexOf(t.bp) > -1) uranus.smtura.treatmed = '1';
        uranus.pmtera.bodyparts[t.bp][t.type].push(t.frequency.replace(t.bp, 'capsaicin'));
        uranus.pmtera.rfa[t.type].reqtreat = 'Capsaicin for the ';
        uranus.pmtera.rfa[t.type].a = '99070';
        uranus.pmtera.rfa[t.type].b.push({ ordinal: 1000, bp: t.bp, freq: `${t.frequency}\n\n` });
      }

      if (t.type === 'musclerelaxant') {
        if (uranus.smtura.musclerelaxant.indexOf(t.bp) === -1) uranus.smtura.musclerelaxant += `${t.bp}, `;
        if (uranus.smtura.musclerelaxant.indexOf(t.bp) > -1) uranus.smtura.treatmed = '1';
        uranus.pmtera.bodyparts[t.bp][t.type].push(t.frequency.replace(t.bp, 'musclerelaxant'));
        uranus.pmtera.rfa[t.type].reqtreat = 'Muscle relaxant for the ';
        uranus.pmtera.rfa[t.type].a = '99070';
        uranus.pmtera.rfa[t.type].b.push({ ordinal: 1100, bp: t.bp, freq: `${t.frequency}\n\n` });
        specificMeds.map(sm => {
          if (t.frequency.toLowerCase().indexOf(sm) > -1) {
            // uranus.pmtera.meds[t.type] = specificMeds[z];
            if (!uranus.pmtera.meds[t.type].includes(sm)) {
              uranus.pmtera.meds[t.type].push(sm);
            }
          }
          return sm;
        });
      }

      if (t.type === 'steroids') {
        if (uranus.smtura.steroids.indexOf(t.bp) === -1) uranus.smtura.steroids += `${t.bp}, `;
        if (uranus.smtura.steroids.indexOf(t.bp) > -1) uranus.smtura.treatmed = '1';
        uranus.pmtera.bodyparts[t.bp][t.type].push(t.frequency.replace(t.bp, 'steroids'));
        uranus.pmtera.rfa[t.type].reqtreat = 'Steroids for the ';
        uranus.pmtera.rfa[t.type].a = '99070';
        uranus.pmtera.rfa[t.type].b.push({ ordinal: 1200, bp: t.bp, freq: `${t.frequency}\n\n` });
      }

      return t;
    });
  }

  medFields.map(mf => {
    if (mf !== 'treatmisc') {
      if (uranus.smtura[mf] !== '' && uranus.pmtera.rfa[mf]) {
        // appending body part after the medication
        // uranus.pmtera.rfa[mf].reqtreat += `${mEnd(uranus.smtura[mf].toLowerCase())}. `;
      }
    }
    return mf;
  });

  return uranus;
};

export const loadReqTreatStoragex = (pantera, tpr) => {
  const uranus = clearReqTreatStorages();
  const provider = JSON.parse(sessionStorage.getItem('provider'));
  /*
  console.log('[LOAD REQ ptra] ', pantera);
  console.log('[LOAD REQ tpr] ', tpr);
  console.log('[LOAD REQ uns] ', uranus);
  */
  tpr.map(tp => {
    if (!uranus.ptera.rfa[tp.type]) {
      uranus.ptera.rfa[tp.type] = {};
      uranus.ptera.rfa[tp.type].b = [];
    }

    if (!uranus.ptera.bodyparts[tp.bp]) {
      uranus.ptera.bodyparts[tp.bp] = {};
    }

    if (!uranus.ptera.bodyparts[tp.bp][tp.type]) {
      uranus.ptera.bodyparts[tp.bp][tp.type] = [];
    }

    // SUPPLIES
    if (tp.type === 'treathot') {
      if (uranus.stura.treathot.indexOf(tp.bp) === -1) uranus.stura.treathot += `${tp.bp}, `;
      if (uranus.stura.treathot.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'HOT PACK'));

      uranus.ptera.rfa[tp.type].reqtreat = 'Hot pack for home use for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatcold') {
      if (uranus.stura.treatcold.indexOf(tp.bp) === -1) uranus.stura.treatcold += `${tp.bp}, `;
      if (uranus.stura.treatcold.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'COLD PACK'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Cold pack for home use';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treattens') {
      if (uranus.stura.treattens.indexOf(tp.bp) === -1) uranus.stura.treattens += `${tp.bp}, `;
      if (uranus.stura.treattens.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'TENS UNIT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Tens Unit for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatsling') {
      if (uranus.stura.treatsling.indexOf(tp.bp) === -1) uranus.stura.treatsling += `${tp.bp}, `;
      if (uranus.stura.treatsling.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'SLING SUPPORT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Sling support for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatsplint') {
      if (uranus.stura.treatsplint.indexOf(tp.bp) === -1) uranus.stura.treatsplint += `${tp.bp}, `;
      if (uranus.stura.treatsplint.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'SPLINT SUPPORT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Splint support for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatbraces') {
      if (uranus.stura.treatbraces.indexOf(tp.bp) === -1) uranus.stura.treatbraces += `${tp.bp}, `;
      if (uranus.stura.treatbraces.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'BRACES'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Braces support for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatcane') {
      if (uranus.stura.treatcane.indexOf(tp.bp) === -1) uranus.stura.treatcane += `${tp.bp}, `;
      if (uranus.stura.treatcane.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'CANE'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Cane support for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatcrutches') {
      if (uranus.stura.treatcrutches.indexOf(tp.bp) === -1) uranus.stura.treatcrutches += `${tp.bp}, `;
      if (uranus.stura.treatcrutches.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'CRUTCHES'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Crutches support for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treathek') {
      if (uranus.stura.treathek.indexOf(tp.bp) === -1) uranus.stura.treathek += `${tp.bp}, `;
      if (uranus.stura.treathek.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'HOME EXERCISE KIT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Home Exercise Kit support for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatifunit') {
      if (uranus.stura.treatifunit.indexOf(tp.bp) === -1) uranus.stura.treatifunit += `${tp.bp}, `;
      if (uranus.stura.treatifunit.indexOf(tp.bp) > -1) uranus.stura.treatsupp = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'IF UNIT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'IF unit support for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatchronic') {
      if (uranus.stura.treatchronic.indexOf(tp.bp) === -1) uranus.stura.treatchronic += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'CHRONIC PAIN MGMT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Chronic pain management for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatfrp') {
      if (uranus.stura.treatfrp.indexOf(tp.bp) === -1) uranus.stura.treatfrp += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'FUNCTIONAL RESTORATION PROGRAM'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Functional restoration program for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatfre') {
      if (uranus.stura.treatfre.indexOf(tp.bp) === -1) uranus.stura.treatfre += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'FUNCTIONAL RESTORATION EVALUATION'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Functional restoration evaluation only to determine if patient is entitled to the program for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatbiofeed') {
      if (uranus.stura.treatbiofeed.indexOf(tp.bp) === -1) uranus.stura.treatbiofeed += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'BIOFEEDBACK'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Biofeedback for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatctscan') {
      if (uranus.stura.treatctscan.indexOf(tp.bp) === -1) uranus.stura.treatctscan += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'CT SCAN'));
      uranus.ptera.rfa[tp.type].reqtreat = 'CT scan for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatmultidisc') {
      if (uranus.stura.treatmultidisc.indexOf(tp.bp) === -1) uranus.stura.treatmultidisc += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'MULTIDISCIPLINARY'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Multidisciplinary for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatfce') {
      if (uranus.stura.treatfce.indexOf(tp.bp) === -1) uranus.stura.treatfce += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'FUNCTIONAL CAPACITY EVALUATION'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Functional Capacity Evaluation for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatmri') {
      if (uranus.stura.treatmri.indexOf(tp.bp) === -1) uranus.stura.treatmri += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'MRI'));
      uranus.ptera.rfa[tp.type].reqtreat = 'MRI for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatemg') {
      if (uranus.stura.treatemg.indexOf(tp.bp) === -1) uranus.stura.treatemg += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'EMG/NCV'));
      uranus.ptera.rfa[tp.type].reqtreat = 'EMG/NCV for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatacu') {
      if (uranus.stura.treatacu.indexOf(tp.bp) === -1) uranus.stura.treatacu += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'ACU TRIAL'));
      // uranus.ptera.rfa[tp.type].reqtreat = 'ACU Trial for the ';
      uranus.ptera.rfa[tp.type].reqtreat = 'Acupuncture Trial for the ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 97813;97814;97140;97026; 97110; 97530 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatphymed') {
      if (uranus.stura.treatphymed.indexOf(tp.bp) === -1) uranus.stura.treatphymed += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'PT TRIAL'));
      // uranus.ptera.rfa[tp.type].reqtreat = 'PT Trial for the ';
      uranus.ptera.rfa[tp.type].reqtreat = 'Physical Therapy Trial for the ';
      uranus.ptera.rfa[tp.type].a = '97161-97163; 97012-97799 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatchiro') {
      if (uranus.stura.treatchiro.indexOf(tp.bp) === -1) uranus.stura.treatchiro += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'CHIRO TRIAL'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Chiropractic Therapy with Adjunct Manual Therapy and Infrared for the ';
      uranus.ptera.rfa[tp.type].a = '99201-99205;98940-98943; 97012-97799 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatfim') {
      if (uranus.stura.treatfim.indexOf(tp.bp) === -1) uranus.stura.treatfim = 'TREAT';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'FUNCTIONAL IMPROVEMENT MEASUREMENT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Functional Improvement Measures (Neuromuscular) for the';
      uranus.ptera.rfa[tp.type].a = '95999 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatinternal') {
      if (uranus.stura.treatinternal.indexOf(tp.bp) === -1) uranus.stura.treatinternal = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'INTERNAL MEDICINE'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Internal Medicine Evaluation and Treatment, if necessary. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 30, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatpsyche') {
      // if (uranus.stura.treatpsyche.indexOf(tp.bp) === -1)
      // uranus.stura.treatpsyche = 'TREAT';
      if (uranus.stura.treatpsyche.indexOf(tp.bp) === -1) uranus.stura.treatpsyche += `${tp.bp}, `;

      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'PSYCHE'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Psyche Evaluation and Treatment if necessary, for the ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 40, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatortho') {
      if (uranus.stura.treatortho.indexOf(tp.bp) === -1) uranus.stura.treatortho += `${tp.bp}, `;
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'ORTHOPEDICS'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Orthopedic Evaluation and Treatment for the ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 50, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatneuro') {
      uranus.stura.treatneuro = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'NEURO'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Neurology Evaluation and Treatment, if necessary. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 60, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatopthal') {
      uranus.stura.treatopthal = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'OPHTHALMOLOGIST'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Ophthalmology Evaluation and Treatment, if necessary. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 70, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatcpharma') {
      uranus.stura.treatcpharma = 'TREAT';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'CONTINUED PHARMACOLOGICAL MGMT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Pharmacological Management Re-evaluation and Treatment for the ';
      uranus.ptera.rfa[tp.type].a = '99211-99215; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 80, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatpodia') {
      uranus.stura.treatpodia = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'PODIATRY'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Podiatry Evaluation and Treatment if necessary, for the ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 90, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatrheuma') {
      uranus.stura.treatrheuma = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'RHEUMATOLOGY'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Rheumatology Evaluation and Treatment, if necessary. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 100, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatpulmo') {
      uranus.stura.treatpulmo = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'PULMONOLOGY'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Pulmonology Evaluation and Treatment, if necessary. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 110, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatderma') {
      uranus.stura.treatderma = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'DERMATOLOGY'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Dermatology Evaluation and Treatment, if necessary. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 120, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatcardio') {
      uranus.stura.treatcardio = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'CARDIOLOGY'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Cardiology Evaluation and Treatment, if necessary. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 120, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatent') {
      uranus.stura.treatent = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'ENT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'ENT Evaluation and Treatment, if necessary. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 130, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatgensurg') {
      uranus.stura.treatgensurg = 'TREAT';
      uranus.stura.treatsf = '1';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'GENERAL SURGEON'));
      uranus.ptera.rfa[tp.type].reqtreat = 'General Surgeon';
      uranus.ptera.rfa[tp.type].a = 'PENDING ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: 110, bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatxray') {
      if (uranus.stura.treatxray.indexOf(tp.bp) === -1) uranus.stura.treatxray += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'XRAY'));
      uranus.ptera.rfa[tp.type].reqtreat = 'X-ray for the ';
      uranus.ptera.rfa[tp.type].a = '';
      // DEDE
      // pdiag[diagBodyPart[tp.bp]]
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatfmc') {
      uranus.stura.treatfmc = 'TREAT';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'FUTURE MEDICAL CARE'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Follow-Up Visit per Future Medical Care. ';
      uranus.ptera.rfa[tp.type].a = '99211-99215; 99354-99355; 99358-99359; WC002 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency.replace('provname', provider.provname)}\n\n` });
    }
    if (tp.type === 'treatiniteval') {
      uranus.stura.treatiniteval = 'TREAT';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'INITIAL EVALUATION'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Primary Treating Physician Evaluation. ';
      uranus.ptera.rfa[tp.type].a = '99201-99205; 99354-99355; 99358-99359 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatpharma') {
      uranus.stura.treatpharma = 'TREAT';
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'PHARMACOLOGICAL MGMT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Pharmacological Management Evaluation and Treatment for the ';
      uranus.ptera.rfa[tp.type].a = '99201-99205 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatxpt') {
      if (uranus.stura.treatxpt.indexOf(tp.bp) === -1) uranus.stura.treatxpt += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'PT EXTENDED'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Extended Physical Therapy for the ';
      uranus.ptera.rfa[tp.type].a = '97164; 97012-97799 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatot') {
      if (uranus.stura.treatot.indexOf(tp.bp) === -1) uranus.stura.treatot += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'OT TRIAL'));
      // uranus.ptera.rfa[tp.type].reqtreat = 'OT Trial for the ';
      uranus.ptera.rfa[tp.type].reqtreat = 'Occupational Therapy Trial for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatxot') {
      if (uranus.stura.treatxot.indexOf(tp.bp) === -1) uranus.stura.treatxot += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'OT EXTENDED'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Extended Occupational Therapy for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatxacu') {
      if (uranus.stura.treatxacu.indexOf(tp.bp) === -1) uranus.stura.treatxacu += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'ACU EXTENDED'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Extended E-Acupuncture regimen for the ';
      uranus.ptera.rfa[tp.type].a = '99211-99215; 97813;97814;97026;97140;97110;97530 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatxchiro') {
      if (uranus.stura.treatxchiro.indexOf(tp.bp) === -1) uranus.stura.treatxchiro += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'CHIRO EXTENDED'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Continued Chiropractic regimen for the ';
      uranus.ptera.rfa[tp.type].a = '99211-99215; 98940-98943; 97012-97799 ';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatexer') {
      if (uranus.stura.treatexer.indexOf(tp.bp) === -1) uranus.stura.treatexer += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'EXERCISE'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Therapeutic Exercise/Activities for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatipm') {
      if (uranus.stura.treatipm.indexOf(tp.bp) === -1) uranus.stura.treatipm += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'INTERVENTIONAL PAIN MGMT'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Interventional pain management of the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatinj') {
      if (uranus.stura.treatinj.indexOf(tp.bp) === -1) uranus.stura.treatinj += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'INJECTION'));
      uranus.ptera.rfa[tp.type].reqtreat = 'Injection for the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }
    if (tp.type === 'treatsurg') {
      if (uranus.stura.treatsurg.indexOf(tp.bp) === -1) uranus.stura.treatsurg += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push(tp.frequency.replace(tp.bp, 'SURGERY'));
      /*
      if (pantera.initial.pcode === 'mah') {

      } else {

      }
*/
      uranus.ptera.rfa[tp.type].reqtreat = 'Surgery of the ';
      uranus.ptera.rfa[tp.type].a = '';
      uranus.ptera.rfa[tp.type].b.push({ ordinal: sortRef[tp.bp], bp: tp.bp, freq: `${tp.frequency}\n\n` });
    }

    if (tp.type === 'treatsupp') {
      if (uranus.stura.treatsupp.indexOf(tp.bp) === -1) uranus.stura.treatsupp += `${tp.bp}, `;
      uranus.ptera.bodyparts[tp.bp][tp.type].push('TREAT');
      // toastr.info('Added ' + uranus.ptera.toastr[tp.type] + ' of the ' + tp.bp);
    }
    if (tp.type === 'treatmisc') {
      //uranus.stura.treatmisc += `${tp.bp}, `;
      uranus.stura.treatmisc.bp = tp.bp;
      uranus.stura.treatmisc.items.push(tp.misc1);
      uranus.stura.treatmisc.items.push(tp.misc2);
      uranus.stura.treatmisc.items.push(tp.misc3);
      uranus.ptera.bodyparts[tp.bp][tp.type].push(uranus.stura.treatmisc.toString());
      // toastr.info('Added ' + tp.misc1);
      // toastr.info('Added ' + tp.misc2);
      // toastr.info('Added ' + tp.misc3);
    }
    return tp;
  });

  return uranus;
};
