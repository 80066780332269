// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch patient list
 * @param {*} payload
 */
export async function fetchPatientList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`patient?${params}`);
}

/**
 * get patient
 * @param {*} payload
 */

export async function fetchPatient(payload) {
  return GET(`patient/${payload}`);
}

/**
 * update patient by id
 * @param {*} payload
 */
export async function updatePatientById(payload) {
  const { id, ...rest } = payload;
  return PUT(`patient/${id}`, rest);
}

/**
 * remove patient by id
 * @param {*} payload
 */
export async function removePatientById(payload) {
  return DELETE(`patient/${payload}`);
}

/**
 * remove patient by id
 * @param {*} payload
 */
export async function removePatientByIds(payload) {
  return DELETE(`patient?ids=${payload}`);
}

/**
 * create patient
 * @param {*} payload
 */
export async function createPatient(payload) {
  return POST(`patient`, payload);
}
