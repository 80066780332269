import {
  fetchJobdescList,
  fetchJobdesc,
  updateJobdescById,
  removeJobdescById,
  createJobdesc
} from '@service/api/jobdescription';

// types
import * as types from '../types';

export const getJobdescList = (payload) => (dispatch) => {
  return fetchJobdescList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_JOB_DESC,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeJobdesc = (payload) => () => {
  return removeJobdescById(payload).then((res) => {
    return res;
  });
};

export const addJobdesc = (payload) => () => {
  return createJobdesc(payload).then((res) => {
    return res;
  });
};

export const getJobdesc = (payload) => () => {
  return fetchJobdesc(payload).then((res) => {
    return res;
  });
};

export const updateJobdesc = (payload) => async () => {
  const res = await updateJobdescById(payload);
  return res;
};
