// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch psneck list
 * @param {*} payload
 */
export async function fetchPsneckList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`psneck?${params}`);
}

/**
 * get psneck
 * @param {*} payload
 */

export async function fetchPsneck(payload) {
  return GET(`psneck/${payload}`);
}

/**
 * update psneck by id
 * @param {*} payload
 */
export async function updatePsneckById(payload) {
  const { id, ...rest } = payload;
  return PUT(`psneck/${id}`, rest);
}

/**
 * remove psneck by id
 * @param {*} payload
 */
export async function removePsneckById(payload) {
  return DELETE(`psneck/${payload}`);
}

/**
 * create psneck
 * @param {*} payload
 */
export async function createPsneck(payload) {
  return POST(`psneck`, payload);
}
