import {
  fetchSuperbillList,
  fetchSuperbill,
  updateSuperbillById,
  removeSuperbillById,
  createSuperbill
} from '@service/api/superbill';

// types
import * as types from '../types';

export const getSuperbillList = (payload) => async (dispatch) => {
  const res = await fetchSuperbillList(payload);
  if (res.success) {
    dispatch({
      type: types.SET_SUPAH_BILL,
      payload: res.data.docs,
    });
  }
  return res;
};

export const removeSuperbill = (payload) => () => {
  return removeSuperbillById(payload).then((res) => {
    return res;
  });
};

export const addSuperbill = (payload) => () => {
  return createSuperbill(payload).then((res) => {
    return res;
  });
};

export const getSuperbill = (payload) => () => {
  return fetchSuperbill(payload).then((res) => {
    return res;
  });
};

export const updateSuperbill = (payload) => () => {
  return updateSuperbillById(payload).then((res) => {
    return res;
  });
};
