import {
  fetchWristList,
  fetchWrist,
  updateWristById,
  removeWristById,
  createWrist
} from '@service/api/wrist';

// types
import * as types from '../types';

export const getWristList = (payload) => async(dispatch) => {
  return fetchWristList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_WRIST,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeWrist = (payload) => () => {
  return removeWristById(payload).then((res) => {
    return res;
  });
};

export const addWrist = (payload) => () => {
  return createWrist(payload).then((res) => {
    return res;
  });
};

export const getWrist = (payload) => () => {
  return fetchWrist(payload).then((res) => {
    return res;
  });
};

export const updateWrist = (payload) => () => {
  return updateWristById(payload).then((res) => {
    return res;
  });
};
