// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch patientmed list
 * @param {*} payload
 */
export async function fetchPatientmedList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`patientmed?${params}`);
}

/**
 * get patientmed
 * @param {*} payload
 */

export async function fetchPatientmed(payload) {
  return GET(`patientmed/${payload}`);
}

/**
 * update patientmed by id
 * @param {*} payload
 */
export async function updatePatientmedById(payload) {
  const { id, ...rest } = payload;
  return PUT(`patientmed/${id}`, rest);
}

/**
 * remove patientmed by id
 * @param {*} payload
 */
export async function removePatientmedById(payload) {
  return DELETE(`patientmed/${payload}`);
}

/**
 * remove patientmed by id
 * @param {*} payload
 */
export async function removePatientmedByPatientId(payload) {
  const params = methods.convertQueryString(payload);
  return DELETE(`patientmed?${params}`);
}

/**
 * create patientmed
 * @param {*} payload
 */
export async function createPatientmed(payload) {
  return POST(`patientmed`, payload);
}
