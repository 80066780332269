import * as types from '../types';

const initialState = {
  tplan: undefined,
  activeMtusTp: '',
  activeMtusBp: '',
  selectedMtusBp: [],
  selectedMtus: [],
  deletedMtus: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TREAT_PLAN:
      return {
        ...state,
        tplan: action.payload,
      };
    case types.SET_ACTIVE_MTUS_TP:
      return {
        ...state,
        activeMtusTp: action.payload,
      };
    case types.SET_ACTIVE_MTUS_BP:
      return {
        ...state,
        activeMtusBp: action.payload,
      };
    case types.SET_SELECTED_MTUS:
      return {
        ...state,
        selectedMtus: action.payload,
      };
    case types.SET_DELETED_MTUS:
      return {
        ...state,
        deletedMtus: action.payload,
      };      
    default:
      return state;
  }
};

export default reducer;
