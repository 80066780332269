// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch foot list
 * @param {*} payload
 */
export async function fetchFootList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`foot?${params}`);
}

/**
 * get foot
 * @param {*} payload
 */

export async function fetchFoot(payload) {
  return GET(`foot/${payload}`);
}

/**
 * update foot by id
 * @param {*} payload
 */
export async function updateFootById(payload) {
  const { id, ...rest } = payload;
  return PUT(`foot/${id}`, rest);
}

/**
 * remove foot by id
 * @param {*} payload
 */
export async function removeFootById(payload) {
  return DELETE(`foot/${payload}`);
}

/**
 * create foot
 * @param {*} payload
 */
export async function createFoot(payload) {
  return POST(`foot`, payload);
}
