import {
  fetchElbowsList,
  fetchElbows,
  updateElbowsById,
  removeElbowsById,
  createElbows
} from '@service/api/elbows';

// types
import * as types from '../types';

export const getElbowsList = (payload) => async (dispatch) => {
  return fetchElbowsList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_ELBOWS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeElbows = (payload) => () => {
  return removeElbowsById(payload).then((res) => {
    return res;
  });
};

export const addElbows = (payload) => () => {
  return createElbows(payload).then((res) => {
    return res;
  });
};

export const getElbows = (payload) => () => {
  return fetchElbows(payload).then((res) => {
    return res;
  });
};

export const updateElbows = (payload) => () => {
  return updateElbowsById(payload).then((res) => {
    return res;
  });
};
