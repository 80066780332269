import {
  fetchVitalsignList,
  fetchVitalsign,
  updateVitalsignById,
  removeVitalsignById,
  createVitalsign
} from '@service/api/vitalsign';

// types
import * as types from '../types';

export const getVitalsignList = (payload) => async (dispatch) => {
  return fetchVitalsignList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_VITAL_SIGNS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeVitalsign = (payload) => () => {
  return removeVitalsignById(payload).then((res) => {
    return res;
  });
};

export const addVitalsign = (payload) => () => {
  return createVitalsign(payload).then((res) => {
    return res;
  });
};

export const getVitalsign = (payload) => () => {
  return fetchVitalsign(payload).then((res) => {
    return res;
  });
};

export const updateVitalsign = (payload) => () => {
  return updateVitalsignById(payload).then((res) => {
    return res;
  });
};
