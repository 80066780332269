import {
  fetchHipthighList,
  fetchHipthigh,
  updateHipthighById,
  removeHipthighById,
  createHipthigh
} from '@service/api/hipthigh';

// types
import * as types from '../types';

export const getHipthighList = (payload) => async (dispatch) => {
  return fetchHipthighList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_HIP_AND_PELVIS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeHipthigh = (payload) => () => {
  return removeHipthighById(payload).then((res) => {
    return res;
  });
};

export const addHipthigh = (payload) => () => {
  return createHipthigh(payload).then((res) => {
    return res;
  });
};

export const getHipthigh = (payload) => () => {
  return fetchHipthigh(payload).then((res) => {
    return res;
  });
};

export const updateHipthigh = (payload) => () => {
  return updateHipthighById(payload).then((res) => {
    return res;
  });
};
