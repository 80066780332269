import React from 'react';

// antd icons
import { CoffeeOutlined, LaptopOutlined, MedicineBoxOutlined, HistoryOutlined, ProfileOutlined } from '@ant-design/icons';

// routes
import * as historian from '@modules/historian';
import * as doctor from '@modules/doctor';
import * as welcome from '@modules/welcome';
import * as patient from '@modules/patient';
import * as existing from '@modules/existing';
import * as forms from '@modules/forms';
import * as admin from '@modules/admin';
// import * as acupuncture from '@modules/acupuncture';

export const adminConfig = [
  {
    key: 'admin',
    title: 'Admin',
    icon: <LaptopOutlined />,
    subRoute: [{
      key: '/admin/patients-admin',
      title: 'All Patients',
      component: admin.patientsadmin,
    }, {
      key: '/admin/user-location',
      title: 'Analytics',
      component: admin.vserlocation,
    }, {
      key: '/admin/diagnosis',
      title: 'Diagnosis',
      component: admin.diagnosis,
    }, {
      key: '/admin/medication',
      title: 'Medication',
      component: admin.medication,
    }, {
      key: '/admin/vsers',
      title: 'Users',
      component: admin.vsers,
    }, {
      key: '/admin/templates',
      title: 'Templates',
      component: admin.templates,
    }, {
      key: '/admin/poffice',
      title: 'Provider Address',
      component: admin.poffice,
    }, {
      key: '/admin/procedurecode',
      title: 'Procedure Code',
      component: admin.procedurecode,
    }, {
      key: '/admin/tplan',
      title: 'Treatment Plan',
      subRoute: [{
        key: '/admin/tplanadmin',
        title: 'Narratives',
        component: admin.treatmentplan,
      }, {
        key: '/admin/tplancode',
        title: 'T Plan Codes',
        component: admin.treatcode,
      }]
    }],
  }
];

export const afterLoginConfig = [
  {
    key: '/welcome',
    title: 'Welcome',
    icon: <LaptopOutlined />,
  component: welcome.dashboard,
    subRoute: [],
  }
];

export const newPatientConfig = [
  {
    key: 'patient',
    title: 'Patient',
    icon: <HistoryOutlined />,
    subRoute: [
      {
        key: '/patient/new',
        title: 'New Patient',
        component: patient.newpatient,
      },
      {
        key: '/patient/caseref',
        title: 'Case Referral',
        component: patient.caseref,
      },
      {
        key: '/patient/preauth',
        title: 'Pre-authorization',
        component: patient.preauth,
      },
      {
        key: '/patient/initialdemo',
        title: 'Initial Patient Demographics',
        component: historian.patientdemo,
      }],
    }
];

export const existingPatientConfig = [
  {
    key: 'existing',
    title: 'Existing',
    icon: <ProfileOutlined />,
    subRoute: [
      {
        key: '/existing/patients',
        title: 'Patient Listing',
        component: existing.patients,
      },
    ],
  },
];

export const menuConfig = [
  {
    key: 'historian',
    title: 'Historian',
    icon: <HistoryOutlined />,
    subRoute: [
      {
        key: '/historian/patientdemo',
        title: 'Patient Demographics',
        component: historian.patientdemo,
        subRoute: [],
      },
      {
        key: '/historian/preauth',
        title: 'Pre-authorization',
        component: historian.preauth,
        subRoute: [],
      },
      {
        key: '/historian/funcimp',
        title: 'Functional Improvement',
        component: historian.funcimp,
        subRoute: [],
      },
      {
        key: '/historian/jobdesc',
        title: 'Job Description',
        component: historian.jobdesc,
        subRoute: [],
      },
      {
        key: '/historian/pscomplaints',
        title: 'Complaints',
        component: historian.pscomplaints,
        subRoute: [],
      },
      {
        key: '/historian/pastmedicalhistory',
        title: 'Past Medical Hx',
        component: historian.pastmedicalhistory,
        subRoute: [],
      },
      {
        key: '/historian/pastrelatedmedicalhistory',
        title: 'Past Related Medical Hx',
        component: historian.pastrelatedmedicalhistory,
        subRoute: [],
      },
      {
/*
        key: '/historian/dinj',
        title: 'Date of Injuries',
        component: historian.dateofinjuries,
        subRoute: [],
      },
      {
*/
        key: '/historian/omedhx',
        title: 'Other Medical Hx',
        component: historian.othermedicalhx,
        subRoute: [],
      },
      {
        key: '/historian/specacc',
        title: 'Specific Accident',
        component: historian.specificaccident,
        subRoute: [],
      },
      {
        key: '/historian/ctrauma',
        title: 'Cumulative Trauma',
        component: historian.cumulativetrauma,
        subRoute: [],
      },
      {
        key: '/historian/mpn',
        title: 'MPN',
        component: historian.mpn,
        subRoute: [],
      },
    ],
  },
  {
    key: 'doctor',
    title: 'Doctor',
    icon: <CoffeeOutlined />,
    subRoute: [
      {
        key: '/doctor/diagnosis',
        title: 'Diagnosis',
        component: doctor.diagnosis,
        subRoute: [],
      },
      {
        key: '/doctor/recrev',
        title: 'Upload Records Review',
        component: doctor.recrev,
        subRoute: [],
      },
      {
        key: '/doctor/pexam',
        title: 'Physical Examination',
        component: doctor.physicalexamination,
        subRoute: [],
      },
      {
        key: '/doctor/mil',
        title: 'MIL Evaluation',
        component: doctor.mil,
        subRoute: [],
      },
      {
        key: '/doctor/superbill',
        title: 'Superbills',
        component: doctor.superbills,
        subRoute: [],
      },
      {
        key: '/doctor/wstatus',
        title: 'Work Status',
        component: doctor.workstatus,
        subRoute: [],
      },
      {
        key: '/doctor/treatmentplan',
        title: 'Treatment Plan',
        component: doctor.treatmentplan,
        subRoute: [],
      },
      {
        key: '/doctor/billreduc',
        title: 'Bill Reduction',
        component: doctor.billreduc,
        subRoute: [],
      },
    ],
  },
  {
    key: 'forms',
    title: 'Forms',
    icon: <ProfileOutlined />,
    subRoute: [
      {
        key: '/forms/reports',
        title: 'Reports',
        component: forms.reports,
        subRoute: [],
      },
      {
        key: '/forms/misc',
        title: 'Misc Forms',
        component: forms.misc,
        subRoute: [],
      },
    ],
  },
];

/*
export const alphaConfig = [
  {
    key: '/welcome',
    title: 'Welcome',
    icon: <LaptopOutlined />,
    component: welcome.dashboard,
    subRoute: [],
  },
  {
    key: 'patient',
    title: 'Patient',
    icon: <HistoryOutlined />,
    subRoute: [
      {
        key: '/patient/new',
        title: 'New Patient',
        component: patient.newpatient,
      },
      {
        key: '/patient/caseref',
        title: 'Case Referral',
        component: patient.caseref,
      },
      {
        key: '/patient/preauth',
        title: 'Pre-authorization',
        component: patient.preauth,
      },
      {
        key: '/patient/initialdemo',
        title: 'Initial Patient Demographics',
        component: historian.patientdemo,
      }
    ],
  },
  {
      key: 'existing',
      title: 'Existing',
      icon: <ProfileOutlined />,
      subRoute: [
        {
          key: '/existing/patients',
          title: 'Patient Listing',
          component: existing.patients,
        },
      ],
  },
  {
    key: 'historian',
    title: 'Historian',
    icon: <HistoryOutlined />,
    subRoute: [
      {
        key: '/historian/patientdemo',
        title: 'Patient Demographics',
        component: historian.patientdemo,
      },
      {
        key: '/historian/funcimp',
        title: 'Functional Improvement',
        component: historian.funcimp  ,
      },
      {
        key: '/historian/jobdesc',
        title: 'Job Description',
        component: historian.jobdesc,
      },
      {
        key: '/historian/pastmedicalhistory',
        title: 'Past Medical Hx',
        component: historian.pastmedicalhistory,
      },
      {
        key: '/historian/pastrelatedmedicalhistory',
        title: 'Past Related Medical Hx',
        component: historian.pastrelatedmedicalhistory,
      },
      {
        key: '/historian/dinj',
        title: 'Date of Injuries',
        component: historian.dateofinjuries,
      },
      {
        key: '/historian/omedhx',
        title: 'Other Medical Hx',
      },
      {
        key: '/historian/specacc',
        title: 'Specific Accident',
        component: historian.specificaccident,
      },
      {
        key: '/historian/ctrauma',
        title: 'Cumulative Trauma',
        component: historian.cumulativetrauma,
      },
      {
        key: '/historian/mpn',
        title: 'MPN',
        component: historian.mpn,
      },
      {
        key: '/historian/complaints',
        title: 'Complaints',
      },
    ],
  },
  {
    key: 'doctor',
    title: 'Doctor',
    icon: <CoffeeOutlined />,
    subRoute: [
      {
        key: '/doctor/diag',
        title: 'Diagnosis',
      },
      {
        key: '/doctor/recrev',
        title: 'Upload Records Review',
        component: doctor.workstatus,
      },
      {
        key: '/doctor/pexam',
        title: 'Physical Examination',
        component: doctor.workstatus,
      },
      {
        key: '/doctor/mil',
        title: 'MIL Evaluation',
        component: doctor.mil,
      },
      {
        key: '/doctor/superbill',
        title: 'Superbills',
        component: doctor.superbills,
      },
      {
        key: '/doctor/wstatus',
        title: 'Work Status',
        component: doctor.workstatus,
      },
      {
        key: '/doctor/tplan',
        title: 'Treatment Plan',
        component: doctor.workstatus,
      }
    ],
  },
  {
    key: 'forms',
    title: 'Forms',
    icon: <ProfileOutlined />,
    subRoute: [
      {
        key: '/forms/reports',
        title: 'Reports',
      },
      {
        key: '/forms/misc',
        title: 'Misc Forms',
      },
    ],
  },
  {
    key: 'examination',
    title: 'Examination',
    icon: <ProfileOutlined />,
    subRoute: [
      {
        key: '/examination/cervicalspine',
        title: 'Cervical Spine',
        component: examination.cervicalspine,
      },
    ],
  }
];
*/
