// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch chestwall list
 * @param {*} payload
 */
export async function fetchChestwallList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`chestwall?${params}`);
}

/**
 * get chestwall
 * @param {*} payload
 */

export async function fetchChestwall(payload) {
  return GET(`chestwall/${payload}`);
}

/**
 * update chestwall by id
 * @param {*} payload
 */
export async function updateChestwallById(payload) {
  const { id, ...rest } = payload;
  return PUT(`chestwall/${id}`, rest);
}

/**
 * remove chestwall by id
 * @param {*} payload
 */
export async function removeChestwallById(payload) {
  return DELETE(`chestwall/${payload}`);
}

/**
 * create chestwall
 * @param {*} payload
 */
export async function createChestwall(payload) {
  return POST(`chestwall`, payload);
}
