// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch workstatus list
 * @param {*} payload
 */
export async function fetchWorkstatusList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`workstatus?${params}`);
}

/**
 * get workstatus
 * @param {*} payload
 */

export async function fetchWorkstatus(payload) {
  return GET(`workstatus/${payload}`);
}

/**
 * update workstatus by id
 * @param {*} payload
 */
export async function updateWorkstatusById(payload) {
  const { id, ...rest } = payload;
  return PUT(`workstatus/${id}`, rest);
}

/**
 * remove workstatus by id
 * @param {*} payload
 */
export async function removeWorkstatusById(payload) {
  return DELETE(`workstatus/${payload}`);
}

/**
 * create workstatus
 * @param {*} payload
 */
export async function createWorkstatus(payload) {
  return POST(`workstatus`, payload);
}
