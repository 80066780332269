// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch lumbarspine list
 * @param {*} payload
 */
export async function fetchLumbarspineList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`lumbarspine?${params}`);
}

/**
 * get lumbarspine
 * @param {*} payload
 */

export async function fetchLumbarspine(payload) {
  return GET(`lumbarspine/${payload}`);
}

/**
 * update lumbarspine by id
 * @param {*} payload
 */
export async function updateLumbarspineById(payload) {
  const { id, ...rest } = payload;
  return PUT(`lumbarspine/${id}`, rest);
}

/**
 * remove lumbarspine by id
 * @param {*} payload
 */
export async function removeLumbarspineById(payload) {
  return DELETE(`lumbarspine/${payload}`);
}

/**
 * create lumbarspine
 * @param {*} payload
 */
export async function createLumbarspine(payload) {
  return POST(`lumbarspine`, payload);
}
