import {
  fetchPswristList,
  fetchPswrist,
  updatePswristById,
  removePswristById,
  createPswrist
} from '@service/api/pswrist';

// types
import * as types from '../types';

export const getPswristList = (payload) => (dispatch) => {
  return fetchPswristList(payload).then((res) => {
    if (res.success) {
      if (res.data.docs.length > 0) {
        res.data.docs.map(doc => {
          if (doc.lr === 'right') {
            dispatch({
              type: types.SET_PSRWRIST,
              payload: [doc],
            });
          }
          if (doc.lr === 'left') {
            dispatch({
              type: types.SET_PSLWRIST,
              payload: [doc],
            });
          }
          return doc;
        });
      }
    }
    return res;
  });
};

export const removePswrist = (payload) => () => {
  return removePswristById(payload).then((res) => {
    return res;
  });
};

export const addPswrist = (payload) => () => {
  return createPswrist(payload).then((res) => {
    return res;
  });
};

export const getPswrist = (payload) => () => {
  return fetchPswrist(payload).then((res) => {
    return res;
  });
};

export const updatePswrist = (payload) => () => {
  return updatePswristById(payload).then((res) => {
    return res;
  });
};
