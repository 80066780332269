import {
  fetchPshipList,
  fetchPship,
  updatePshipById,
  removePshipById,
  createPship
} from '@service/api/pship';

// types
import * as types from '../types';

export const getPshipList = (payload) => (dispatch) => {
  return fetchPshipList(payload).then((res) => {
    if (res.success) {
      if (res.data.docs.length > 0) {
        res.data.docs.map(doc => {
          if (doc.lr === 'right') {
            dispatch({
              type: types.SET_PSRHIP,
              payload: [doc],
            });
          }
          if (doc.lr === 'left') {
            dispatch({
              type: types.SET_PSLHIP,
              payload: [doc],
            });
          }
          return doc;
        });
      }
    }
    return res;
  });
};

export const removePship = (payload) => () => {
  return removePshipById(payload).then((res) => {
    return res;
  });
};

export const addPship = (payload) => () => {
  return createPship(payload).then((res) => {
    return res;
  });
};

export const getPship = (payload) => () => {
  return fetchPship(payload).then((res) => {
    return res;
  });
};

export const updatePship = (payload) => () => {
  return updatePshipById(payload).then((res) => {
    return res;
  });
};
