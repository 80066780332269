import { fetchWorkstatusList, fetchWorkstatus, updateWorkstatusById, removeWorkstatusById, createWorkstatus } from '@service/api/workstatus';

// types
import * as types from '../types';

export const getWorkstatusList = payload => dispatch => {
  return fetchWorkstatusList(payload).then(res => {
    if (res.success) {
      dispatch({
        type: types.SET_WORKSTATUS,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeWorkstatus = payload => () => {
  return removeWorkstatusById(payload).then(res => {
    return res;
  });
};

export const addWorkstatus = payload => () => {
  return createWorkstatus(payload).then(res => {
    return res;
  });
};

export const getWorkstatus = payload => dispatch => {
  return fetchWorkstatus(payload).then(res => {
    if (res.success) {
      dispatch({
        type: types.SET_WORKSTATUS,
        payload: [res.data],
      });
    }
    return res;
  });
};

export const updateWorkstatus = payload => () => {
  return updateWorkstatusById(payload).then(res => {
    return res;
  });
};
