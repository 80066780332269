// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch billreducitem list
 * @param {*} payload
 */
export async function fetchBillReducItemList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`billreducitem?${params}`);
}

/**
 * get billreducitem
 * @param {*} payload
 */

export async function fetchBillReducItem(payload) {
  return GET(`billreducitem/${payload}`);
}

/**
 * update billreducitem by id
 * @param {*} payload
 */
export async function updateBillReducItemById(payload) {
  const { id, ...rest } = payload;
  return PUT(`billreducitem/${id}`, rest);
}

/**
 * remove billreducitem by id
 * @param {*} payload
 */
export async function removeBillReducItemById(payload) {
  return DELETE(`billreducitem/${payload}`);
}

/**
 * create billreducitem
 * @param {*} payload
 */
export async function createBillReducItem(payload) {
  return POST(`billreducitem`, payload);
}

/**
 * delete billreducitem list by params
 * @param {*} payload
 */
export async function removeBillReducItemList(payload) {
  const params = methods.convertQueryString(payload);
  return DELETE(`billreducitem?${params}`);
}
