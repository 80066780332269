// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch genapp list
 * @param {*} payload
 */
export async function fetchGenappList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`genapp?${params}`);
}

/**
 * get genapp
 * @param {*} payload
 */

export async function fetchGenapp(payload) {
  return GET(`genapp/${payload}`);
}

/**
 * update genapp by id
 * @param {*} payload
 */
export async function updateGenappById(payload) {
  const { id, ...rest } = payload;
  return PUT(`genapp/${id}`, rest);
}

/**
 * remove genapp by id
 * @param {*} payload
 */
export async function removeGenappById(payload) {
  return DELETE(`genapp/${payload}`);
}

/**
 * create genapp
 * @param {*} payload
 */
export async function createGenapp(payload) {
  return POST(`genapp`, payload);
}
