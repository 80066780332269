import { fetchDiagnosisList, fetchDiagnosis, updateDiagnosisById, removeDiagnosisById, createDiagnosis } from '@service/api/diagnosis';

// types
import * as types from '../types';

export const getDiagnosisList = payload => dispatch => {
  console.log('[DIG3]');
  return fetchDiagnosisList(payload).then(res => {
    if (res.success) {
      /*
      dispatch({
        type: types.SET_JOB_DESC,
        payload: res.data.docs,
      });
      */
    }
    return res;
  });
};

export const removeDiagnosis = payload => () => {
  return removeDiagnosisById(payload).then(res => {
    return res;
  });
};

export const addDiagnosis = payload => () => {
  return createDiagnosis(payload).then(res => {
    return res;
  });
};

export const getDiagnosis = payload => () => {
  return fetchDiagnosis(payload).then(res => {
    return res;
  });
};

export const updateDiagnosis = payload => async () => {
  const res = await updateDiagnosisById(payload);
  return res;
};
