import {
  fetchPatientList,
  fetchPatient,
  updatePatientById,
  removePatientById,
  removePatientByIds,
  createPatient,
} from '@service/api/legacy';

// types
// import * as types from '../types';

// export const SET_LEGACY_LIST = 'SET LEGACY LIST';
// export const SET_LEGACY = 'SET LEGACY';

export const getPatientList = (payload) => async () => {
  const res = await fetchPatientList(payload);
  return res;
};

export const removePatient = (payload) => async () => {
  const res = await removePatientById(payload);
  return res;
};

export const batchRemovePatient = (payload) => async () => {
  const res = await removePatientByIds(payload)
  return res;
};

export const addPatient = (payload) => async () => {
  const res = await createPatient(payload);
  return res;
};

export const getPatient = (payload) => async () => {
  const res = await fetchPatient(payload);
  return res;
};

export const updatePatient = (payload) => async () => {
  const res = await updatePatientById(payload);
  return res;
};
