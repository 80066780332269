import {
  fetchThoracicspineList,
  fetchThoracicspine,
  updateThoracicspineById,
  removeThoracicspineById,
  createThoracicspine
} from '@service/api/thoracicspine';

// types
import * as types from '../types';

export const getThoracicspineList = (payload) => async (dispatch) => {
  return fetchThoracicspineList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_THORACIC_SPINE,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeThoracicspine = (payload) => () => {
  return removeThoracicspineById(payload).then((res) => {
    return res;
  });
};

export const addThoracicspine = (payload) => () => {
  return createThoracicspine(payload).then((res) => {
    return res;
  });
};

export const getThoracicspine = (payload) => () => {
  return fetchThoracicspine(payload).then((res) => {
    return res;
  });
};

export const updateThoracicspine = (payload) => () => {
  return updateThoracicspineById(payload).then((res) => {
    return res;
  });
};
