// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch neurologicalexamle list
 * @param {*} payload
 */
export async function fetchNeurologicalexamleList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`neurologicalexamle?${params}`);
}

/**
 * get neurologicalexamle
 * @param {*} payload
 */

export async function fetchNeurologicalexamle(payload) {
  return GET(`neurologicalexamle/${payload}`);
}

/**
 * update neurologicalexamle by id
 * @param {*} payload
 */
export async function updateNeurologicalexamleById(payload) {
  const { id, ...rest } = payload;
  return PUT(`neurologicalexamle/${id}`, rest);
}

/**
 * remove neurologicalexamle by id
 * @param {*} payload
 */
export async function removeNeurologicalexamleById(payload) {
  return DELETE(`neurologicalexamle/${payload}`);
}

/**
 * create neurologicalexamle
 * @param {*} payload
 */
export async function createNeurologicalexamle(payload) {
  return POST(`neurologicalexamle`, payload);
}
