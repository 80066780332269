// api
import {
  fetchPofficeList,
} from '@service/api/poffice';

// types
import * as types from '../types';

export const setProviderOffice = (poffice) => {  
  return {
    type: types.SET_PROVIDER_OFFICE,
    payload: poffice,
  };
};

export const getProviderOffices = (payload) => async (dispatch) => {
  const res = await fetchPofficeList(payload);
  if (res.success) {
    dispatch({
      type: types.SET_PROVIDER_OFFICE_LIST,
      payload: res.data.docs,
    });
  }
  return res;
};
