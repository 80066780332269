import {
  fetchPsotherbodypartList,
  fetchPsotherbodypart,
  updatePsotherbodypartById,
  removePsotherbodypartById,
  createPsotherbodypart
} from '@service/api/psotherbodypart';

// types
import * as types from '../types';

export const getPsotherbodypartList = (payload) => async (dispatch) => {
  return fetchPsotherbodypartList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PSOTHER,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePsotherbodypart = (payload) => () => {
  return removePsotherbodypartById(payload).then((res) => {
    return res;
  });
};

export const addPsotherbodypart = (payload) => () => {
  return createPsotherbodypart(payload).then((res) => {
    return res;
  });
};

export const getPsotherbodypart = (payload) => () => {
  return fetchPsotherbodypart(payload).then((res) => {
    return res;
  });
};

export const updatePsotherbodypart = (payload) => () => {
  return updatePsotherbodypartById(payload).then((res) => {
    return res;
  });
};
