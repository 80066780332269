import {
  fetchHeentList,
  fetchHeent,
  updateHeentById,
  removeHeentById,
  createHeent
} from '@service/api/heent';

// types
import * as types from '../types';

export const getHeentList = (payload) => async (dispatch) => {
  return fetchHeentList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_HEENT,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeHeent = (payload) => () => {
  return removeHeentById(payload).then((res) => {
    return res;
  });
};

export const addHeent = (payload) => () => {
  return createHeent(payload).then((res) => {
    return res;
  });
};

export const getHeent = (payload) => () => {
  return fetchHeent(payload).then((res) => {
    return res;
  });
};

export const updateHeent = (payload) => () => {
  return updateHeentById(payload).then((res) => {
    return res;
  });
};
