import React, { useEffect, useRef, useState } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';

// antd
import { Row, Form, Input, Button } from 'antd';

// icons
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

// redux
import { useDispatch } from 'react-redux';
import { common } from '@redux/combineActions';

// utils
import { shuffle, useDebounce } from '@utils/methods';

// picture
import rptgenLogo from '@images/login/logo-image.jpg';
import rptgenLogo2 from '@images/login/logo-image2.jpg';
import rptgenLogo3 from '@images/login/logo-image3.jpg';
import rptgenLogo4 from '@images/login/logo-image4.jpg';

import rptgenLogoTablet from '@images/login/logo-tablet.png';

// styling
import './index.less';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};

const backgroundImg = shuffle([rptgenLogo, rptgenLogo2, rptgenLogo3, rptgenLogo4])[0];

const Login = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [ht, setHt] = useState('');
  const [hkey, setHkey] = useState('');
  const cRef = useRef(null);

  const onLoad = () => {
    cRef.current.execute();
  };

  const handleSubmit = useDebounce(values => {
    const payload = { ...values, ht, hkey };
    dispatch(common.user.userLogin(payload));
  }, 500);

  const handleFormValuesChange = event => {};

  const handleVerificationSuccess = (token, ekey) => {
    setHt(token);
    setHkey(ekey);
  };

  useEffect(() => {
    return () => {
      cRef.current.resetCaptcha();
    };
  }, []);

  return (
    <>
      <div
        style={{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundImage: `url(${backgroundImg})`,
        }}
        className="login-layout">
        <div className="login-form-layout">
          <img className="login-logo" src={rptgenLogoTablet} alt="Rptgen" />

          <div className="login-form-container">
            <p className="login-header">Please login to access the system.</p>

            <Form //
              className="login-form"
              {...layout}
              form={form}
              initialValues={{ email: '', password: '' }}
              onValuesChange={handleFormValuesChange}
              onFinish={handleSubmit}>
              <Form.Item label="ID / Email" className="login-input" name="email" rules={[{ required: true, message: 'Please input your ID / email!' }]}>
                <Input data-testid="email-fld" />
              </Form.Item>
              <Form.Item label="Password" className="login-input" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
                <Input.Password data-testid="password-fld" iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} placeholder="Enter password" />
              </Form.Item>

              
              <div className="flex-start-start" style={{ padding: '0 41px' }}>
                <HCaptcha //
                  sitekey={process.env.REACT_APP_HC_SKEY}
                  onVerify={handleVerificationSuccess}
                  ref={cRef}
                />
              </div>
             

              <Row className="login-cta">
                <Button data-testid="login-btn" className="login-btn" type="primary" htmlType="submit">
                  <i className="fa fa-lock fa-lg"></i>&nbsp;Sign In
                </Button>
              </Row>
              <Row className="login-captcha">
                <div class="h-captcha" data-sitekey="d0496a7e-c083-44d1-b69c-6e1d76b70526"></div>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <div className="login-footer">[©{new Date().getFullYear()} Rptgen 2.0.1]</div>
      <div className="login-footer2">
        This website is protected by <a href="https://hCaptcha.com/?r=16cbe37fdf06">hCaptcha</a> and its&nbsp;
        <a href="https://www.hcaptcha.com/privacy">Privacy Policy</a> and&nbsp;
        <a href="https://www.hcaptcha.com/terms">Terms of Service</a> apply.
      </div>
    </>
  );
};

export default Login;
