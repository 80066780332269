import * as types from '../types';

const initialState = {
  xsers: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_XSER_LIST:
      return { ...state, xsers: action.payload };
    default:
      return state;
  }
};

export default reducer;
