import React from 'react';

// antd
import { Modal } from 'antd';
import { Spin } from 'antd';

import { SettingFilled } from '@ant-design/icons';

// styling
import './index.less';

const LoadComponent = () => {
  return (
    <>
      <Modal closable={false} centered visible={true} width={300} height={200} header={null} footer={null}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SettingFilled spin style={{ marginRight: 10 }} />
          Loading...
        </div>
      </Modal>

      {/* <Spin
        size="large"
        tip={<p style={{ fontSize: '2em', color: '#000' }}>Loading..</p>}>
        <div className="load-component-container" />
      </Spin> */}
    </>
  );
};

export default LoadComponent;
