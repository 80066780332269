import {
  fetchPatientDoeList,
  fetchPatientDoe,
  updatePatientDoeById,
  removePatientDoeById,
  createPatientDoe,
  executeFollowUpx,
} from '@service/api/patientdoe';

export const executeFollowUp = (payload) => () => {
  return executeFollowUpx(payload).then((res) => {
    return res;
  });
};

export const getPatientDoeList = (payload) => () => {
  return fetchPatientDoeList(payload).then((res) => {
    return res;
  });
};

export const removePatientDoe = (payload) => () => {
  return removePatientDoeById(payload).then((res) => {
    return res;
  });
};

export const addPatientDoe = (payload) => () => {
  return createPatientDoe(payload).then((res) => {
    return res;
  });
};

export const getPatientDoe = (payload) => () => {
  return fetchPatientDoe(payload).then((res) => {
    return res;
  });
};

export const updatePatientDoe = (payload) => () => {
  return updatePatientDoeById(payload).then((res) => {
    return res;
  });
};
