// api
import { signupx, deleteXser, fetchXsers } from '@service/api/accountx';

// types
import * as types from '../types';

export const getXsers = () => async (dispatch) => {
  const resu = await fetchXsers();
  dispatch({
    type: types.SET_XSER_LIST,
    payload: resu,
  });
  return resu;
};

export const userDelete = (payload) => async () => {
  const resu = await deleteXser(payload);
  return resu;
};

export const userSignup = (payload) => async () => {
  const resu = await signupx(payload);
  return resu;
};
