import {
  fetchNeurologicalexamleList,
  fetchNeurologicalexamle,
  updateNeurologicalexamleById,
  removeNeurologicalexamleById,
  createNeurologicalexamle
} from '@service/api/neurologicalexamle';

// types
import * as types from '../types';

export const getNeurologicalexamleList = (payload) => async (dispatch) => {
  return fetchNeurologicalexamleList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_NEURO_EXAM_LOWER_EX,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeNeurologicalexamle = (payload) => () => {
  return removeNeurologicalexamleById(payload).then((res) => {
    return res;
  });
};

export const addNeurologicalexamle = (payload) => () => {
  return createNeurologicalexamle(payload).then((res) => {
    return res;
  });
};

export const getNeurologicalexamle = (payload) => () => {
  return fetchNeurologicalexamle(payload).then((res) => {
    return res;
  });
};

export const updateNeurologicalexamle = (payload) => () => {
  return updateNeurologicalexamleById(payload).then((res) => {
    return res;
  });
};
