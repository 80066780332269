// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch pship list
 * @param {*} payload
 */
export async function fetchPshipList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`pship?${params}`);
}

/**
 * get pship
 * @param {*} payload
 */

export async function fetchPship(payload) {
  return GET(`pship/${payload}`);
}

/**
 * update pship by id
 * @param {*} payload
 */
export async function updatePshipById(payload) {
  const { id, ...rest } = payload;
  return PUT(`pship/${id}`, rest);
}

/**
 * remove pship by id
 * @param {*} payload
 */
export async function removePshipById(payload) {
  return DELETE(`pship/${payload}`);
}

/**
 * create pship
 * @param {*} payload
 */
export async function createPship(payload) {
  return POST(`pship`, payload);
}
