import {
  fetchTreatotherList,
  fetchTreatother,
  updateTreatotherById,
  removeTreatotherById,
  createTreatother,
  removeTreatotherByPatientId,
} from '@service/api/treatother';

// types
import * as types from '../types';

export const getTreatotherList = (payload) => async (dispatch) => {
  return fetchTreatotherList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_TREAT_OTHER,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeTreatother = (payload) => () => {
  return removeTreatotherById(payload).then((res) => {
    return res;
  });
};

export const clearTreatother = (payload) => async () => {
  const res = await removeTreatotherByPatientId(payload);
  return res;
};

export const addTreatother = (payload) => () => {
  return createTreatother(payload).then((res) => {
    return res;
  });
};

export const getTreatother = (payload) => () => {
  return fetchTreatother(payload).then((res) => {
    return res;
  });
};

export const updateTreatother = (payload) => () => {
  return updateTreatotherById(payload).then((res) => {
    return res;
  });
};
