// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch treatother list
 * @param {*} payload
 */
export async function fetchTreatotherList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`treatother?${params}`);
}

/**
 * get treatother
 * @param {*} payload
 */

export async function fetchTreatother(payload) {
  return GET(`treatother/${payload}`);
}

/**
 * update treatother by id
 * @param {*} payload
 */
export async function updateTreatotherById(payload) {
  const { id, ...rest } = payload;
  return PUT(`treatother/${id}`, rest);
}

/**
 * remove treatother by id
 * @param {*} payload
 */
export async function removeTreatotherById(payload) {
  return DELETE(`treatother/${payload}`);
}

/**
 * remove treatother by id
 * @param {*} payload
 */
export async function removeTreatotherByPatientId(payload) {
  const params = methods.convertQueryString(payload);
  return DELETE(`treatother?${params}`);
}

/**
 * create treatother
 * @param {*} payload
 */
export async function createTreatother(payload) {
  return POST(`treatother`, payload);
}
