// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch vsers list
 * @param {*} payload
 */
export async function fetchVserList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`vsers?${params}`);
}

/**
 * get vsers
 * @param {*} payload
 */

export async function fetchVser(payload) {
  return GET(`vsers/${payload}`);
}

/**
 * update vsers by id
 * @param {*} payload
 */
export async function updateVserById(payload) {
  const { id, ...rest } = payload;
  return PUT(`vsers/${id}`, rest);
}

/**
 * remove vsers by ids
 * @param {*} payload
 */
export async function removeVserByIds(payload) {
  return DELETE(`vsers?ids=${payload}`);
}

/**
 * remove vsers by id
 * @param {*} payload
 */
export async function removeVserById(payload) {
  return DELETE(`vsers/${payload}`);
}

/**
 * create vsers
 * @param {*} payload
 */
export async function createVser(payload) {
  return POST(`vsers`, payload);
}
