// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT, POST_FORM_DATA } from '../request';

/**
 * fetch recordreview list
 * @param {*} payload
 */
export async function fetchRecordReviewList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`recordreview?${params}`);
}

/**
 * get recordreview
 * @param {*} payload
 */

export async function fetchRecordReview(payload) {
  return GET(`recordreview/${payload}`);
}

/**
 * update recordreview by id
 * @param {*} payload
 */
export async function updateRecordReviewById(payload) {
  const { id, ...rest } = payload;
  return PUT(`recordreview/${id}`, rest);
}

/**
 * remove recordreview by id
 * @param {*} payload
 */
export async function removeRecordReviewById(payload) {
  return DELETE(`recordreview/${payload}`);
}

/**
 * create recordreview
 * @param {*} payload
 */
export async function createRecordReview(payload) {
  return POST(`recordreview`, payload);
}

/**
 * upload doc
 * @param {*} payload
 */
export async function uploadDocument(params) {
  const { file, examId, numOfPages } = params;
  const obj = new FormData();
  obj.append('docFile', file);
  obj.append('patientid', examId);
  obj.append('fileName', file.name);
  obj.append('numOfPages', numOfPages);
  
  const payload = {
    // headers: { "content-type": "multipart/form-data" },
    data: obj
  };
  return POST_FORM_DATA(`recordreview/upload`, payload);
}
