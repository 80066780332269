// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch wrist list
 * @param {*} payload
 */
export async function fetchWristList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`wrist?${params}`);
}

/**
 * get wrist
 * @param {*} payload
 */

export async function fetchWrist(payload) {
  return GET(`wrist/${payload}`);
}

/**
 * update wrist by id
 * @param {*} payload
 */
export async function updateWristById(payload) {
  const { id, ...rest } = payload;
  return PUT(`wrist/${id}`, rest);
}

/**
 * remove wrist by id
 * @param {*} payload
 */
export async function removeWristById(payload) {
  return DELETE(`wrist/${payload}`);
}

/**
 * create wrist
 * @param {*} payload
 */
export async function createWrist(payload) {
  return POST(`wrist`, payload);
}
