import {
  fetchKneesList,
  fetchKnees,
  updateKneesById,
  removeKneesById,
  createKnees
} from '@service/api/knees';

// types
import * as types from '../types';

export const getKneesList = (payload) => async (dispatch) => {
  return fetchKneesList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_KNEES,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeKnees = (payload) => () => {
  return removeKneesById(payload).then((res) => {
    return res;
  });
};

export const addKnees = (payload) => () => {
  return createKnees(payload).then((res) => {
    return res;
  });
};

export const getKnees = (payload) => () => {
  return fetchKnees(payload).then((res) => {
    return res;
  });
};

export const updateKnees = (payload) => () => {
  return updateKneesById(payload).then((res) => {
    return res;
  });
};
