import {
  fetchPastmedicalhistoryList,
  fetchPastmedicalhistory,
  updatePastmedicalhistoryById,
  removePastmedicalhistoryById,
  createPastmedicalhistory
} from '@service/api/pastmedicalhistory';

// types
import * as types from '../types';

export const setPastMedical = (params) => {
  return {
    type: types.SET_PAST_MEDICAL,
    payload: params,
  };
};

export const getPastmedicalhistoryList = (payload) => (dispatch) => {
  return fetchPastmedicalhistoryList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_PAST_MEDICAL,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removePastmedicalhistory = (payload) => () => {
  return removePastmedicalhistoryById(payload).then((res) => {
    return res;
  });
};

export const addPastmedicalhistory = (payload) => () => {
  return createPastmedicalhistory(payload).then((res) => {
    return res;
  });
};

export const getPastmedicalhistory = (payload) => () => {
  return fetchPastmedicalhistory(payload).then((res) => {
    return res;
  });
};

export const updatePastmedicalhistory = (payload) => () => {
  return updatePastmedicalhistoryById(payload).then((res) => {
    return res;
  });
};
