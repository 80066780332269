import {
  fetchHandsList,
  fetchHands,
  updateHandsById,
  removeHandsById,
  createHands
} from '@service/api/hands';

// types
import * as types from '../types';

export const getHandsList = (payload) => async (dispatch) => {
  return fetchHandsList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_GRIP_STRENGTH,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeHands = (payload) => () => {
  return removeHandsById(payload).then((res) => {
    return res;
  });
};

export const addHands = (payload) => () => {
  return createHands(payload).then((res) => {
    return res;
  });
};

export const getHands = (payload) => () => {
  return fetchHands(payload).then((res) => {
    return res;
  });
};

export const updateHands = (payload) => () => {
  return updateHandsById(payload).then((res) => {
    return res;
  });
};
