// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch neurologicalexamue list
 * @param {*} payload
 */
export async function fetchNeurologicalexamueList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`neurologicalexamue?${params}`);
}

/**
 * get neurologicalexamue
 * @param {*} payload
 */

export async function fetchNeurologicalexamue(payload) {
  return GET(`neurologicalexamue/${payload}`);
}

/**
 * update neurologicalexamue by id
 * @param {*} payload
 */
export async function updateNeurologicalexamueById(payload) {
  const { id, ...rest } = payload;
  return PUT(`neurologicalexamue/${id}`, rest);
}

/**
 * remove neurologicalexamue by id
 * @param {*} payload
 */
export async function removeNeurologicalexamueById(payload) {
  return DELETE(`neurologicalexamue/${payload}`);
}

/**
 * create neurologicalexamue
 * @param {*} payload
 */
export async function createNeurologicalexamue(payload) {
  return POST(`neurologicalexamue`, payload);
}
