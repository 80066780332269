// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

// THIS IS MEASUREMENT: LOWER EX
// NOT PE FEET!!!
// WILL RENAME IN THA FUTURE
////////////////////////////////
////////////////////////////////
////////////////////////////////
////////////////////////////////

/**
 * fetch feet list
 * @param {*} payload
 */
export async function fetchFeetList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`feet?${params}`);
}

/**
 * get feet
 * @param {*} payload
 */

export async function fetchFeet(payload) {
  return GET(`feet/${payload}`);
}

/**
 * update feet by id
 * @param {*} payload
 */
export async function updateFeetById(payload) {
  const { id, ...rest } = payload;
  return PUT(`feet/${id}`, rest);
}

/**
 * remove feet by id
 * @param {*} payload
 */
export async function removeFeetById(payload) {
  return DELETE(`feet/${payload}`);
}

/**
 * create feet
 * @param {*} payload
 */
export async function createFeet(payload) {
  return POST(`feet`, payload);
}
