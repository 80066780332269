import React, { useEffect, useState, Suspense } from 'react';
import { useHistory } from 'react-router-dom';

// antd
import { notification, Button, Drawer, Layout, Menu, Space, Descriptions, Tag } from 'antd';

// antd icons
import { ClockCircleOutlined, IdcardOutlined, UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined, TeamOutlined } from '@ant-design/icons';

// hooks
import useWindowSize from '@hooks/useWindowSize';

// components
// import ConnectionIndicator from '@components/ConnectionIndicator';
// import FormChangesPrompt from '@components/FormChangesPrompt';
import LoadComponent from '@components/LoadComponent';
import ErrorBoundary from '@components/ErrorBoundary';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { common, rptgen } from '@redux/combineActions';

// hooks
import useOpener from '@hooks/useOpener';

// page
import Preview3d from '@pages/PreviewPage';

// utils
import { acuConfig, adminConfig, afterLoginConfig, newPatientConfig, existingPatientConfig, menuConfig } from '@utils/menu-config';
import { clearSessionStorage, convertMomentWithFormat } from '@utils/methods';

// styling
import './index.less';

// picture
import rptgenLogo from '@images/login/logo.png';
import rptgenIco from '@images/cog.png';

const { SubMenu } = Menu;
const { Footer, Header, Sider, Content } = Layout;

const BasicLayout = ({ children }) => {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isOpen: isDrawerOpen, toggleOpen: toggleDrawerOpen } = useOpener();
  const { isOpen: isPatientDrawerOpen, toggleOpen: togglePatientDrawer } = useOpener();
  const { location } = history;
  const [api, contextHolder] = notification.useNotification();

  const { user } = useSelector(state => {
    // console.log('[BASIC LAYOUT STATE] ', state);
    return state.common;
  });
  const { isAdmin } = user;

  const { patient, patientdoe, newPatient, examId } = useSelector(state => state.rptgen.patients);
  const { provider } = useSelector(state => state.rptgen.directwebremote);

  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([location.pathname.split('/')[1]]);

  //     .concat(acuConfig)
  const menuKeys = adminConfig
    .concat(menuConfig)
    .map(menu => menu.key);

  const handleOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (menuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleToggleCollapse = () => setCollapsed(prevState => !prevState);

  const handleLogout = e => {
    e.stopPropagation();
    dispatch(rptgen.directwebremote.setProvider(undefined));
    dispatch(rptgen.patients.setPatient(undefined));
    dispatch(rptgen.patients.setExamId(''));
    dispatch(rptgen.patients.setPatientDoe(undefined));
    dispatch(rptgen.patients.setPatientDemo(undefined));
    dispatch(rptgen.directwebremote.clearPanteraBodyParts());
    dispatch(rptgen.directwebremote.clearDwr());
    clearSessionStorage();
    dispatch(common.user.userLogout());
  };

  const handleChangePatient = () => {
    togglePatientDrawer();
    dispatch(rptgen.patients.setPatient(undefined));
    dispatch(rptgen.patients.setExamId(''));
    dispatch(rptgen.patients.setPatientDoe(undefined));
    dispatch(rptgen.patients.setPatientDemo(undefined));
    dispatch(rptgen.patients.setPreauth(undefined));
    dispatch(rptgen.patients.setScreener(undefined));
    dispatch(rptgen.patients.setCaseref(undefined));
    dispatch(rptgen.directwebremote.clearPanteraBodyParts());
    dispatch(rptgen.directwebremote.clearDwr());
    clearSessionStorage();
    history.push('/existing/patients');
  };

  const handleChangeProvider = () => {
    dispatch(rptgen.directwebremote.setProvider(undefined));
    dispatch(rptgen.patients.setPatient(undefined));
    dispatch(rptgen.patients.setExamId(''));
    dispatch(rptgen.patients.setPatientDoe(undefined));
    dispatch(rptgen.patients.setPatientDemo(undefined));
    dispatch(rptgen.patients.setPreauth(undefined));
    dispatch(rptgen.patients.setScreener(undefined));
    dispatch(rptgen.patients.setCaseref(undefined));
    dispatch(rptgen.directwebremote.clearPanteraBodyParts());
    dispatch(rptgen.directwebremote.clearDwr());
    clearSessionStorage();
    history.push('/welcome');
  };

  const handleChangeDoe = () => {
    const { _id } = patient;
    dispatch(rptgen.patients.setExamId(''));
    dispatch(rptgen.patients.setPatientDoe(undefined));
    dispatch(rptgen.patients.setPatientDemo(undefined));
    dispatch(rptgen.patients.setScreener(undefined));
    dispatch(rptgen.patients.setCaseref(undefined));
    dispatch(rptgen.patients.setPreauth(undefined));
    dispatch(rptgen.directwebremote.clearPanteraBodyParts());
    dispatch(common.ui.fromDoeButton(true));
    history.push(`/existing/patients?id=${_id}`);
  };

  /*
  const headerDropdown = (
    <Menu>
      <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
    </Menu>
  );
  */

  useEffect(() => {
    if (location.pathname.indexOf('preview-3d') > -1) return;
    dispatch(common.ui.setLoading());
    dispatch(rptgen.diagset.getDiagset({ pageIndex: 1, pageSize: 420 }));
    dispatch(rptgen.procedurecode.getProcedureCodeList({ pageIndex: 1, pageSize: 300 }));
    dispatch(rptgen.treatcode.getTreatcodeListTank({ pageIndex: 1, pageSize: 300 }));
    dispatch(rptgen.treatmentplanset.prepareTanks()).then(() => {
      dispatch(common.ui.clearLoading());
      if (user) {
        api.info({
          className: 'notification',
          duration: 1,
          message: `Logged In.`,
          description: `Welcome back ${user.account.fname}!`,
          placement: 'topRight',
        });
      }
    });

    if (!provider) history.push('/welcome');
  }, [api, dispatch, user]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [location]);

  useEffect(() => {
    if (location.pathname.indexOf('doctor/pexam') > -1) {
      if (window.matchMedia('screen and (max-width: 1250px)').matches) {
        handleToggleCollapse();
      }
    }
  }, [location.pathname]);

  const isPp = () => {
    return patient && provider;
  };

  if (location.pathname.indexOf('preview-3d') > -1) return <Preview3d />;

  return (
    <Layout>
      <Drawer width="25%" title="Patient" closable={false} onClose={togglePatientDrawer} visible={isPatientDrawerOpen}>
        <Space direction="vertical">
          <Descriptions size="small" column={1} layout="vertical" bordered>
            {isPp() && (
              <>
                <Descriptions.Item label="Name">{patient.pname}</Descriptions.Item>
                <Descriptions.Item label="Date of birth">{convertMomentWithFormat(patient.dob)}</Descriptions.Item>
              </>
            )}
          </Descriptions>
          <Button type="primary" style={{ marginLeft: 10 }} onClick={handleChangePatient} size="small">
            Change Patient
          </Button>
        </Space>
      </Drawer>

      <Drawer width="25%" title="Logged User" closable={false} onClose={toggleDrawerOpen} bodyStyle={{ background: isAdmin ? 'linear-gradient(180deg, white, white 45%, purple)' : 'linear-gradient(180deg, white, white 45%, #1768A0)' }} visible={isDrawerOpen}>
        <div
          style={{
            writingMode: 'vertical-lr',
            textOrientation: 'mixed',
            color: '#a1a1a1',
            fontSize: 60,
            position: 'absolute',
            bottom: '2%',
            left: '0%',
          }}>
          {user.account.role}
        </div>

        {/*
        layout={{ xxl: 'vertical', xl: 'vertical', lg: 'vertical', md: 'vertical', sm: 'horizontal', xs: 'horizontal'  }}
        */}
        <Space direction="vertical">
          <Descriptions size="small" column={1} layout="vertical" bordered>
            <Descriptions.Item
              label={
                <>
                  <UserOutlined style={{ marginRight: 5 }} />
                  Name:
                </>
              }>
              {user.account.fname}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{user.account.email}</Descriptions.Item>
          </Descriptions>
          <Button type="primary" style={{ marginLeft: 10 }} onClick={handleLogout} size="small">
            Log Out
          </Button>
        </Space>
      </Drawer>

      {contextHolder}
      <Sider trigger={null} collapsible collapsed={collapsed} width={300} className="basic-layout-sider">
        {/* Logo
          <img className={collapsed ? 'imgIco' : 'imgLogo'} src={collapsed ? rptgenIco : rptgenLogo} alt="Rptgen" />
          */}
        <div className="logo">
          <img className={collapsed ? 'imgIco' : 'imgLogo'} src={collapsed ? rptgenIco : rptgenLogo} alt="Rptgen" />
        </div>

        <Menu theme="dark" mode="inline" defaultSelectedKeys={[location.pathname]} defaultOpenKeys={[location.pathname.split('/')[1]]} style={{ height: '100%', overflow: 'scroll' }} openKeys={openKeys} onOpenChange={handleOpenChange}>
          {!newPatient &&
            examId === '' &&
            provider &&
            existingPatientConfig.map(menu =>
              menu.subRoute.length > 0 ? (
                <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                  {menu.subRoute.map(subMenu =>
                    subMenu.subRoute && subMenu.subRoute.length > 0 ? (
                      <SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.title}>
                        {subMenu.subRoute.map(subSubMenu => (
                          <Menu.Item key={subSubMenu.key} onClick={() => history.push(subSubMenu.key)}>
                            {`${subSubMenu.title}`}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenu.key} onClick={() => history.push(subMenu.key)}>
                        {`${subMenu.title}`}
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.key} icon={menu.icon} onClick={() => history.push(menu.key)}>
                  {menu.title}
                </Menu.Item>
              )
            )}

          {newPatient &&
            newPatientConfig.map(menu =>
              menu.subRoute.length > 0 ? (
                <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                  {menu.subRoute.map(subMenu =>
                    subMenu.subRoute && subMenu.subRoute.length > 0 ? (
                      <SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.title}>
                        {subMenu.subRoute.map(subSubMenu => (
                          <Menu.Item disabled key={subSubMenu.key} onClick={() => history.push(subSubMenu.key)}>
                            {`${subSubMenu.title}`}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item disabled key={subMenu.key} onClick={() => history.push(subMenu.key)}>
                        {`${subMenu.title}`}
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item disabled key={menu.key} icon={menu.icon} onClick={() => history.push(menu.key)}>
                  {menu.title}
                </Menu.Item>
              )
            )}

          {!newPatient &&
            provider &&
            examId !== '' &&
            menuConfig.map(menu =>
              menu.subRoute.length > 0 ? (
                <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                  {menu.subRoute.map(subMenu =>
                    subMenu.subRoute && subMenu.subRoute.length > 0 ? (
                      <SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.title}>
                        {subMenu.subRoute.map(subSubMenu => (
                          <Menu.Item key={subSubMenu.key} onClick={() => history.push(subSubMenu.key)}>
                            {`${subSubMenu.title}`}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenu.key} onClick={() => history.push(subMenu.key)}>
                        {`${subMenu.title}`}
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.key} icon={menu.icon} onClick={() => history.push(menu.key)}>
                  {menu.title}
                </Menu.Item>
              )
            )}

          {!newPatient &&
            !patient &&
            !provider &&
            afterLoginConfig.map(menu =>
              menu.subRoute.length > 0 ? (
                <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                  {menu.subRoute.map(subMenu =>
                    subMenu.subRoute && subMenu.subRoute.length > 0 ? (
                      <SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.title}>
                        {subMenu.subRoute.map(subSubMenu => (
                          <Menu.Item key={subSubMenu.key} onClick={() => history.push(subSubMenu.key)}>
                            {`${subSubMenu.title}`}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenu.key} onClick={() => history.push(subMenu.key)}>
                        {`${subMenu.title}`}
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.key} icon={menu.icon} onClick={() => history.push(menu.key)}>
                  {menu.title}
                </Menu.Item>
              )
            )}

          {/* {!newPatient &&
            provider &&
            examId !== '' &&
            user.account.role === 'admin' &&
            acuConfig.map(menu =>
              menu.subRoute.length > 0 ? (
                <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                  {menu.subRoute.map(subMenu =>
                    subMenu.subRoute && subMenu.subRoute.length > 0 ? (
                      <SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.title}>
                        {subMenu.subRoute.map(subSubMenu => (
                          <Menu.Item key={subSubMenu.key} onClick={() => history.push(subSubMenu.key)}>
                            {`${subSubMenu.title}`}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenu.key} onClick={() => history.push(subMenu.key)}>
                        {`${subMenu.title}`}
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.key} icon={menu.icon} onClick={() => history.push(menu.key)}>
                  {menu.title}
                </Menu.Item>
              )
            )} */}

          {user.account.role === 'admin' &&
            adminConfig.map(menu =>
              menu.subRoute.length > 0 ? (
                <SubMenu key={menu.key} icon={menu.icon} title={menu.title}>
                  {menu.subRoute.map(subMenu =>
                    subMenu.subRoute && subMenu.subRoute.length > 0 ? (
                      <SubMenu key={subMenu.key} icon={subMenu.icon} title={subMenu.title}>
                        {subMenu.subRoute.map(subSubMenu => (
                          <Menu.Item key={subSubMenu.key} onClick={() => history.push(subSubMenu.key)}>
                            {`${subSubMenu.title}`}
                          </Menu.Item>
                        ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenu.key} onClick={() => history.push(subMenu.key)}>
                        {`${subMenu.title}`}
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={menu.key} icon={menu.icon} onClick={() => history.push(menu.key)}>
                  {menu.title}
                </Menu.Item>
              )
            )}
        </Menu>
      </Sider>

      <Layout style={{ marginLeft: collapsed ? 80 : 300, transition: 'all 0.2s' }}>
        {/* Header */}
        <Header className="basic-layout-header" style={{ height: 60, width: `calc(100% - ${collapsed ? 80 : 300}px)`, transition: 'all 0.2s' }}>
          {/* Fold / Unfold Icon */}
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: handleToggleCollapse,
          })}

          <div style={{ fontSize: 11, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Space>
              {provider && (
                <Tag style={{ cursor: 'pointer' }} onClick={handleChangeProvider} icon={<IdcardOutlined />} color="default">
                  {`Provider: ${provider.pcode}`}
                </Tag>
              )}

              {isPp() && (
                <Tag style={{ cursor: 'pointer' }} onClick={togglePatientDrawer} icon={<TeamOutlined />} color="default">
                  Patient
                </Tag>
              )}

              {patientdoe && patientdoe.doe && (
                <Tag style={{ cursor: 'pointer' }} onClick={handleChangeDoe} icon={<ClockCircleOutlined />} color="default">
                  DOE: {convertMomentWithFormat(patientdoe.doe)}
                </Tag>
              )}
            </Space>

            <Tag style={{ cursor: 'pointer' }} onClick={toggleDrawerOpen} icon={<UserOutlined />} color="default">
              Logged User
            </Tag>

            {/* Avatar */}
            {/*
            <Dropdown overlay={headerDropdown} placement="bottomCenter">
              <div className="basic-layout-avatar">{`Logged User: ${user.account.fname}`}</div>
            </Dropdown>
            */}
          </div>
        </Header>

        {/* Content */}
        <Content className="basic-layout-content">
          <ErrorBoundary>
            <Suspense fallback={<LoadComponent />}>{children}</Suspense>
          </ErrorBoundary>
        </Content>
        <Footer className="basic-layout-footer">
          <div className="footer-container">
            {/*
              <ConnectionIndicator />
            */}
            <div>&nbsp;</div>
            <div>
              [©{new Date().getFullYear()} Rptgen 2.0.2] [W: {size.width} H: {size.height}]
            </div>
          </div>
        </Footer>
      </Layout>
      {/* <FormChangesPrompt /> */}
    </Layout>
  );
};

export default BasicLayout;
