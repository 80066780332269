// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch diagnosis list
 * @param {*} payload
 */
export async function fetchDiagnosisList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`diagnosis?${params}`);
}

/**
 * get diagnosis
 * @param {*} payload
 */

export async function fetchDiagnosis(payload) {
  return GET(`diagnosis/${payload}`);
}

/**
 * update diagnosis by id
 * @param {*} payload
 */
export async function updateDiagnosisById(payload) {
  const { id, ...rest } = payload;
  return PUT(`diagnosis/${id}`, rest);
}

/**
 * remove diagnosis by id
 * @param {*} payload
 */
export async function removeDiagnosisById(payload) {
  return DELETE(`diagnosis/${payload}`);
}

/**
 * create diagnosis
 * @param {*} payload
 */
export async function createDiagnosis(payload) {
  return POST(`diagnosis`, payload);
}
