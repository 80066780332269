// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch poffice list
 * @param {*} payload
 */
export async function fetchPofficeList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`poffice?${params}`);
}

/**
 * get poffice
 * @param {*} payload
 */

export async function fetchPoffice(payload) {
  return GET(`poffice/${payload}`);
}

/**
 * get poffice by pcode
 * @param {*} payload
 */

export async function fetchPofficeByCode(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`poffice?${params}`);
}

/**
 * update poffice by id
 * @param {*} payload
 */
export async function updatePofficeById(payload) {
  const { id, ...rest } = payload;
  return PUT(`poffice/${id}`, rest);
}

/**
 * remove poffice by id
 * @param {*} payload
 */
export async function removePofficeById(payload) {
  return DELETE(`poffice/${payload}`);
}

/**
 * create poffice
 * @param {*} payload
 */
export async function createPoffice(payload) {
  return POST(`poffice`, payload);
}

/**
 * authenticate poffice
 * @param {*} payload
 */
export async function authenticateProvider(payload) {
  return POST(`poffice/authenticate`, payload);
}
