// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch pastmedicalhistory list
 * @param {*} payload
 */
export async function fetchPastmedicalhistoryList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`pastmedicalhistory?${params}`);
}

/**
 * get pastmedicalhistory
 * @param {*} payload
 */

export async function fetchPastmedicalhistory(payload) {
  return GET(`pastmedicalhistory/${payload}`);
}

/**
 * update pastmedicalhistory by id
 * @param {*} payload
 */
export async function updatePastmedicalhistoryById(payload) {
  const { id, ...rest } = payload;
  return PUT(`pastmedicalhistory/${id}`, rest);
}

/**
 * remove pastmedicalhistory by id
 * @param {*} payload
 */
export async function removePastmedicalhistoryById(payload) {
  return DELETE(`pastmedicalhistory/${payload}`);
}

/**
 * create pastmedicalhistory
 * @param {*} payload
 */
export async function createPastmedicalhistory(payload) {
  return POST(`pastmedicalhistory`, payload);
}
