import axios from 'axios';

import { message } from 'antd';

// request header configuration
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post.Accept = 'application/json';

// // Create an instance of Axios with default headers
// const api = axios.create({
//   baseURL: 'https://api.example.com',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// Add a request interceptor to include the access token in the headers
axios.interceptors.request.use(
  (config) => {
    // console.log('[R E Q  I N T E R C E P T O R  1]]] ', config);
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 Unauthorized responses
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    console.log('[R E S  I N T E R C E P T O R  1 error]]] ', error);
    console.log('[R E S  I N T E R C E P T O R  1 origReq]]] ', originalRequest);

    // If the error status is 401 and there is no retry flag, try to refresh the token
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('rtoken');
        const response = await axios.post('/account/validate-token', { refreshToken });
        console.log('[T H E  M V C H  A W A I T E D  V A L I D A T E  T O K E N]]]]] ', response);

        // Update the access token in local storage
        localStorage.setItem('token', response.data.accessToken);
        localStorage.setItem('rtoken', response.data.refreshToken);

        // Retry the original request with the new access token
        originalRequest.headers.Authorization = `Bearer ${response.data.accessToken}`;
        return axios(originalRequest);
      } catch (refreshError) {
        // Handle refresh token error (e.g., redirect to login)
        console.error('Failed to refresh token', refreshError);
        localStorage.removeItem('token');
        localStorage.removeItem('rtoken');
        window.location.href = process.env.REACT_APP_ROUTER_BASE || '';
      }
    }

    return Promise.reject(error);
  }
);

const baseUrl = process.env.REACT_APP_NODE_URL;
const tokenx = localStorage.getItem('tokenx');

/**
 * handle check request method
 * based on method, use axios to handle request
 * @param url
 * @param options
 * @returns {Promise<AxiosResponse<T>>}
 */
const requestMethod = (url, options = {}) => {
  const { method = 'GET', ...option } = options;
  switch (method) {
    case 'GET':
      return axios.get(baseUrl + url, option);
    case 'POST':
      return axios.post(baseUrl + url, option);
    case 'POST_FORM_DATA':
      return axios.post(baseUrl + url, option.data);
    case 'PUT':
      return axios.put(baseUrl + url, option);
    case 'DELETE':
      return axios.delete(baseUrl + url, { data: option });
    case 'GET_BLOB':
      return axios.get(baseUrl + url, { responseType: 'blob', timeout: 30000 });
    case 'GET_ABUF':
      return axios.get(baseUrl + url, {
        responseType: 'arraybuffer',
        timeout: 30000,
        headers: {
          'X-SB-KEY': tokenx
        }
      });      
    default:
      return axios.get(baseUrl + url, option);
  }
};

/**
 * Check the response status
 * @param res
 * @returns {*}
 */
const checkApiStatus = res => {
  if (res.status >= 200 && res.status < 300) return res;
  // console.log('[CHECK_API_STATUS] ', res);
  message.error(`${res}`);
  return false;
};

/**
 * handle response data format
 * @param res
 * @returns {Promise<{data: *, message: *, status: *}>}
 */
const handleResponseData = res => {
  const { data } = res;
  return Promise.resolve(data);
};

/**
 * handle throw error
 * @param err
 * @returns {Promise<never>}
 */
const handleThrowError = err => {
  //console.log('[PEOW HERE1] ', err, err.response);
  if (err.response) {
    if (err.response.status === 401) {
      //console.log('[PEOW HERE2] ', err, err.response);
      if (err.response.data.msg) {
        message.error(`${err.response.data.msg}`);
      }
    } else {
      //console.log('[PEOW HERE3] ', err, err.response);
      message.error(`${err.response.data ? err.response.data.msg : err}`);
    }
  }
  //console.log('[PEOW HERE4] ', err, err.response);
  return Promise.reject(err);
};

/**
 * Overall fetch method
 * @param url
 * @param options
 * @returns {Promise<{data: *, status: *} | never>}
 */
const fetch = (url, options) => {
  return requestMethod(url, options).then(checkApiStatus).then(handleResponseData).catch(handleThrowError);
};

/**
 * handle GET request
 * @param url
 * @param options
 * @constructor
 */
export const GET = (url, options) => fetch(url, { ...options, method: 'GET' });

/**
 * handle POST request
 * @param url
 * @param options
 * @constructor
 */
export const POST = (url, options) => fetch(url, { ...options, method: 'POST' });

/**
 * handle POST request
 * @param url
 * @param options
 * @constructor
 */
export const POST_FORM_DATA = (url, options) => fetch(url, { ...options, method: 'POST_FORM_DATA' });

/**
 * handle PUT request
 * @param url
 * @param options
 * @constructor
 */
export const PUT = (url, options) => fetch(url, { ...options, method: 'PUT' });

/**
 * handle delete request
 * @param url
 * @param options
 * @constructor
 */
export const DELETE = (url, options) => fetch(url, { ...options, method: 'DELETE' });

/**
 * handle GET BLOB request
 * @param url
 * @param options
 * @constructor
 */
export const GET_BLOB = (url, options) => fetch(url, { ...options, method: 'GET_BLOB' });

export const GET_ABUF = (url, options) => fetch(url, { ...options, method: 'GET_ABUF' });