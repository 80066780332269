// utils
import * as methods from '@utils/methods';

import { DELETE, GET, POST, PUT } from '../request';

/**
 * fetch patientdiag list
 * @param {*} payload
 */
export async function fetchPatientDiagList(payload) {
  const params = methods.convertQueryString(payload);
  return GET(`patientdiag?${params}`);
}

/**
 * get patientdiag
 * @param {*} payload
 */

export async function fetchPatientDiag(payload) {
  return GET(`patientdiag/${payload}`);
}

/**
 * update patientdiag by id
 * @param {*} payload
 */
export async function updatePatientDiagById(payload) {
  const { id, ...rest } = payload;
  return PUT(`patientdiag/${id}`, rest);
}

/**
 * remove patientdiag by id
 * @param {*} payload
 */
export async function removePatientDiagById(payload) {
  return DELETE(`patientdiag/${payload}`);
}

/**
 * create patientdiag
 * @param {*} payload
 */
export async function createPatientDiag(payload) {
  return POST(`patientdiag`, payload);
}
