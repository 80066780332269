import {
  fetchPshandList,
  fetchPshand,
  updatePshandById,
  removePshandById,
  createPshand
} from '@service/api/pshand';

// types
import * as types from '../types';

export const getPshandList = (payload) => (dispatch) => {
  return fetchPshandList(payload).then((res) => {
    if (res.success) {
      if (res.data.docs.length > 0) {
        res.data.docs.map(doc => {
          if (doc.lr === 'right') {
            dispatch({
              type: types.SET_PSRHAND,
              payload: [doc],
            });
          }
          if (doc.lr === 'left') {
            dispatch({
              type: types.SET_PSLHAND,
              payload: [doc],
            });
          }
          return doc;
        });
      }
    }
    return res;
  });
};

export const removePshand = (payload) => () => {
  return removePshandById(payload).then((res) => {
    return res;
  });
};

export const addPshand = (payload) => () => {
  return createPshand(payload).then((res) => {
    return res;
  });
};

export const getPshand = (payload) => () => {
  return fetchPshand(payload).then((res) => {
    return res;
  });
};

export const updatePshand = (payload) => () => {
  return updatePshandById(payload).then((res) => {
    return res;
  });
};
