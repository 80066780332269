import {
  fetchCervicalspineList,
  fetchCervicalspine,
  updateCervicalspineById,
  removeCervicalspineById,
  createCervicalspine
} from '@service/api/cervicalspine';

// types
import * as types from '../types';

export const getCervicalspineList = (payload) => async (dispatch) => {
  return fetchCervicalspineList(payload).then((res) => {
    if (res.success) {
      dispatch({
        type: types.SET_CERVICAL_SPINE,
        payload: res.data.docs,
      });
    }
    return res;
  });
};

export const removeCervicalspine = (payload) => () => {
  return removeCervicalspineById(payload).then((res) => {
    return res;
  });
};

export const addCervicalspine = (payload) => () => {
  return createCervicalspine(payload).then((res) => {
    return res;
  });
};

export const getCervicalspine = (payload) => () => {
  return fetchCervicalspine(payload).then((res) => {
    return res;
  });
};

export const updateCervicalspine = (payload) => () => {
  return updateCervicalspineById(payload).then((res) => {
    return res;
  });
};
